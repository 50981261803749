import React, { FC, PropsWithChildren } from "react";
import { useNotificationCountSubscription } from "api";
import { NotificationCountContext } from "context";
import { useAuth } from "react-oidc-context";

export const NotificationCountProvider: FC<PropsWithChildren> = (props) => {
  const { user } = useAuth();
  const { data } = useNotificationCountSubscription({
    variables: { userId: `${user?.profile?.sub}` },
  });
  return <NotificationCountContext.Provider value={data?.counts} {...props} />;
};

export { default as COLORS } from "./colors";

export const confirmModalOption = {
    confirmationButtonProps: {
        style: {
            backgroundColor: "red",
        },
    },
    dialogProps: {
        PaperProps: {
            style: {
                maxWidth: 400,
            },
        },
    },
}

export const colorMap = {
    Stock: '#3ecf8e',
    Sector: '#EE9949',
    Industry: 'rgb(58 105 186)'
}
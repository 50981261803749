import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import { ArrowUpward } from "@mui/icons-material";
import { useWindowScroll } from "react-use";
interface NewPromptProps {
  title: string;
  topFixValue: number;
  handleClick: () => void;
}
const NewPrompt: React.FC<NewPromptProps> = ({ title, topFixValue, handleClick }) => {
  const { y } = useWindowScroll();
  return (
    <NewPostButton className={y >= topFixValue ? "fixed-top" : ""} onClick={handleClick}>{title}</NewPostButton>
  );
}
export default NewPrompt;

const NewPostButton = styled((props: ButtonProps) => <Button startIcon={<ArrowUpward />} variant="outlined" {...props} />)({
  backgroundColor: '#3364C4',
  position: 'absolute',
  zIndex: 999,
  top: '20px',
  left: 0,
  right: 0,
  width: '120px',
  margin: 'auto',
  borderRadius: '20px',
  height: '32px',
  borderColor: "#3364C4",
  color: "#fff",
  '&:hover, &:active': {
    backgroundColor: '#3364C4',
    borderColor: "#3364C4",
    color: "#fff",
  },
  '&.fixed-top': {
    position: 'fixed',
    top: '80px'
  }
})

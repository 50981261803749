import React, { FC } from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

export const BackIconButton: FC<IconButtonProps> = (props) => (
  <IconButton {...props}>
    <ArrowBack />
  </IconButton>
);


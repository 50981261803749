import React, { FC, Fragment, useEffect, useState } from "react";
import { Button, Stack, Typography, styled } from "@mui/material";
import { isDarkMode } from "utills";
import { useGetBrokenStreakDetailsQuery } from "api";
import Fire from "./Fire";
import Badge from "assets/image/badge.webp";
import Heading from "./Heading";
import CustomeLine from "./CustomeLine";
import { CloseIconButton } from "components/Buttons";

interface MissedStrickProps {
  handleUnderstood?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleModalClose: () => void;
}

const MissedStrick: FC<MissedStrickProps> = ({
  handleUnderstood,
  handleModalClose,
}) => {
  const { data } = useGetBrokenStreakDetailsQuery();
  const [activeStep, setActiveStep] = useState(0);
  const [showWeekStep, setShowWeekStep] = useState(0);

  useEffect(() => {
    if (data?.getBrokenStreakDetails?.data?.prevStreak) {
      data?.getBrokenStreakDetails?.data?.prevStreak.map((w, i) => {
        if (w?.isCurrentWeek) {
          setActiveStep(i);
          setShowWeekStep(i);
        }
      });
    }
  }, [
    data?.getBrokenStreakDetails?.data?.prevStreak,
    setActiveStep,
    setShowWeekStep,
  ]);

  const onhandleChange = (step: number) => {
    setShowWeekStep(step);
  };

  return (
    <Fragment>
      <CloseButton onClick={handleModalClose} />
      <Container>
        <SectionDay>
          <SectionWeek>
            <Image src={Badge} />
            <Heading
              title="You missed your streak!"
              weekHeaderMessage={
                data?.getBrokenStreakDetails?.data?.prevStreak?.[activeStep]
                  ?.weekHeaderMessage
              }
            />
            <CustomeLine
              data={data?.getBrokenStreakDetails?.data?.prevStreak}
              onhandleChange={onhandleChange}
            />
            <Subtitle1>
              {
                data?.getBrokenStreakDetails?.data?.prevStreak?.[activeStep]
                  ?.weekHeader
              }
            </Subtitle1>
          </SectionWeek>
          <LineContainer>
            <LeftLine />
            <Subtitle2>Day Streak</Subtitle2>
            <RightLine />
          </LineContainer>
          <DayStrickContainer>
            {data?.getBrokenStreakDetails?.data?.prevStreak?.[
              showWeekStep
            ]?.days?.map((d) => (
              <Fire
                state={d?.visitedState}
                day={d?.dayName?.substring(0, 1)}
                key={d?.dayName}
              />
            ))}
          </DayStrickContainer>
          <Stack padding={2}>
            <UnderstoodButton
              variant="contained"
              color="inherit"
              fullWidth
              disableElevation
              size="large"
              onClick={handleUnderstood}
            >
              Understood
            </UnderstoodButton>
          </Stack>
        </SectionDay>
      </Container>
    </Fragment>
  );
};

export default MissedStrick;

const CloseButton = styled(CloseIconButton)({
  position: "absolute",
  top: 4,
  right: 4,
  zIndex: 99,
});

const UnderstoodButton = styled(Button)(({ theme }) => ({
  backgroundColor: isDarkMode(theme) ? "#16181c" : "#F6FFFA",
  color: isDarkMode(theme) ? "#fff" : "#131722",
  "&:hover": {
    backgroundColor: isDarkMode(theme) ? "#16181c" : "#F6FFFA",
    color: isDarkMode(theme) ? "#fff" : "#131722",
  },
  borderRadius: 20,
}));

// const Container = styled('div')(({ theme }) => ({
//   display: "flex",
//   flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center',
//   padding: 10,
//   zIndex: 9,
//   paddingTop: 50,
//   position: 'relative',
//   overflowX: 'hidden',
//   "&::after": {
//     content: '""',
//     backgroundImage: isDarkMode(theme) ? 'linear-gradient(#16181c, #e4f1ff)' : 'linear-gradient(#f6fffa, #e4f1ff)',
//     position: 'absolute',
//     top: 0,
//     left: '-10%',
//     right: '-10%',
//     height: '30%',
//     borderBottomLeftRadius: '50%',
//     borderBottomRightRadius: '50%',
//     zIndex: -1
//   }

// }))

// const Subtitle1 = styled(Typography)(({ theme }) => ({
//   fontSize: 20,
//   fontWeight: 700,
//   lineHeight: '30px',
//   marginBottom: 10,
//   marginTop: 25,
//   color: isDarkMode(theme) ? "#fff" : '#131722',
//   textAlign: "center"
// }))

// const Body1 = styled(Typography)({
//   fontSize: 16,
//   marginBottom: 20,
//   color: '#606060',
//   textAlign: "center"
// })

// const ButtomContainer = styled('div')({
//   padding: '12px 0px',
//   margin: 'auto',
//   borderRadius: 40,
//   marginTop: 5,
//   marginBottom: 15

// })

// const Caption = styled('p')(({ theme }) => ({
//   color: isDarkMode(theme) ? "#fff" : '#000',
//   fontWeight: 700,
//   fontSize: 16,
//   textAlign: 'center'
// }))

// const Image = styled(
//   (
//     props: React.DetailedHTMLProps<
//       React.ImgHTMLAttributes<HTMLImageElement>,
//       HTMLImageElement
//     >
//   ) => <img height={80} width={80} {...props} />
// )(({ theme }) => ({
//   height: 100,
//   width: 100,
//   marginTop: -20,
// }));

const Image = styled(
  (
    props: React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >
  ) => <img height={80} width={80} {...props} />
)(({ theme }) => ({
  height: 80,
  width: 80,
  objectFit: "contain",
  objectPosition: "center",
  margin: "15px auto 0",
}));

const LineContainer = styled("div")({
  display: "flex",
  alignItems: "baseline",
  margin: "15px 10px",
});

const RightLine = styled("div")({
  height: 1,
  flex: 1,
  backgroundImage:
    "linear-gradient(90deg, rgba(201, 220, 243, 0.5) 10%, rgba(201, 220, 243, 0) 90%)",
  marginLeft: 10,
});

const LeftLine = styled("div")({
  height: 1,
  flex: 1,
  backgroundImage:
    "linear-gradient(90deg, rgba(201, 220, 243, 0) 10%, rgba(201, 220, 243, 0.5) 90%)",
  marginRight: 10,
});

const Container = styled("div")({
  borderRadius: 20,
  maxWidth: 600,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "fit-content",
  "@media (min-width: 540px)": {
    padding: 0,
  },
});

const Subtitle1 = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  marginBottom: 10,
  marginTop: 16,
  lineHeight: "24px",
  color: isDarkMode(theme) ? "#fff" : "#131722",
  fontWeight: 600,
  textAlign: "center",
}));

const Subtitle2 = styled(Typography)({
  fontSize: 18,
  marginTop: 20,
  marginBottom: 15,
  color: "#ffffff",
  fontWeight: 700,
});

const SectionWeek = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: isDarkMode(theme) ? "#16181c" : "#F6FFFA",
  backgroundPosition: "center bottom",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  paddingTop: 15,
  paddingBottom: 25,
  borderBottomLeftRadius: 35,
  borderBottomRightRadius: 35,
  boxShadow:
    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, #004598 0px 30px 60px -30px",
}));

const SectionDay = styled("section")(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(27, 106, 201, 1),rgba(30, 67, 134, 1))",
  paddingBottom: 5,
}));

const DayStrickContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "25px 20px 0",
});

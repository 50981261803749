import React, { FC, Fragment, useEffect, useState } from "react";
import { Button, Stack, Typography, styled } from "@mui/material";
import { useGetPowerUserStreakDetailsQuery } from "api";
import Custom from "./Custom";
import Heading from "./Heading";
import Fire from "./Fire";
import Badge from "assets/image/badge.webp";
import { isDarkMode } from "utills";
import { CloseIconButton } from "components/Buttons";

interface RunningStrickProps {
  handleUnderstood?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  handleModalClose: () => void;
}

const RunningStrick: FC<RunningStrickProps> = ({
  handleUnderstood,
  handleModalClose,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [showWeekStep, setShowWeekStep] = useState(0);
  const { data } = useGetPowerUserStreakDetailsQuery();

  useEffect(() => {
    if (data?.getPowerUserStreakDetails?.data?.weekData) {
      data?.getPowerUserStreakDetails?.data?.weekData.map((w, i) => {
        if (w?.isCurrentWeek) {
          setActiveStep(i);
          setShowWeekStep(i);
        }
      });
    }
  }, [
    data?.getPowerUserStreakDetails?.data?.weekData,
    setActiveStep,
    setShowWeekStep,
  ]);

  const onhandleChange = (step: number) => {
    setShowWeekStep(step);
  };

  if (!data?.getPowerUserStreakDetails?.data?.weekData) return null;

  return (
    <Fragment>
      <CloseButton onClick={handleModalClose} />
      <Container>
        <SectionDay>
          <SectionWeek>
            <Image src={Badge} />
            <Heading
              title="Become a Power User"
              weekHeaderMessage={
                data?.getPowerUserStreakDetails?.data?.weekData[activeStep]
                  ?.weekHeaderMessage
              }
            />
            <Custom
              data={data?.getPowerUserStreakDetails?.data?.weekData}
              onhandleChange={onhandleChange}
            />
            <Subtitle1>
              {
                data?.getPowerUserStreakDetails?.data?.weekData[activeStep]
                  ?.weekHeader
              }
            </Subtitle1>
          </SectionWeek>
          <LineContainer>
            <LeftLine />
            <Subtitle2>Day Streak</Subtitle2>
            <RightLine />
          </LineContainer>
          <DayStrickContainer>
            {data?.getPowerUserStreakDetails?.data?.weekData[
              showWeekStep
            ]?.days?.map((d) => (
              <Fire
                state={d?.visitedState}
                day={d?.dayName?.substring(0, 1)}
                key={d?.dayName}
              />
            ))}
          </DayStrickContainer>
          <Stack padding={2}>
            <UnderstoodButton
              variant="contained"
              color="inherit"
              fullWidth
              disableElevation
              size="large"
              onClick={handleUnderstood}
            >
              Understood
            </UnderstoodButton>
          </Stack>
        </SectionDay>
      </Container>
    </Fragment>
  );
};

export default RunningStrick;

const CloseButton = styled(CloseIconButton)({
  position: "absolute",
  top: 4,
  right: 4,
  zIndex: 99,
});

const UnderstoodButton = styled(Button)(({ theme }) => ({
  backgroundColor: isDarkMode(theme) ? "#16181c" : "#F6FFFA",
  color: isDarkMode(theme) ? "#fff" : "#131722",
  "&:hover": {
    backgroundColor: isDarkMode(theme) ? "#16181c" : "#F6FFFA",
    color: isDarkMode(theme) ? "#fff" : "#131722",
  },
  borderRadius: 20,
}));

const Image = styled(
  (
    props: React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >
  ) => <img height={80} width={80} {...props} />
)(({ theme }) => ({
  height: 80,
  width: 80,
  objectFit: "contain",
  objectPosition: "center",
  margin: "15px auto 0",
}));

const LineContainer = styled("div")({
  display: "flex",
  alignItems: "baseline",
  margin: "15px 10px",
});

const RightLine = styled("div")({
  height: 1,
  flex: 1,
  backgroundImage:
    "linear-gradient(90deg, rgba(201, 220, 243, 0.5) 10%, rgba(201, 220, 243, 0) 90%)",
  marginLeft: 10,
});

const LeftLine = styled("div")({
  height: 1,
  flex: 1,
  backgroundImage:
    "linear-gradient(90deg, rgba(201, 220, 243, 0) 10%, rgba(201, 220, 243, 0.5) 90%)",
  marginRight: 10,
});

const Container = styled("div")({
  borderRadius: 20,
  maxWidth: 600,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "fit-content",
  "@media (min-width: 540px)": {
    padding: 0,
  },
});

const Subtitle1 = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  marginBottom: 10,
  marginTop: 16,
  lineHeight: "24px",
  color: isDarkMode(theme) ? "#fff" : "#131722",
  fontWeight: 600,
  textAlign: "center",
}));

const Subtitle2 = styled(Typography)({
  fontSize: 18,
  marginTop: 20,
  marginBottom: 15,
  color: "#ffffff",
  fontWeight: 700,
});

const SectionWeek = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: isDarkMode(theme) ? "#16181c" : "#F6FFFA",
  backgroundPosition: "center bottom",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  paddingTop: 15,
  paddingBottom: 25,
  borderBottomLeftRadius: 35,
  borderBottomRightRadius: 35,
  boxShadow:
    "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, #004598 0px 30px 60px -30px",
}));

const SectionDay = styled("section")(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(27, 106, 201, 1),rgba(30, 67, 134, 1))",
  paddingBottom: 5,
}));

const DayStrickContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "25px 20px 0",
});

import { createContext } from "react";
import { HandleOpenModal } from "./ReportModalProvider";

export interface IReportModal {
    open: boolean;
    handleOpenModal: HandleOpenModal;
    handleCloseModal: () => void;
}

export const ReportModalContext = createContext<IReportModal | undefined>(
    undefined
);
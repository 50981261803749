import {
  CircularProgress,
  Stack,
  SxProps,
  Typography,
  styled,
} from "@mui/material";
import React, { FC, ReactNode, useRef } from "react";

interface InfiniteScrollProps {
  dataLength: number | undefined;
  hasMore: boolean | null | undefined;
  loading: boolean;
  children: ReactNode;
  endMessage?: ReactNode;
  emptyMessage?: ReactNode;
  fetchMoreData?: () => void;
  sx?: SxProps<any> | undefined;
}

const InfiniteScroll: FC<InfiniteScrollProps> = ({
  dataLength,
  loading,
  hasMore,
  children,
  endMessage,
  emptyMessage,
  fetchMoreData,
  sx,
}) => {
  const observer = useRef<IntersectionObserver>();

  const lastElement = React.useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreData?.();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, fetchMoreData]
  );

  return (
    <PostsContainer sx={sx}>
      {children}
      {hasMore ? (
        <div ref={lastElement} style={{ height: "2px", width: "100%" }} />
      ) : null}
      {loading && dataLength ? (
        <Loader />
      ) : !hasMore && dataLength ? (
        endMessage
      ) : null}
      {!loading && !dataLength
        ? emptyMessage || <Empty title="No posts" />
        : null}
    </PostsContainer>
  );
};

export const Loader = () => (
  <Stack justifyContent="center" alignItems="center" padding={1.5}>
    <CircularProgress size={24} />
  </Stack>
);

export const Empty: FC<{ title: string }> = ({ title }) => (
  <Stack justifyContent="center" alignItems="center" padding={1.5}>
    <Typography>{title}</Typography>
  </Stack>
);

export default InfiniteScroll;

const PostsContainer = styled("div")({
  maxWidth: "550px",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  padding: "12px 0"
});

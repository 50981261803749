import React, { FC } from "react";
import { Typography, styled } from "@mui/material";
import { Maybe } from "api";

const RunningStrick: FC<{
  title?: string;
  weekHeaderMessage: Maybe<string> | undefined;
}> = ({ title, weekHeaderMessage }) => {
  return (
    <SectionWeekContainer>
      <Typography variant="h2" align="center" sx={{ color: "#2576d8" }}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        sx={{
          color: "#606060",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "8px",
        }}
        style={{
          color: "#606060",
          fontSize: 16,
          maxWidth: "375px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        {weekHeaderMessage}
      </Typography>
    </SectionWeekContainer>
  );
};

export default RunningStrick;

const SectionWeekContainer = styled("div")({
  position: "relative",
  overflowX: "hidden",
  paddingBottom: 15,
  ".MuiTypography-root": {
    position: "relative",
    zIndex: 1,
  },
  ".MuiTypography-body1": {
    fontSize: 18,
    color: "#131722",
    maxWidth: "375px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  "&::after": {
    content: '""',
    // backgroundImage: 'linear-gradient(#F6FFFA, #e4f1ff)',
    position: "absolute",
    top: 0,
    left: "-10%",
    right: "-10%",
    bottom: 0,
    borderBottomLeftRadius: "50%",
    borderBottomRightRadius: "50%",
  },
});

/* eslint-disable react/display-name */
import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  Fragment,
  useMemo,
} from "react";
import { PostInput } from "./PostInput";
import { ImageEditor } from "./ImageEditor";
import { CreatePoll, Poll } from "./CreatePoll";
import { useCallback } from "react";
import { size } from "lodash-es";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  Club,
  ClubPostDocument,
  CreatePollArgs,
  Maybe,
  MediaInput,
  PollExpiryEnum,
  useCreatePostV2Mutation,
  GetTrendingHashTagsV2Document,
  GetTrendingSectorsByDollarTagDocument,
  GetTrendingStocksByDollarTagDocument,
  useGetFeatureToPlatformPermissionQuery,
  PlatformPermissionFromFeatureTypes,
  useMeQuery,
  useGetUserFeedTypeListQuery,
  GetFeedByTypeDocument,
} from "api";
import { ModalHeader } from "./ModalHeader";
import { UserDisplay } from "./UserDisplay";
import styled from "@emotion/styled";
import {
  CheckBoxOutlineBlankOutlined,
  PollOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ImageSmallIcon, AttachmentIcon, AddVideoIcon, AudioIcon } from "icons";
import { Image } from "./Image";
import { manageAnyHashtag } from "utills";
import { DisplayPoll } from "./DisplayPoll";
import { Video } from "./Video";
import { Pdf } from "./Pdf";
import { isEmpty } from "lodash-es";
import { toast } from "react-hot-toast";
import DisplayAudioPlayer from "./DisplayAudioPlayer";
import { useConfirm } from "material-ui-confirm";
import { AlertPaperconfigOption } from "helper";
import { COLORS } from "assets/colors";
import { trim } from "lodash-es";
import axios from "axios";
import { MEDIA_UPLOAD_URL, UPLOAD_LARGE_FILE_THUMBNAIL } from "config";
import { useAuth } from "react-oidc-context";
import ChooseAudience from "./ChooseAudience";
import { useClubPermission } from "hooks";
import imageCompression from "utills/image-compression";
import { sendEvent } from "ga4";
import { useAuthContext } from "context/AuthContext";
import useUploadLargeFile from "hooks/uploadLargeFiles";
import { isDesktop } from "react-device-detect";

const MAX_IMAGES = 3;
interface VideoThumbnailArgs {
  name: string;
  type: string;
  uri: string;
}
const videoThumbnailArgs: VideoThumbnailArgs[] = [];

interface PostCreatorProps {
  club: Maybe<Club> | undefined;
  text: Maybe<string> | undefined;
  image?: File;
  handleCloseModal: (text?: any, flag?: boolean) => void;
  handleOpenModal?: () => void;
}

export type ModalType =
  | "CREATE_A_POLL"
  | "EDIT_IMAGE"
  | "TEXT_POST"
  | "CHOOSE_AUDIANCE";

export const PostCreator = forwardRef<any, PostCreatorProps>(
  ({ club, handleCloseModal, handleOpenModal, ...rest }, ref) => {
    const { data: meData } = useMeQuery();
    const [fileSize, fileSizeMessage] = useMemo(() => {
      let fileSize = 10 * 1024 * 1024;
      let fileSizeMessage = "Video size exceeds 10 MB";
      if (meData?.me?.canUploadLargeFiles) {
        fileSize = 100 * 1024 * 1024;
        fileSizeMessage = "Video size exceeds 100 MB";
      }
      return [fileSize, fileSizeMessage];
    }, [meData?.me]);
    const confirm = useConfirm();
    const auth = useAuth();

    const uploadLargeFile = useUploadLargeFile();

    const { user } = useAuthContext();
    const imageRef = useRef<HTMLInputElement>(null);
    const videoRef = useRef<HTMLInputElement>(null);
    const audioRef = useRef<HTMLInputElement>(null);
    const docRef = useRef<HTMLInputElement>(null);
    const videoThumbnailRef = useRef<HTMLInputElement>(null);
    const [modal, setModal] = useState<ModalType>("TEXT_POST");
    const [text, setText] = useState<string>(rest.text || "");
    const [images, setImages] = useState<Array<File>>(
      rest.image ? [rest.image] : []
    );
    const [videoThumbnail, setVideoThumbnail] = useState<Array<File>>([]);
    const [editImgaeIndex, setEditImageIndex] = useState<number | undefined>(
      undefined
    );
    const [video, setVideo] = useState<File | undefined>(undefined);
    const [audio, setAudio] = useState<File | undefined>(undefined);
    const [doc, setDoc] = useState<File | undefined>(undefined);
    const [poll, setPoll] = useState<Poll | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [audience, setAudience] = useState<Club | undefined>();

    const [createPost] = useCreatePostV2Mutation();
    const { token } = useClubPermission(club?.clubId);
    const { data: permission } = useGetFeatureToPlatformPermissionQuery();
    const { data: feedTypeData } = useGetUserFeedTypeListQuery();

    // VIDEO THUMBNAIL UPLOAD
    const uploadVideoThumbnail = (files: File, largeFileReturnData?: any) => {
      const formData = new FormData();
      formData.append("file", files);
      formData.append("videoFilenameData", largeFileReturnData);
      return axios.post(UPLOAD_LARGE_FILE_THUMBNAIL, formData, {
        headers: {
          "Content-Type": "multipart/form-date",
          Authorization: `Bearer ${auth?.user?.access_token}`,
        },
      });
    };

    const defaultFeed = useMemo(() => {
      return feedTypeData?.getUserFeedTypeList?.find(
        (item) => item?.default == true
      );
    }, [feedTypeData?.getUserFeedTypeList]);

    useEffect(() => {
      async function pasteHandler(this: Document, e: any) {
        if (e.clipboardData && e.clipboardData.items.length > 0) {
          for (let i = 0; i < e.clipboardData.items.length; i++) {
            if (new RegExp("image/").test(e.clipboardData.items[i].type)) {
              if (images.length + 1 > MAX_IMAGES) {
                return confirm({
                  ...AlertPaperconfigOption,
                  title: "Upload Error!",
                  description: "You can upload atmost 3 images",
                });
              } else {
                const blob = e.clipboardData.items[i].getAsFile();
                if (blob) {
                  if (blob.size > 10 * 1024 * 1024) {
                    return confirm({
                      ...AlertPaperconfigOption,
                      title: "Upload Error!",
                      description: "Can not upload image size more than 10 MB",
                    });
                  }
                  setImages((x) => [...x, blob]);
                }
              }
            }
          }
        }
      }
      window.addEventListener("paste", pasteHandler);
      return () => {
        window.removeEventListener("paste", pasteHandler);
      };
    }, [window, images, setImages]);

    const handleClose = () => {
      if (
        !(
          isEmpty(text) &&
          isEmpty(images) &&
          isEmpty(video) &&
          isEmpty(doc) &&
          isEmpty(poll)
        )
      ) {
        confirm({
          ...AlertPaperconfigOption,
          title: "Are you sure?",
          description: "You won't be able to revert this!",
          confirmationText: "Discard",
          cancellationText: "Go Back",
          cancellationButtonProps: {
            variant: "contained",
            // color: "primary",
            style: {
              display: "flex",
              backgroundColor: COLORS.buttonActive,
            },
          },
          confirmationButtonProps: {
            variant: "contained",
            // color: "primary",
            style: {
              backgroundColor: "#d33",
            },
          },
        }).then(() => {
          handleCloseModal();
        });
      } else {
        handleCloseModal();
      }
    };

    useImperativeHandle(ref, () => ({
      handleClose,
    }));

    const handleImageLoad = () => imageRef?.current?.click();
    const handleVideoLoad = () => videoRef?.current?.click();
    const handleAudioLoad = () => audioRef?.current?.click();
    const handlePdfLoad = () => docRef?.current?.click();
    const handleVideoThumbnailLoad = () => videoThumbnailRef?.current?.click();

    const handleImagesUpload = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        if (!files?.length) return;
        if (Array.from(files).some((x) => x.size > 10 * 1024 * 1024)) {
          event.target.value = "";
          if (!user?.canUploadLargeFiles) {
            return confirm({
              ...AlertPaperconfigOption,
              title: "Upload Error!",
              description: "Can not upload image size more than 10 MB",
            });
          }
        }
        if (size(images) + Number(files?.length) > MAX_IMAGES) {
          event.target.value = "";
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: "You can upload atmost 3 images",
          });
        }
        setImages((images) => [...images, ...Array.from(files)]);
        event.target.value = "";
      },
      [images]
    );

    const handleVideoUpload = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        if (!files?.length) return;
        const video = files[0];
        if (video.size > fileSize) {
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: fileSizeMessage,
          });
        }
        setVideo(files[0]);
        event.target.value = "";
      },
      [setVideo, fileSize, fileSizeMessage]
    );

    // HANDLE VIDEO THUMBNAIL UPLOAD
    const handleVideoThumbnailUpload = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const files = event.target.files;
      setVideoThumbnail(files as any);
    };
    const handleVideoThumbnailRemove = async () => {
      setVideoThumbnail([]);
    };

    const handleAudioUpload = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        if (!files?.length) return;
        const audio = files[0];
        const ext = audio.name.split(".").pop();
        if (!["mp3", "wav", "m4a"].includes(ext!)) {
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: "Upload file should be .mp3, .wav, .m4a",
          });
        }
        if (audio.size > 10 * 1024 * 1024) {
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: "Audio size exceeds 10MB",
          });
        }
        setAudio(files[0]);
        event.target.value = "";
      },
      [setAudio]
    );

    const handlePDFUpload = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        if (!files?.length) return;
        const pdf = files[0];
        if (pdf.size > 15 * 1024 * 1024) {
          return confirm({
            ...AlertPaperconfigOption,
            title: "Upload Error!",
            description: "Pdf size exceeds 15MB",
          });
        }
        setDoc(files[0]);
        event.target.value = "";
      },
      [setDoc]
    );

    const uploadFiles = (files: Array<File>) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      return axios.post(MEDIA_UPLOAD_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-date",
          Authorization: `Bearer ${auth?.user?.access_token}`,
        },
      });
    };
    // const uploadLargeFiles = (files: Array<File>) => {
    //   const formData = new FormData();
    //   files.forEach((file) => {
    //     formData.append("files", file);
    //   });
    //   return axios.post(UPLOAD_LARGE_FILES, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-date",
    //       Authorization: `Bearer ${auth?.user?.access_token}`,
    //     },
    //   });
    // };

    const handleCreatePost = async (event: SyntheticEvent) => {
      event.preventDefault();
      const refetchQueries: any = [
        {
          query: GetFeedByTypeDocument,
          variables: { feedType: defaultFeed?.feedType, limit: 20, offset: 0 },
        },
      ];
      if (club) {
        refetchQueries.push({
          query: ClubPostDocument,
          variables: { clubId: club.clubId, limit: 20, offset: 0 },
        });
      }

      if (isDesktop) {
        refetchQueries.push(
          {
            query: GetTrendingStocksByDollarTagDocument,
            variables: { limit: 3, offset: 0 },
          },
          {
            query: GetTrendingSectorsByDollarTagDocument,
            variables: { limit: 3, offset: 0 },
          },
          {
            query: GetTrendingHashTagsV2Document,
            variables: { limit: 3, offset: 0 },
          }
        );
      }
      const canPostInFeed =
        permission?.getFeatureToPlatformPermission?.includes(
          PlatformPermissionFromFeatureTypes?.CanPost
        );
      if (!canPostInFeed && !audience && !club) {
        return toast.error("Please select audience");
      }
      try {
        setLoading(true);
        const media: Array<Maybe<MediaInput>> = [];
        const files: Array<File> = [];
        if (images.length) {
          const compressImages = await imageCompression<Array<File>>(images);
          if (compressImages) {
            files.push(...compressImages);
          }
        }
        if (videoThumbnail.length) {
          const compressImages = await imageCompression<Array<File>>(
            videoThumbnail
          );
          if (compressImages) {
            files.push(...compressImages);
          }
        }
        if (video) {
          if (meData?.me?.canUploadLargeFiles) {
            // const largeFileReturnData = await toast?.promise(
            //   (async () => {
            //     await uploadLargeFile(video as any);
            //     throw Error("HALELUYAAAA");
            //   })(),
            //   {
            //     loading: "Posting...",
            //     success: "Post saved...",
            //     error: () => {
            //       handleOpenModal?.();
            //       return "Post failed!!!";
            //     },
            //   }
            // );

            const largeFileReturnData = await uploadLargeFile(video);
            if (largeFileReturnData) {
              media.push(largeFileReturnData);
              videoThumbnailArgs?.push(largeFileReturnData);
            }
            // data?.forEach((item: any) => {
            //   const file = JSON.parse(item.uploadfilename);
            //   media.push({
            //     name: item.uploadfilename,
            //     type: file.MIME.replace(/\/.*/i, "/*"),
            //     uri: item.uploadfilename,
            //   });
            // });

            if (
              videoThumbnail &&
              !isEmpty(videoThumbnail) &&
              !isEmpty(videoThumbnailArgs)
            ) {
              const compressedImage = await imageCompression<File>(
                videoThumbnail[0]
              );

              const { data: uploadThumbnailReturnData } =
                await uploadVideoThumbnail(
                  videoThumbnail[0],
                  largeFileReturnData?.uri
                );
              const file = JSON.parse(uploadThumbnailReturnData.uploadfilename);
              media.pop();
              media.push({
                name: uploadThumbnailReturnData.uploadfilename,
                type: file.MIME.replace(/\/.*/i, "/*"),
                uri: uploadThumbnailReturnData.uploadfilename,
              });
            }
          } else {
            files.push(video);
          }
        }
        if (audio) files.push(audio);
        if (doc) files.push(doc!);
        if (files.length) {
          const { data } = await uploadFiles(files);
          data?.forEach((item: any) => {
            const file = JSON.parse(item.uploadfilename);
            console.log("ITEM", item);
            media.push({
              name: item.uploadfilename,
              type: file.MIME.replace(/\/.*/i, "/*"),
              uri: item.uploadfilename,
            });
          });
        }
        let mewpoll: Maybe<CreatePollArgs> | undefined = undefined;
        if (poll) {
          mewpoll = {
            question: poll.question,
            options: poll?.options?.filter(Boolean),
            expiryEnum: poll.pollDuration,
          };
        }
        if (club || audience) {
          const clubId = club?.clubId || audience?.clubId;
          await createPost({
            variables: {
              data: {
                text: manageAnyHashtag(trim(text)),
                media,
                poll: mewpoll,
                clubId,
                clubBasedPermissionToken: token,
              },
            },
            refetchQueries,
          }).then(({ data }) => {
            handleCloseModal();
            sendEvent({
              event: "post_submitted",
            });
            toast.success(
              <Fragment>
                Post created successfully.&nbsp;
                <Link
                  href={`/posts/${data?.createPostV2?.postId}`}
                  target="_blank"
                  rel="noopener"
                >
                  view post
                </Link>
              </Fragment>
            );
          });
        } else {
          await createPost({
            variables: {
              data: {
                text: manageAnyHashtag(trim(text)),
                media,
                poll: mewpoll,
              },
            },
            refetchQueries,
          }).then(({ data }) => {
            handleCloseModal();
            toast.success(
              <Fragment>
                Post created successfully.&nbsp;
                <Link
                  target="_blank"
                  rel="noopener"
                  href={`/posts/${data?.createPostV2?.postId}`}
                >
                  view post
                </Link>
              </Fragment>
            );
          });
        }
      } catch (error: any) {
        toast.error("Something Went Wrong!");
      } finally {
        setLoading(false);
      }
    };

    const initPoll = poll ?? {
      question: "",
      pollDuration: PollExpiryEnum.OneDay,
      options: ["", ""],
    };

    const isDisable =
      isEmpty(text) &&
      isEmpty(images) &&
      !video &&
      !doc &&
      !audio &&
      isEmpty(poll);
    const isImageDisable = Boolean(
      images.length >= 3 || poll || video || doc || audio
    );
    const isMediaDisable = Boolean(
      images.length > 0 || poll || video || doc || audio
    );

    const renderVideo = useMemo(
      () => (
        <Video
          video={video}
          onClick={() => {
            setVideo(undefined);
            setVideoThumbnail([]);
          }}
          videoThumbnail={videoThumbnail[0]}
        >
          {meData?.me?.canUploadLargeFiles && (
            <Box
              gap={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                position: "absolute",

                justifyContent: "center",

                alignItems: "center",
              }}
            >
              {" "}
              <Box>
                <Tooltip title="Upload thumbnail">
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleVideoThumbnailLoad}
                    sx={{
                      // color: "white !important",
                      backgroundColor: "rgba(27, 106, 201, 0.8)",
                      zIndex: 1,

                      filter: "blur",
                      border: "white!important",
                    }}
                  >
                    <Typography fontSize="0.8rem" color="white">
                      {!isEmpty(videoThumbnail)
                        ? "Change Thumbnail"
                        : "Add Thumbnail"}
                    </Typography>
                    {/* <FileUploadOutlinedIcon /> */}
                  </Button>
                </Tooltip>
              </Box>
              {!isEmpty(videoThumbnail) && (
                <Box>
                  <Tooltip title="Remove thumbnail">
                    <Button
                      variant="outlined"
                      onClick={handleVideoThumbnailRemove}
                      sx={{
                        color: "white",
                        backgroundColor: "rgba(27, 106, 201, 0.8)",
                        zIndex: 1,

                        filter: "blur",
                        border: "white!important",
                      }}
                      size="small"
                    >
                      <Typography fontSize="0.8rem" color="white">
                        Remove
                      </Typography>
                      {/* <ClearOutlinedIcon /> */}
                    </Button>
                  </Tooltip>
                </Box>
              )}
            </Box>
          )}
        </Video>
      ),
      [video, setVideo, videoThumbnail]
    );

    const renderAudio = useMemo(
      () =>
        audio ? (
          <DisplayAudioPlayer
            audio={audio}
            onClick={() => setAudio(undefined)}
          />
        ) : null,
      [audio, setAudio]
    );

    return (
      <React.Fragment>
        {loading && (
          <Loader>
            <CircularProgress />
          </Loader>
        )}
        {modal === "CHOOSE_AUDIANCE" && (
          <ChooseAudience
            audience={audience}
            setAudience={setAudience}
            setModal={setModal}
          />
        )}
        {modal === "CREATE_A_POLL" && (
          <CreatePoll
            poll={initPoll}
            handleClose={handleClose}
            setModal={setModal}
            setPoll={setPoll}
          />
        )}
        {modal === "EDIT_IMAGE" && (
          <ImageEditor
            image={
              editImgaeIndex !== undefined ? images[editImgaeIndex] : undefined
            }
            editImgaeIndex={editImgaeIndex}
            setImages={setImages}
            setModal={setModal}
            setEditImageIndex={setEditImageIndex}
          />
        )}
        {modal === "TEXT_POST" && (
          <PostInput
            header={<ModalHeader title="New Post" onClick={handleClose} />}
            postHeader={
              <UserDisplay
                club={club}
                audience={audience}
                setModal={setModal}
              />
            }
            text={text}
            setText={setText}
            setModal={setModal}
            renderImages={images.map((image, key) => (
              <Image
                key={key}
                image={image}
                onClickDelete={() =>
                  setImages((x) => x.filter((_, i) => i !== key))
                }
                onClickEdit={() => {
                  setEditImageIndex(key);
                  setModal("EDIT_IMAGE");
                }}
              />
            ))}
            renderPoll={
              poll ? (
                <DisplayPoll
                  poll={poll}
                  setModal={setModal}
                  setPoll={setPoll}
                />
              ) : null
            }
            renderVideo={
              video && (
                <>
                  {/* <section>
                    <button
                      style={{ position: "absolute" }}
                      onClick={handleVideoThumbnailLoad}
                    >
                      Thumbnail
                    </button>
                  </section> */}
                  <section>{renderVideo}</section>{" "}
                </>
              )
            }
            renderAudio={renderAudio}
            renderDoc={doc ? <Pdf onClick={() => setDoc(undefined)} /> : null}
            uploadImageButton={
              <StyledTooltip
                title="Upload an image"
                placement="top-start"
                arrow
              >
                <IconButton
                  disabled={isImageDisable}
                  sx={{ color: COLORS.buttonActive }}
                  onClick={handleImageLoad}
                >
                  <ImageSmallIcon />
                </IconButton>
              </StyledTooltip>
            }
            uploadVideoButton={
              <StyledTooltip title="Upload a video" placement="top" arrow>
                <IconButton
                  disabled={isMediaDisable}
                  sx={{ color: COLORS.buttonActive }}
                  onClick={handleVideoLoad}
                >
                  <AddVideoIcon />
                </IconButton>
              </StyledTooltip>
            }
            uploadAudioButton={
              <StyledTooltip title="Upload Audio" placement="top" arrow>
                <IconButton
                  disabled={isMediaDisable}
                  sx={{ color: COLORS.buttonActive }}
                  onClick={handleAudioLoad}
                >
                  <AudioIcon />
                </IconButton>
              </StyledTooltip>
            }
            uploadPdfButton={
              <StyledTooltip title="Upload a pdf" placement="top" arrow>
                <IconButton
                  disabled={isMediaDisable}
                  sx={{ color: COLORS.buttonActive }}
                  onClick={handlePdfLoad}
                >
                  <AttachmentIcon />
                </IconButton>
              </StyledTooltip>
            }
            createPollButton={
              <StyledTooltip title="Create a Poll" placement="top" arrow>
                <IconButton
                  disabled={isMediaDisable}
                  sx={{ color: COLORS.buttonActive }}
                  onClick={() => setModal("CREATE_A_POLL")}
                >
                  <PollOutlined />
                </IconButton>
              </StyledTooltip>
            }
            createPostButton={
              <CustomButton
                sx={{
                  backgroundColor: COLORS.buttonActive,
                  color: COLORS.buttonTextActive,
                }}
                variant="contained"
                disabled={isDisable}
                onClick={(e: SyntheticEvent) => {
                  handleCreatePost(e);
                  // meData?.me?.canUploadLargeFiles &&
                  //   video &&
                  //   handleCloseModal(text, true);
                }}
                style={{ maxWidth: 120, width: "100%" }}
              >
                Post
              </CustomButton>
            }
          />
        )}
        <HiddenInput
          type="file"
          accept="image/*"
          ref={imageRef}
          onChange={handleImagesUpload}
          multiple
        />
        <HiddenInput
          type="file"
          accept="image/*"
          ref={videoThumbnailRef}
          onChange={handleVideoThumbnailUpload}
        />
        <HiddenInput
          type="file"
          accept="video/*"
          ref={videoRef}
          onChange={handleVideoUpload}
        />
        <HiddenInput
          type="file"
          accept=".pdf"
          ref={docRef}
          onChange={handlePDFUpload}
        />
        <HiddenInput
          type="file"
          accept=".mp3,.wav,.m4a"
          ref={audioRef}
          onChange={handleAudioUpload}
        />
      </React.Fragment>
    );
  }
);

const HiddenInput = styled.input`
  display: none;
`;

const StyledTooltip = styled(Tooltip)`
  .MuiTooltip-tooltipPlacementTop {
    margin-bottom: 0;
    margin-top: 24px;
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  backdrop-filter: blur(0.25px);
  border-radius: 4px;
  .MuiCircularProgress-colorPrimary {
    color: var(--button-color);
    opacity: 0.65;
  }
`;

const CustomButton = styled(Button)`
  &:hover {
    background-color: ${COLORS.buttonActive};
    color: ${COLORS.buttonTextActive};
  }
`;

import React, { FC, Fragment, PropsWithChildren } from "react";
import { Maybe, Post } from "api";
import { Button, Paper, Typography, styled } from "@mui/material";
import { useApolloClient } from "@apollo/client";

interface PostOffensiveContentTextProps {
  post: Maybe<Post> | undefined;
}

const PostOffensiveContentText: FC<
  PropsWithChildren<PostOffensiveContentTextProps>
> = ({ post, children }) => {
  const client = useApolloClient();

  const loading = true;
  const handleViewPost = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    if (!post) return;

    client.cache.modify({
      id: client.cache.identify({
        postId: post.postId,
        __typename: post.__typename,
      }),
      fields: {
        isSuspected: () => 0,
      },
    });
  };

  if (post?.isSuspected) {
    // return loading ? (
    //   <></>
    // ) : 
    return (
      <OffensiveContent>
        <Typography variant="caption">
          This post may contain potentially offensive content or spam.
        </Typography>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disableElevation
          onClick={handleViewPost}
        >
          View post
        </Button>
      </OffensiveContent>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default PostOffensiveContentText;

const OffensiveContent = styled(Paper)`
  font-family: "Trebuchet MS", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 8px 12px;
`;

import React, { Fragment } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import HeaderLogo from "components/HeaderLogo";


const PublicHeader = () => (
    <Fragment>
        <CustomAppBar position="fixed" elevation={0}>
            <Toolbar>
                <HeaderLogo component={Link} to="/" />
            </Toolbar>
        </CustomAppBar>
        <Toolbar />
    </Fragment>
)

export default PublicHeader;

const CustomAppBar = styled(AppBar)`
    @media (max-width: 360px) {
        .MuiTypography-h1 {
            display: block !important;
        }
    }
`;

import React, { FC } from "react";
import { Button, ButtonProps } from "@mui/material";
import styled from "@emotion/styled";
import { KeyboardArrowDown } from '@mui/icons-material';

const SharedOn: FC<ButtonProps> = (props) => <StyledButton
    {...props}
    size='small'
    disableElevation
    endIcon={<KeyboardArrowDown />}
/>

export default SharedOn;

const StyledButton = styled(Button)`
    font-size: 1rem;
`;
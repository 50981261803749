import { Typography, styled } from "@mui/material";
import { Club, Maybe, useMeQuery } from "api";
import { useAuthContext } from "context/AuthContext";
import React, { FC, PropsWithChildren } from "react";
import Avatar from "components/Avatar";
import { getIsRingVisible } from "utills";

export const UserDisplay: FC<
  PropsWithChildren<{ club?: Maybe<Club> | undefined }>
> = ({ children }) => {
  const { user } = useAuthContext();
  const { data } = useMeQuery();
  return (
    <UserDisplayContainer>
      <Avatar
        src={data?.me?.avatar?.showUri}
        hasRing={getIsRingVisible(data?.me?.membershipLevel)}
      />
      <div>
        <Typography variant="body1">
          {`${data?.me?.firstName} ${data?.me?.lastName} `}
          <RightArrow>&#9658;</RightArrow>
          {children}
        </Typography>
        <Typography variant="subtitle2" sx={{marginTop: -1.5}}>@{user?.username}</Typography>
      </div>
    </UserDisplayContainer>
  );
};

const RightArrow = styled("span")`
  margin: 0 2px;
  font-size: 15px;
`;

const UserDisplayContainer = styled("div")`
  display: flex;
  align-items: center;
  padding: 0 0 12px 0;
  .MuiAvatar-root {
    height: 45px;
    width: 45px;
    margin-right: 10px;
  }
  .MuiTypography-body1 {
    line-height: 1.2;
    margin-bottom: 5px;
  }
`;

import React, { FC, Fragment } from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash-es";
import { Maybe, User, Club } from "api";
import { sendEvent } from "ga4";

interface PostHeaderTitleProps {
    user: Maybe<User> | undefined;
    club: Maybe<Club> | undefined;
}

const PostHeaderTitle: FC<PostHeaderTitleProps> = ({ user, club }) => {
    const onClick = () => {
        sendEvent({
            event: "user_button_clicked",
            component_name: "post"
        })
    }

    const onClubClick = () => {
        sendEvent({
            event: "club_button_clicked",
            component_name: "post"
        })


    }
    return (
        <CustomTypography variant="body2" onClick={onClick}>
            <Link to={`/profile/${user?.username}`}>{`${user?.firstName} ${user?.lastName}`}</Link>
            {Boolean(user?.isVerified) && <Image src={require("assets/image/blue-check.webp")} alt="Blue Tick" />}
            {Boolean(user?.greenTick) && <Image src={require("assets/image/green-check.webp")} alt="Green Tick" />}
            {Boolean(user?.tick3) && <Image src={require("assets/image/yellow-tick.webp")} alt="Yellow Tick" />}
            {!isEmpty(club) && (
                <Fragment>
                    <ArrowRight />
                    <Link to={`/club/${club?.clubId}`} onClick={onClubClick}>{club?.clubName}</Link>
                </Fragment>
            )}
        </CustomTypography>
    )
}

export default PostHeaderTitle;

const Image = styled.img`
    height: 14px;
    width: 14px;
    margin-left: 4px;
`;

const CustomTypography = styled(Typography)`
    display: flex;
    align-items: center;
    a {
        text-decoration: none;
        color: inherit;
    }
    a:hover {
        color: inherit;
        text-decoration: underline;
    }
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: bold;
    /* @media (min-width: 375px) {
        font-size: 1rem;
        line-height: 1.125rem;
    }
    @media (min-width: 540px) {
        font-size: 1.125rem;
        line-height: 1.5rem;
    } */
`;

import React, { FC } from "react";
import {
  FollowEntity,
  Hashtag,
  Maybe,
  PlatformPermissionFromFeatureTypes,
  useFollowHashtagV2Mutation,
  useGetFeatureToPlatformPermissionQuery,
  useUnfollowHashtagV2Mutation,
} from "api";
import {
  updateCacheOnFollowHashTag,
  updateCacheOnUnFollowHashTag,
} from "config/apollo";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import { Button, ButtonProps } from "@mui/material";
import { usePermissionFeatureMapping } from "hooks";
import {
  TopicFollowButtonClickedComponentName,
  UpgradeModalInitialised,
  sendEvent,
} from "ga4";

interface HashtagFollowUnfollowButtonProps
  extends Exclude<ButtonProps, "onClick"> {
  hashtag: Maybe<Hashtag> | undefined;
  showUnfollowButton?: boolean;
  hideFollowingButton?: boolean;
  component_name: TopicFollowButtonClickedComponentName;
  upgrade_component_name: UpgradeModalInitialised;
}

export const HashtagFollowUnfollowButton: FC<
  HashtagFollowUnfollowButtonProps
> = ({
  hashtag,
  showUnfollowButton,
  hideFollowingButton,
  component_name,
  upgrade_component_name,
  ...rest
}) => {
    if (!hashtag) return null;

    const { platformFeatureMap } = usePermissionFeatureMapping();
    const [follow] = useFollowHashtagV2Mutation();
    const [unfollow] = useUnfollowHashtagV2Mutation();
    const { handleModalOpen } = useUpgradePlanContext();
    const { data } = useGetFeatureToPlatformPermissionQuery();

    const handleFollow = React.useCallback(
      (event: React.SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();
        if (
          !data?.getFeatureToPlatformPermission?.includes(
            PlatformPermissionFromFeatureTypes.CanFollow
          )
        ) {
          sendEvent({
            event: "upgrade_modal_initialised",
            component_name: upgrade_component_name as UpgradeModalInitialised,
            feature_name_list:
              platformFeatureMap[PlatformPermissionFromFeatureTypes.CanFollow],
          });
          handleModalOpen();
          return;
        }
        sendEvent({ event: "topic_follow_button_clicked", component_name });
        follow({
          variables: {
            hashtagId: `${hashtag?.TagId}`,
          },
          update: (cache) => {
            updateCacheOnFollowHashTag(cache, hashtag);
          },
        });
      },
      [hashtag?.TagId, data?.getFeatureToPlatformPermission]
    );

    const handleUnfollow = React.useCallback(
      (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (
          !data?.getFeatureToPlatformPermission?.includes(
            PlatformPermissionFromFeatureTypes.CanFollow
          )
        ) {
          sendEvent({
            event: "upgrade_modal_initialised",
            component_name: upgrade_component_name as UpgradeModalInitialised,
            feature_name_list:
              platformFeatureMap[PlatformPermissionFromFeatureTypes.CanFollow],
          });
          handleModalOpen();
          return;
        }
        sendEvent({
          event: "topic_unfollow_button_clicked",
          component_name: "topic_details",
        });
        unfollow({
          variables: {
            hashtagId: `${hashtag?.TagId}`,
          },
          update: (cache) => {
            updateCacheOnUnFollowHashTag(cache, hashtag);
          },
        });
      },
      [hashtag?.TagId, data?.getFeatureToPlatformPermission]
    );

    if (!hashtag?.isfollow)
      return (
        <Button
          variant="contained"
          disableElevation
          onClick={handleFollow}
          {...rest}
        >
          Follow
        </Button>
      );
    if (hashtag?.isfollow && showUnfollowButton)
      return (
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={handleUnfollow}
          {...rest}
        >
          Unfollow
        </Button>
      );
    if (hashtag?.isfollow && !hideFollowingButton)
      return (
        <Button variant="contained" color="secondary" disabled>
          Following
        </Button>
      );
    return null;
  };

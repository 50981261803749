import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  IconButtonProps,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { FC, useCallback } from "react";
import { Fragment } from "react";
import { BackIcon } from "icons";
import CloseIcon from "@mui/icons-material/Close";
import { useGetListOfClubsWithQueryPermissionQuery } from "api";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useFormikContext } from "formik";
import { AskQueryData } from "./AskQuery";

interface AskInClubProps {
  setModal: React.Dispatch<React.SetStateAction<"AskQuery" | "AskInClub">>;
}

const AskInClub: FC<AskInClubProps> = ({ setModal }) => {
  const { values, setFieldValue } = useFormikContext<AskQueryData>();
  const { data } = useGetListOfClubsWithQueryPermissionQuery();
  const [value, setValue] = React.useState<string | undefined>(values.clubId);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleNextOrSave = useCallback(() => {
    setFieldValue("clubId", value);
    setModal("AskQuery");
  }, [value]);

  const handleBack = () => {
    setModal("AskQuery");
  };

  return (
    <Fragment>
      <ModalHeader onClick={handleBack}/>
      <Divider />
      <Typography
        sx={{
          "&&": {
            marginTop: "8px",
            marginLeft: "12px",
            paddingBottom: "8px",
          },
        }}
      >
        Please select a club to submit your query
      </Typography>
      <DialogContent
        sx={{
          "&&": {
            paddingTop: 0,
          },
        }}
      >
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          sx={{
            "&&": {
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              paddingBottom: "8px",
            },
          }}
        >
          {data?.getListOfClubsWithQueryPermission?.map((club) => (
            <Paper
              key={club?.clubId}
              sx={{
                "&&": {
                  padding: "8px",
                },
              }}
            >
              <FormControlLabel
                key={club?.clubId}
                value={club?.clubId}
                labelPlacement="start"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label={
                  <Stack
                    direction="row"
                    alignItems="center"
                    flex={1}
                    spacing={1}
                  >
                    <LazyLoadImage
                      src={club?.avatar?.LowResUri}
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: 6,
                      }}
                    />
                    <Typography>{club?.clubName}</Typography>
                  </Stack>
                }
                sx={{
                  "&&": {
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: 0,
                    marginRight: 0,
                  },
                }}
              />
            </Paper>
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={{ "&&": { justifyContent: "flex-end" } }}>
        {values.clubId ? (
          <Button variant="outlined" disableElevation onClick={handleBack}>
            Back
          </Button>
        ) : null}
        <Button
          variant="contained"
          disableElevation
          onClick={handleNextOrSave}
          disabled={!value}
        >
          {values.clubId ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default AskInClub;

const ModalHeader: FC<IconButtonProps> = (props) => {
  return (
    <ModalTitle>
      <IconButton className="back" {...props}>
        <BackIcon />
      </IconButton>
      Ask Query
      <IconButton className="close" {...props}>
        <CloseIcon />
      </IconButton>
    </ModalTitle>
  );
};

const ModalTitle = styled(DialogTitle)({
  "& .close": {
    visibility: "hidden",
  },
  "@media (min-width: 540px)": {
    "& .back": {
      visibility: "hidden",
    },
    "& .close": {
      visibility: "visible",
    },
  },
});

import React, { FC } from "react";
import styled from "@emotion/styled";
import {
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@mui/material";
import Modal from "components/Modal";
import { Close } from "@mui/icons-material";
import ReferBanner from "./ReferBannar";
import ReferYourFriend from "./ReferYourFriend";

interface ReferModalProps extends DialogProps {
  onhandleClose: () => void;
}

const ReferModal: FC<ReferModalProps> = ({ onhandleClose, ...rest }) => {
  return (
    <CustomModal {...rest}>
      <DialogTitle>
        <Typography variant="h3">Refer and Earn</Typography>
        <IconButton onClick={onhandleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: "0 !important" }}>
        <ReferBanner />
        <ReferYourFriend />
      </DialogContent>
    </CustomModal>
  );
};

export default ReferModal;

const CustomModal = styled(Modal)`
  .MuiPaper-root {
    height: auto;
    max-height: auto;
  }
`;

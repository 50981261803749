import React, { FC } from "react";
import PdfImage from "assets/image/pdf.webp";
import styled from "@emotion/styled";
import { IconButton, IconButtonProps, Paper } from "@mui/material";
import { Close } from "@mui/icons-material";

export const Pdf: FC<IconButtonProps> = (props) => (
    <Container variant="outlined">
        <IconButton {...props}>
            <Close />
        </IconButton>
        <Image
            src={PdfImage}
            alt="pdf"
        />
    </Container>
)

const Image = styled.img``;

const Container = styled(Paper)`
    position: relative;
    width: 100%;
    margin-top: 10px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
   .MuiIconButton-root {
        background-color: #2196f3;
        &:hover, &:active , &:focus {
            background-color: #2196f3;
        }
        color: #ffffff;
        position: absolute;
        right: 4px;
        top: 4px;
    }
`;
import React, { Fragment, createContext, useState, FC, useContext, PropsWithChildren } from "react";
import ReferModal from "./ReferModal";
import ReactGA from "react-ga4";
import { CLICK_REFER_AND_EARN_BUTTON } from "utills/events";

const ReferContext = createContext<{
    handleOpenReferDrawer?: () => void;
}>({});

const ReferProvider: FC<PropsWithChildren> = ({ children }) => {
    const [open, setOpen] = useState(false);

    const handleOpenReferDrawer = () => {
        ReactGA.event(CLICK_REFER_AND_EARN_BUTTON);
        setOpen(true);
    }
    const onhandleClose = () => setOpen(false);
    return (
        <Fragment>
            <ReferContext.Provider value={{ handleOpenReferDrawer }}>
                {children}
            </ReferContext.Provider>
            <ReferModal open={open} onClose={onhandleClose} onhandleClose={onhandleClose} />
        </Fragment>
    )
}

export default ReferProvider;

export const useRefer = () => useContext(ReferContext);
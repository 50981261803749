import React, { FC, Fragment } from "react";
import styled from "@emotion/styled";
import { Maybe, Media } from "api";
import 'react-image-lightbox/style.css';

interface ImagePostProps {
    media?: Maybe<Maybe<Media>[]> | undefined;
}

const ImagePost: FC<ImagePostProps> = ({ media }) => {
    const images: Array<string> = media?.filter(x => x?.type.includes('image/'))?.map(x => x?.uri?.HighResUri) || [];

    return (
        <Fragment>

            {images?.map((item, key) => (
                <ImageContainer key={key}>
                    <Image
                        src={item}
                        alt="image"
                    />
                </ImageContainer>
            ))}
        </Fragment>
    );
};

export default ImagePost;

const ImageContainer = styled.div`
  margin: 10px auto;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
`;


import { lazy, Suspense } from "react";
import { CreateAndSharePostContext } from "./context";
import { FC, PropsWithChildren, useReducer } from "react";
import { Modal } from "./styles";
import { State, ActionTypes } from "./types";
import { reducer } from "./reducer";
import {
  Avatar,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

export { FloatingButtonMenu } from "./FloatingButtonMenu";
export { ActionTypes };
export { useCreateAndSharePostContext } from "./context";
export { CreatePostButton } from "./CreatePostButton";

const CreatePost = lazy(() => import("./CreatePost"));
const AskQuery = lazy(() => import("./AskQuery"));
const EditAskQuery = lazy(() => import("./EditAskQuery"));
const ShareStockIdea = lazy(() => import("./ShareStockIdea"));
const EditShareStockIdea = lazy(() => import("./EditShareStockIdea"));
const SharePost = lazy(() => import("./SharePost"));
const EditPost = lazy(() => import("./EditPost"));
const SharedPosts = lazy(() => import("./SharedPosts"));
const MediaOfPosts = lazy(() => import("./MediaOfPosts"));
const EditedPostHistories = lazy(() => import("./EditedPostHistories"));

const initialState: State = {
  modal: undefined,
};

const CreateAndSharePostProvider: FC<PropsWithChildren> = ({
  children,
  ...props
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleCloseModal = () => dispatch({ type: ActionTypes.CLOSE_MODAL });

  return (
    <CreateAndSharePostContext.Provider {...props} value={[state, dispatch]}>
      {children}
      <Modal open={Boolean(state.modal)}>
        {state.modal === "POST" && (
          <Suspense>
            <CreatePost handleCloseModal={handleCloseModal} />
          </Suspense>
        )}
        {state.modal === "ASK_QUERY" && (
          <Suspense>
            <AskQuery handleCloseModal={handleCloseModal} />
          </Suspense>
        )}
        {state.modal === "EDIT_ASK_QUERY" && (
          <Suspense>
            <EditAskQuery handleCloseModal={handleCloseModal} />
          </Suspense>
        )}
        {state.modal === "SHARE_STOCK_IDEA" && (
          <Suspense>
            <ShareStockIdea handleCloseModal={handleCloseModal} />
          </Suspense>
        )}
        {state.modal === "EDIT_SHARE_STOCK_IDEA" && (
          <Suspense>
            <EditShareStockIdea handleCloseModal={handleCloseModal} />
          </Suspense>
        )}
        {state.modal === "SHARE_POST" && (
          <Suspense>
            <SharePost handleCloseModal={handleCloseModal} />
          </Suspense>
        )}
        {state.modal === "EDIT_POST" && (
          <Suspense>
            <EditPost handleCloseModal={handleCloseModal} />
          </Suspense>
        )}
        {state.modal === "EDITED_POST_HOSTORIES" && (
          <Suspense>
            <EditedPostHistories handleCloseModal={handleCloseModal} />
          </Suspense>
        )}
        {state.modal === "SHARED_POSTS" && (
          <Suspense>
            <SharedPosts handleCloseModal={handleCloseModal} />
          </Suspense>
        )}
        {state.modal === "MEDIA_OF_POSTS" && (
          <Suspense>
            <MediaOfPosts handleCloseModal={handleCloseModal} />
          </Suspense>
        )}
      </Modal>
    </CreateAndSharePostContext.Provider>
  );
};

export default CreateAndSharePostProvider;

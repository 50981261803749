import { ExternalResource, useGetMultipleExternalResourceQuery } from "api";

type Keys =
  | "REFER_T_&_C_LINK"
  | "NPS_TIME"
  | "FAQ"
  | "PRODUCT_TOUR_APP"
  | "PRODUCT_TOUR_WEB"
  | "RESTRICTION_MODAL_OFFER_INFO"
  | "MEMBERSHIP_INFO_MODAL_CTA_URL"
  | "MEMBERSHIP_INFO_MODAL_DATA";

type ExternalResources = Record<Keys, any>;

export const useGetExternalResources = () => {
  const { data, ...rest } = useGetMultipleExternalResourceQuery({
    variables: {
      type: [
        "REFER_T_&_C_LINK",
        "NPS_TIME",
        "FAQ",
        "PRODUCT_TOUR_APP",
        "PRODUCT_TOUR_WEB",
        "RESTRICTION_MODAL_OFFER_INFO",
        "MEMBERSHIP_INFO_MODAL_CTA_URL",
        "MEMBERSHIP_INFO_MODAL_DATA",
      ],
    },
  });
  const externalResources = (
    data?.getMultipleExternalResource || []
  ).reduce<ExternalResources>(
    (a, c) => ({ ...a, [`${c?.type}`]: c?.value }),
    {} as ExternalResources
  );
  return {
    data: {
      ...data,
    },
    externalResources,
    ...rest,
  };
};

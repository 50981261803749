
import { StepType } from "@reactour/tour";
import { Maybe, TourKeys } from "api";
import { isMobile } from 'react-device-detect';

const stepsMobile = (theme): Record<string, Record<number, Omit<StepType, "content">>> => {
    return {
        "home": {
            1: {
                selector: '[data-tut="reactour__home_nav"]',
                padding: 0,
                position: "bottom",
                styles: {
                    popover: (base) => ({ ...base, top: 0, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6, fontSize: 12 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })

                }
            },

            3: {
                selector: '[data-tut="reactour__learn_nav"]',
                padding: 0,
                position: "bottom",
                styles: {
                    popover: (base) => ({ ...base, top: 0, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6, fontSize: 12 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            5: {
                selector: '[data-tut="reactour__search_nav"]',
                padding: 0,
                position: "bottom",
                styles: {
                    popover: (base) => ({ ...base, top: 0, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6, fontSize: 12 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            6: {
                selector: '[data-tut="reactour__watchlist"]',
                padding: 0,
                position: "bottom",
                styles: {
                    maskArea: (base) => ({ ...base, rx: 100 }),
                    popover: (base) => ({ ...base, top: 20, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6, fontSize: 12 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            10: {
                selector: '[data-tut="reactour__bell_notification_nav"]',
                padding: 0,
                position: "bottom",
                styles: {
                    popover: (base) => ({ ...base, top: 0, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6, fontSize: 12 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            13: {
                selector: '[data-tut="reactour__button_to_post_nav"]',
                padding: 0,
                position: "bottom",
                styles: {
                    popover: (base) => ({ ...base, top: 0, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6, fontSize: 12 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            14: {
                selector: '[data-tut="reactour__select_feed"]',
                padding: 0,
                position: "bottom",
                styles: {
                    popover: (base) => ({ ...base, top: 10, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6, fontSize: 12 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            }

        },

        // "club": {
        //     1: {
        //         selector: '[data-tut="reactour__club_post"]',
        //         padding: 5,
        //         position: "bottom",
        //         styles: {
        //             popover: (base) => ({ ...base, top: 0, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 })
        //         }
        //     },
        //     2: {
        //         selector: '[data-tut="reactour__club_tranding_topic"]',
        //         padding: 5,
        //         position: "bottom",
        //         styles: {
        //             popover: (base) => ({ ...base, top: 0, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 })
        //         }
        //     },

        // }
    }
}
const stepsWeb = (theme): Record<string, Record<number, Omit<StepType, "content">>> => {
    return {
        "home": {
            1: {
                selector: '[data-tut="reactour__home"]',
                padding: 0,
                position: "right",
                styles: {
                    popover: (base) => ({ ...base, top: 0, left: 10, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            2: {
                selector: '[data-tut="reactour__explore"]',
                padding: 0,
                position: "right",
                styles: {
                    popover: (base) => ({ ...base, top: 0, left: 10, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            3: {
                selector: '[data-tut="reactour__learn"]',
                padding: 0,
                position: "right",
                styles: {
                    popover: (base) => ({ ...base, top: 0, left: 10, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            4: {
                selector: '[data-tut="reactour__clubs"]',
                padding: 0,
                position: "right",
                styles: {
                    popover: (base) => ({ ...base, top: 0, bottom: 0, margin: "auto", height: "fit-content", left: "auto", background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            5: {
                selector: '[data-tut="reactour__search"]',
                padding: 0,
                position: "bottom",
                styles: {
                    popover: (base) => ({ ...base, top: 20, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            6: {
                selector: '[data-tut="reactour__watchlist"]',
                padding: 0,
                position: "bottom",
                styles: {
                    maskArea: (base) => ({ ...base, rx: 100 }),
                    popover: (base) => ({ ...base, top: 20, left: -10, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            7: {
                selector: '[data-tut="reactour__treanding_stocks"]',
                padding: 0,
                position: "left",
                styles: {
                    popover: (base) => {
                        return ({ ...base, top: 20, left: -10, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 })
                    },
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            10: {
                selector: '[data-tut="reactour__bell_notification"]',
                padding: 0,
                position: "bottom",
                styles: {
                    maskArea: (base) => ({ ...base, rx: 100 }),
                    popover: (base) => ({ ...base, top: 20, left: -10, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 }),
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            11: {
                selector: '[data-tut="reactour__treanding_topic"]',
                padding: 0,
                position: "left",
                styles: {
                    popover: (base) => {
                        return ({ ...base, top: 160, left: -350, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 })
                    },
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            12: {
                selector: '[data-tut="reactour__refer_and_earn"]',
                padding: 0,
                position: "left",
                styles: {
                    popover: (base) => {
                        return ({ ...base, top: 0, left: -20, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 })
                    },
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            13: {
                selector: '[data-tut="reactour__button_to_post"]',
                padding: 0,
                position: "left",
                styles: {
                    maskArea: (base) => ({ ...base, rx: 100 }),
                    popover: (base) => {
                        return ({ ...base, top: -10, left: -20, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 })
                    },
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            },
            14: {
                selector: '[data-tut="reactour__select_feed"]',
                padding: 0,
                position: "bottom",
                styles: {
                    popover: (base) => {
                        return ({ ...base, top: 15, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 })
                    },
                    close: (base) => ({ ...base, top: 12, right: 12 })
                }
            }
        },

        // "club": {
        //     1: {
        //         selector: '[data-tut="reactour__club_post"]',
        //         padding: 5,
        //         position: "bottom",
        //         styles: {
        //             popover: (base) => ({ ...base, top: 0, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 })
        //         }
        //     },
        //     2: {
        //         selector: '[data-tut="reactour__club_tranding_topic"]',
        //         padding: 5,
        //         position: "bottom",
        //         styles: {
        //             popover: (base) => ({ ...base, top: 0, left: 0, background: theme == "dark" ? "#000" : "#fff", borderRadius: 6 })
        //         }
        //     },

        // }
    }
}

export const getTourSteps = (data: Maybe<Maybe<{ __typename?: "TourKeys" | undefined; } & Pick<TourKeys, "tourComponent" | "tourId" | "tourKey" | "tourDescription">>[]> | undefined, screen: string, theme): Array<StepType & { key: number }> => {

    let steps = stepsWeb(theme)[screen];
    console.log({ theme });

    if (isMobile) {
        steps = stepsMobile(theme)[screen]
    }

    return data?.map(d => {
        if (!d?.tourKey) return undefined;
        if (!steps[d?.tourKey]) return undefined;
        return ({
            content: d?.tourDescription,
            key: d?.tourKey,
            ...steps[d?.tourKey]
        })
    }).filter(x => Boolean(x)) as Array<StepType & { key: number }>;
}
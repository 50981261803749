import styled from "@emotion/styled";
import { Card } from "@mui/material";
import PostComponent from "components/PostComponent";
import ActivityMessage from "components/PostComponent/ActivityMessage";
import SmartFeed from "features/smart-feed";
import React, { FC, Fragment } from "react";

const SkeletonPostActivities: FC<{ loading: boolean }> = ({ loading }) => {
  return Array.from(new Array(3))?.map((activity, key) => (
    <Fragment key={key}>
      <PostComponent
        postHeaderComponent={<ActivityMessage activity={undefined} />}
        post={undefined}
        component_name={undefined}
        loading={loading}
      />
    </Fragment>
  ));
};

export default SkeletonPostActivities;

const SkeletonHeaderContainer = styled(Card)`
  margin-top: 16px;
  &:last-child {
    margin-bottom: 16px;
  }
  .MuiCardActions-root {
    .MuiButton-root {
      flex: 0 1 100%;
    }
  }
  @media (max-width: 350px) {
    margin-top: 0px;
  }
`;

export const PostsContainer = styled.div`
  max-width: 550px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

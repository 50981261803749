import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import React from 'react';
import { Toaster } from 'react-hot-toast';

const CustomToster = () => {
    const isDesktop = useMediaQuery('(min-width:540px)');
    const theme = useTheme();
    return (
        <Toaster
            position={isDesktop ? 'bottom-left' : 'bottom-center'}
            toastOptions={{
                style: {
                    background: theme.palette.background.default,
                    color: theme.typography.h3.color
                }
            }}
        />
    )
}

export default CustomToster;

import React, { createContext, FC, Fragment, PropsWithChildren, useContext, useState } from 'react';
import PlanModal from './PlanModal';

export interface IUpgradePlanContext {
  open: boolean;
  handleModalOpen: () => void;
  handleModalClose: () => void;
}

export const UpgradePlanContext = createContext<IUpgradePlanContext>({} as IUpgradePlanContext);

export const UpgradePlanProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  }

  const handleModalClose = () => setOpen(false);

  return (
    <Fragment>
      <UpgradePlanContext.Provider value={{ open, handleModalOpen, handleModalClose }}>
        {children}
      </UpgradePlanContext.Provider>
      <PlanModal open={open} onhandleClose={handleModalClose} />
    </Fragment>
  );
};

export const useUpgradePlanContext = () => {
  return useContext(UpgradePlanContext)
};

export default UpgradePlanContext;

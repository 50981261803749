import React, { FC } from "react";
import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { subscriptionsContents } from "./SubscriptionContents";
import { ModalType } from "./SubscriptionModalContext";

interface SubscriptionModalProps extends DialogProps {
  handleCloseModal: VoidFunction;
  modal: ModalType;
}

export const SubscriptionModal: FC<SubscriptionModalProps> = ({ handleCloseModal, modal, ...rest }) => {

  const handleSuscribeLink = () => {
    window.open("https://stockedge.com/Plans/stockedgeclub", "_blank");
  }

  return (
    <Modal onClose={handleCloseModal} {...rest} >
      <ModalHeader>
        <CloseButton onClick={handleCloseModal}>
          <CloseIcon />
        </CloseButton>
      </ModalHeader>
      <Content>
        {subscriptionsContents(modal)}
      </Content>
      <Actions>
        <SubmitButton
          variant="contained"
          color="primary"
          onClick={handleSuscribeLink}
        >
          Upgrade
        </SubmitButton>
      </Actions>
    </Modal>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin: 25px 0;
`;



const SubmitButton = styled(Button)`
  && {
    height: 40px !important;
    width: min(178px, 100%) !important;
    font-size: .85rem;
    background-color: #1F4386;
    border-radius: 3px;
    &:hover,
    &:focus {
      background-color: #1F4386;
    }
  }
`;

const Modal = styled(Dialog)`
&& {
  .MuiBackdrop-root {
    background-color: rgba(31, 67, 134, 0.5);
  }

  .MuiPaper-root {
    width: min(500px, 100%);
  }

  @media (min-width: 540px) {
    /* ${Grid} {
      grid-template-columns: repeat(4, 1fr);
      align-items: flex-start;
    } */

    /* ${SubmitButton} {
      min-width: 175px !important;
      width: auto !important;
    } */
  }

  @media (max-width: 540px) {
    /* .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
      max-width: 100%;
    } */
  }
}
`;



const Actions = styled(DialogActions)`
  && {
    justify-content: center;
    padding: 24px 0;
  }
`;

const Content = styled(DialogContent)`
  && {
    padding: 8px 32px;
  }
`;

const ModalHeader = styled(DialogTitle)`
  && {
    justify-content: flex-end;
    display: flex;
    padding: 4px 4px 0;
  }
`;

const CloseButton = styled(IconButton)`
  && {
    padding: 8px;
    color: #5C7082 !important;
  }
`;

import React, { FC, memo } from "react";
import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import AudioPlayer from "react-player/lazy";
import imgSound from "assets/audio.webp";
import { Close } from "@mui/icons-material";

const DisplayAudioPlayer: FC<{ audio: File; onClick?: () => void; }> = memo(({ audio, onClick }) => {
    return (
        <Container>
            <IconButton onClick={onClick}>
                <Close />
            </IconButton>
            <img src={imgSound} alt="" />
            <AudioPlayer
                className="audio-player"
                url={URL.createObjectURL(audio)}
                width='100%'
                height='56px'
                controls
            />
        </Container>
    )
})

export default DisplayAudioPlayer;

const Container = styled.div`
    position: relative;
    height: 150px;
    position: relative;
    border-radius: 4px;
    background: #2196f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 20px;
    margin-top: 15px;
    img {
        height: 56px;
        width: 56px;
        margin-bottom: 20px;
        object-fit: cover;
        object-position: center;
    }
    .MuiIconButton-root {
        position: absolute;
        right: 8px;
        top: 8px;
        padding: 8px;
        z-index: 1;
    }
    .audio-player video {
        width: 100% !important;
        height: 100% !important;
        min-height: auto !important;
        max-height: 54px !important;
        background: inherit !important;
    }
`;
import React, { FC, PropsWithChildren, Fragment, useState } from "react";
import ReactGA from "react-ga4";
import RequestACallContext from "./RequestACallContext";
import RequestACallbackModal from "./RequestACallbackModal";
import { CALLBACK_REQUEST_INITIATED } from "utills/events";

const RequestACallProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    ReactGA.event(CALLBACK_REQUEST_INITIATED, {});
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);

  return (
    <Fragment>
      <RequestACallContext.Provider value={{ handleOpen }}>
        {children}
      </RequestACallContext.Provider>
      <RequestACallbackModal
        open={open}
        onClose={handleCloseModal}
        handleCloseModal={handleCloseModal}
      />
    </Fragment>
  );
};

export default RequestACallProvider;

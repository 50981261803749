import React, { FC, Fragment, useState } from "react";
import { Button, ButtonProps } from "@mui/material";
import {
  Maybe,
  Club,
  useJoinClubMutation,
  ClubMemberType,
  FeedPostsDocument,
} from "api";
import { useAuthContext } from "context/AuthContext";
import { updateCacheOnJoinClub } from "config/apollo";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import { usePermissionFeatureMapping } from "hooks";
import { ClubDisclaimerModal } from "components/ClubDisclaimerModal";
import { FormikHelpers } from "formik";
import { ClubJoinButtonClickedComponentName, UpgradeModalInitialised, sendEvent } from "ga4";

interface JoinClubButtonProps extends Exclude<ButtonProps, "onClick"> {
  club: Maybe<Club> | undefined;
  hideJoinedButton?: boolean;
  component_name: ClubJoinButtonClickedComponentName;
  upgrade_component_name: UpgradeModalInitialised;
}

export const JoinClubButton: FC<JoinClubButtonProps> = ({
  club,
  hideJoinedButton,
  component_name,
  upgrade_component_name,
  ...rest
}) => {
  if (!club) return null;
  const { clubFeatureMap } = usePermissionFeatureMapping();
  const [open, setOpen] = useState<boolean>(false);
  const { user } = useAuthContext();
  const { handleModalOpen } = useUpgradePlanContext();
  const [joinClub, { loading }] = useJoinClubMutation();

  const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!club?.canJoinClub) {
      sendEvent({
        event: "upgrade_modal_initialised",
        component_name: upgrade_component_name as UpgradeModalInitialised,
        feature_name_list: clubFeatureMap[club?.clubId as string]
      });
      handleModalOpen();
      return;
    }
    if (club?.showDisclaimer) {
      setOpen(true);
      return;
    }
    handleJoinClub();
  };

  const handleJoinClub = (agreedToDisclaimer = false) => {
    const data = {
      clubId: `${club?.clubId}`,
      memberType: ClubMemberType.Member,
      agreedToDisclaimer,
    };
    sendEvent({
      event: "club_join_button_clicked",
      component_name,
    });

    joinClub({
      variables: { data },
      update: (cache, { data }) => {
        updateCacheOnJoinClub(cache, user, club, data);
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: FeedPostsDocument,
          variables: { offset: 0, limit: 20 },
        },
      ],
    }).then(() => {
      setOpen(false);
    });
  };

  const handleOnSubmit = (
    values: { agreedToDisclaimer: boolean },
    formikHelpers: FormikHelpers<{ agreedToDisclaimer: boolean }>
  ) => {
    handleJoinClub(values.agreedToDisclaimer);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  if (club?.ismember === "Resolved" && !hideJoinedButton)
    return (
      <Button variant="text" color="info" disabled {...rest}>
        Joined
      </Button>
    );

  if (club?.ismember === "Pending" && !hideJoinedButton)
    return (
      <Button variant="text" color="info" disabled {...rest}>
        Requested
      </Button>
    );

  if (club?.ismember === "False")
    return (
      <Fragment>
        <Button
          disabled={loading}
          variant="contained"
          disableElevation
          onClick={handleOnClick}
          {...rest}
        >
          Join Club
        </Button>
        <ClubDisclaimerModal
          loading={loading}
          open={open}
          club={club}
          handleCloseModal={handleCloseModal}
          handleOnSubmit={handleOnSubmit}
        />
      </Fragment>
    );
  return null;
};

export default JoinClubButton;

import React, { FC } from "react";
import { Dialog, DialogProps, styled } from "@mui/material";

const BadgeModal: FC<DialogProps> = ({ children, ...rest }) => {
  return (
    <CustomModal disableEscapeKeyDown {...rest}>
      {children}
    </CustomModal>
  );
};

export default BadgeModal;

const CustomModal = styled(Dialog)({
  "&&": {
    ".MuiDialog-paper": {
      height: "auto",
      maxWidth: "calc(min(100%, 375px) - 20px)",
      width: "100%",
      borderRadius: 10,
      margin: 0,
    },
  },
});

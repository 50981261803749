import React, { FC } from "react";
import { Typography } from "@mui/material";
import {styled} from "@mui/material";
import { timeAgo } from "utills";
import DateTime from "components/DateTime";

interface TimeAgoProps {
  time: string | number;
  clock?: boolean;
}
const TimeAgo: FC<TimeAgoProps> = ({ time, clock = true }) => (
  <Typography variant="caption">
    {clock && <>&#128338; {" "}</>}
    {/* {timeAgo(time)} */}
    <DateTime date={time} />
  </Typography>
);

export default TimeAgo;

const Time = styled("time")`
  display: contents;
  color: var(--text-color-secondary);
`;

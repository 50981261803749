import { WebStorageStateStore, User } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";
import { OIDC_AUTHORITY, OIDC_CLIENT_ID } from ".";
import { isMobile } from "react-device-detect";

const ApplicationSourceMap = {
  App: 10,
  Web: 20,
  WebApp: 30,
};

const PlatformMap = {
  Android: 10,
  iOS: 20,
  Web: 90,
};

const oidcConfig: AuthProviderProps = {
  redirect_uri: `${window.location.origin}/auth/callback`,
  response_type: "code",
  scope: "openid profile email offline_access social.app",
  post_logout_redirect_uri: `${window.location.origin}`,
  silent_redirect_uri: `${window.location.origin}/auth/callback`,
  revokeTokensOnSignout: true,
  stateStore: new WebStorageStateStore({ store: localStorage }),
  userStore: new WebStorageStateStore({ store: localStorage }),
  stopCheckSessionOnError: false,
  automaticSilentRenew: true,
  monitorSession: true,
  authority: OIDC_AUTHORITY,
  client_id: OIDC_CLIENT_ID,
  extraQueryParams: {
    ApplicationSource: ApplicationSourceMap[isMobile ? "WebApp" : "Web"],
    Platform: PlatformMap["Web"],
  },
};

export default oidcConfig;

export const getOIDCUser = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`
  );

  if (!oidcStorage) return null;

  return User.fromStorageString(oidcStorage);
};

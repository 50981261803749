import React, {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useReducer,
} from "react";
import { reducer, initialState, State, ActionKind } from "./reducer";
import { PostInput } from "./SharePost";
import { useSharePost } from "./useSharePost";
import { useCallback } from "react";
import { size } from "lodash-es";
import { AlertPaperconfigOption } from "helper";
import { useConfirm } from "material-ui-confirm";
import { COLORS } from "assets/colors";

export const isContentAvailable = (state: State) =>
  size(state.images) ||
  size(state.text) ||
  Boolean(state.video) ||
  Boolean(state.doc);

export const CreatePostContainer = forwardRef((props, ref) => {
  const confirm = useConfirm();
  const { handleCloseModal } = useSharePost();
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleClose = useCallback(() => {
    if (isContentAvailable(state)) {
      confirm({
        ...AlertPaperconfigOption,
        title: "Are you sure?",
        description: "You won't be able to revert this!",
        confirmationText: "Discard",
        cancellationText: "Continue",
        cancellationButtonProps: {
          variant: "contained",
          // color: "primary",
          style: {
            display: "flex",
            backgroundColor:COLORS.buttonActive,
          }
        },
        confirmationButtonProps: {
          variant: "contained",
          color: "primary",
          style: {
            backgroundColor: "#d33",
          }
        }
      }).then(() => {
        dispatch({ type: ActionKind.Init });
        handleCloseModal();
      })
    } else {
      handleCloseModal();
    }
  }, [state, handleCloseModal]);

  useImperativeHandle(ref, () => ({
    handleClose,
  }));

  return (
    <Fragment>
      <PostInput state={state} dispatch={dispatch} handleClose={handleClose} />
    </Fragment>
  );
});

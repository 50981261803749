import React, {
  FC,
  Fragment,
  MouseEventHandler,
  useMemo,
  useState,
} from "react";
import {
  Button,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  styled,
  Link
} from "@mui/material";
import { isDarkMode } from "utills";
import {
  Maybe,
  useGetBadgeFeedbackQuestionLazyQuery,
  useAddBadgeJourneyFeedbackMutation,
  useGetAchievementDetailsQuery,
  MediaInput,
  useCreatePostV2Mutation,
  FeedPostsDocument,
  HasSeenPopUpMutation,
} from "api";
import { CloseIconButton, BackIconButton } from "components/Buttons";
import { FieldArray, Formik, FormikHelpers } from "formik";
import { ModalLoader } from "components/ModalLoader";
import toast from "react-hot-toast";
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from "react-lazy-load-image-component";
import axios from "axios";
import { MEDIA_UPLOAD_URL } from "config";
import { useAuth } from "react-oidc-context";
import imageCompression from "utills/image-compression";
import { manageAnyHashtag } from "utills";
import { trim } from "lodash-es";
import { FetchResult } from "@apollo/client";
import * as Yup from "yup";
import { sendEvent } from "ga4";

async function getImageFileFromUrl(imageUrl: string): Promise<File> {
  const response = await fetch(imageUrl);
  if (!response.ok) {
    throw new Error("Failed to fetch image");
  }
  const blob = await response.blob();
  const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
  const file = new File([blob], fileName, { type: blob.type });
  return file;
}

interface StrickCompleteProps {
  achievementType: string;
  sourceOfClick?: string;
  handleModalClose: () => void;
  handlePopupClose?: () => Promise<FetchResult<HasSeenPopUpMutation>>;
  handleCrossButtonClicked: () => Promise<void>;
}

const RedirectLazyLoadImage =
  LazyLoadImage as unknown as React.ComponentClass<LazyLoadImageProps>;

const StrickComplete: FC<StrickCompleteProps> = ({
  achievementType,
  sourceOfClick,
  handleModalClose,
  handlePopupClose,
  handleCrossButtonClicked,
}) => {
  const auth = useAuth();
  const [modal, setModal] = useState<
    "FeedbackModal" | "StreakCompleteModal" | "FeedbackAcknowlagement"
  >("StreakCompleteModal");
  const { data, loading } = useGetAchievementDetailsQuery({
    variables: { achievementType, sourceOfClick },
    fetchPolicy: "cache-and-network",
  });
  const [
    getBadgeFeedbackQuestion,
    { data: feedbackData, loading: feedbackLoading },
  ] = useGetBadgeFeedbackQuestionLazyQuery();

  const [addBadgeJourneyFeedback] = useAddBadgeJourneyFeedbackMutation();
  const [createPost, { loading: createPostLoading }] =
    useCreatePostV2Mutation();

  const renderText = useMemo(() => {
    const changevalue = "Power User";
    const re = new RegExp(changevalue);
    return data?.getAchievementDetails?.text3
      ?.split(re)
      .flatMap((x, key, arr) => {
        if (key + 1 === arr.length) return x;
        return [x, <PowerUser key={key}>{changevalue}</PowerUser>];
      });
  }, [data?.getAchievementDetails]);

  const handleOpenModal =
    (
      modal: "FeedbackModal" | "StreakCompleteModal" | "FeedbackAcknowlagement"
    ) =>
    async () => {
      if (modal === "FeedbackModal") {
        sendEvent({
          event: "share_your_journey",
        });
        await getBadgeFeedbackQuestion({
          variables: {
            badgeId: data?.getAchievementDetails?.badgeDetails?.badgeId,
          },
        });
      }

      setModal(modal);
    };

  const uploadFiles = (files: Array<File>) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    return axios.post(MEDIA_UPLOAD_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-date",
        Authorization: `Bearer ${auth?.user?.access_token}`,
      },
    });
  };

  const onShare: MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      sendEvent({
        event: "post_to_your_wall",
      });
      if (data?.getAchievementDetails?.share) {
        const media: Array<Maybe<MediaInput>> = [];
        if (data?.getAchievementDetails?.share?.imageLink) {
          const image: File | undefined = await getImageFileFromUrl(
            data?.getAchievementDetails?.share?.imageLink
          );
          if (image) {
            const compressImages = await imageCompression<Array<File>>([image]);
            if (compressImages) {
              const { data } = await uploadFiles(compressImages);
              data?.forEach((item: any) => {
                const file = JSON.parse(item.uploadfilename);
                media.push({
                  name: item.uploadfilename,
                  type: file.MIME.replace(/\/.*/i, "/*"),
                  uri: item.uploadfilename,
                });
              });
            }
          }
        }
        await createPost({
          variables: {
            data: {
              text: manageAnyHashtag(
                trim(data?.getAchievementDetails?.share?.text || "")
              ),
              media,
            },
          },
          refetchQueries: [
            {
              query: FeedPostsDocument,
              variables: { limit: 20, offset: 0 },
            },
          ],
          update: (cache, { data }) => {
            if (data?.createPostV2) {
              toast.success(
                <Fragment>
                  Post created successfully.&nbsp;
                  <Link
                    href={`/posts/${data?.createPostV2?.postId}`}
                    target="_blank"
                    rel="noopener"
                  >
                    view post
                  </Link>
                </Fragment>
              );
            }
          },
        });
        await handlePopupClose?.();
        handleModalClose();
      }
    } catch (e) {
      toast.error("Something went error!");
    }
  };

  const onSubmit = async (
    values: FeedbackModalForm,
    formikHelpers: FormikHelpers<FeedbackModalForm>
  ) => {
    try {
      sendEvent({
        event: "submit_your_journey",
      });
      const questionResponse = values.questionResponse.map((x) => ({
        questionId: x?.questionId,
        badgeId: x?.badgeId,
        responseText: x.responseText,
      }));
      await addBadgeJourneyFeedback({
        variables: {
          questionResponse,
        },
        update: (cache, { data }) => {
          if (data?.addBadgeJourneyFeedback?.success) {
            cache.modify({
              fields: {
                getPowerUserPromotion: (existing) => {
                  return { ...existing, allowFeedback: false };
                },
              },
            });
          }
        },
      }).then(({ data }) => {
        if (data?.addBadgeJourneyFeedback) {
          handleOpenModal("FeedbackAcknowlagement")();
        }
      });
      formikHelpers.resetForm();
    } catch (e) {
      toast.error("Something went wrong!");
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const initialValues: FeedbackModalForm = {
    questionResponse:
      feedbackData?.getBadgeFeedbackQuestion?.map((x) => ({
        questionId: `${x?.questionId}`,
        questionText: `${x?.questionText}`,
        badgeId: `${x?.badgeId}`,
        responseText: "",
      })) || [],
  };

  if (loading || feedbackLoading) {
    return (
      <DialogContent sx={{ minHeight: "200px" }}>
        <ModalLoader open />
      </DialogContent>
    );
  }
  return (
    <>
      {modal === "StreakCompleteModal" ? (
        <Fragment>
          <CloseButton onClick={handleCrossButtonClicked} />
          <Container>
            <Typography sx={{ fontSize: "1.125rem", textAlign: "center" }}>
              {data?.getAchievementDetails?.text1}
            </Typography>
            <Image
              src={
                data?.getAchievementDetails?.badgeDetails?.badgeIcon as string
              }
            />
            <Title>{data?.getAchievementDetails?.text2}</Title>
            <Subtitle>{renderText}</Subtitle>
            <Stack
              sx={{
                gap: 2,
                alignSelf: "stretch",
                marginTop: 1.5,
                ".MuiButton-root": {
                  margin: 0,
                  borderRadius: "200px",
                  height: "44px",
                },
              }}
            >
              {sourceOfClick === "otherUser" ? (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleCrossButtonClicked}
                  size="large"
                >
                  Ok
                </Button>
              ) : (
                <Fragment>
                  {data?.getAchievementDetails?.allowFeedback ? (
                    <Button
                      variant="outlined"
                      size="large"
                      disableElevation
                      onClick={handleOpenModal("FeedbackModal")}
                    >
                      Share your journey with us
                    </Button>
                  ) : null}
                  {sourceOfClick !== "popUp" ? (
                    <Stack direction={"row"}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={onShare}
                        size="small"
                      >
                        Post to your wall
                      </Button>
                    </Stack>
                  ) : null}
                </Fragment>
              )}
            </Stack>
          </Container>
        </Fragment>
      ) : modal === "FeedbackModal" ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={Yup.object({
            questionResponse: Yup.array().of(
              Yup.object().shape({
                responseText: Yup.string().required("Answer is required"),
              })
            ),
          })}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            submitForm,
          }) => (
            <Fragment>
              <ModalLoader open={isSubmitting || loading} />
              <DialogTitle
                sx={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: 1.5,
                }}
              >
                <BackButton onClick={handleOpenModal("StreakCompleteModal")} />
                Share your journey with us
              </DialogTitle>
              <FeedbackContainer>
                <FieldArray
                  name="questionResponse"
                  render={() => (
                    <>
                      {values.questionResponse.map((response, index) => (
                        <Stack key={index} direction="column" spacing={1}>
                          <Typography>Q. {response.questionText}</Typography>
                          <TextField
                            name={`questionResponse.${index}.responseText`}
                            value={response.responseText}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            multiline
                            fullWidth
                            placeholder="Write here"
                            maxRows={5}
                            minRows={2}
                            error={Boolean(
                              (errors?.questionResponse?.[index] as any)
                                ?.responseText
                            )}
                            helperText={
                              (errors?.questionResponse?.[index] as any)
                                ?.responseText
                            }
                          />
                        </Stack>
                      ))}
                    </>
                  )}
                />
                <Stack
                  sx={{
                    gap: 2,
                    alignSelf: "stretch",
                    ".MuiButton-root": {
                      margin: 0,
                      borderRadius: "200px",
                      height: "46px",
                    },
                  }}
                >
                  <UnderstoodButton
                    variant="contained"
                    size="large"
                    disableElevation
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Submit
                  </UnderstoodButton>
                </Stack>
              </FeedbackContainer>
            </Fragment>
          )}
        </Formik>
      ) : modal === "FeedbackAcknowlagement" ? (
        <Fragment>
          <ModalLoader open={createPostLoading} />
          <DialogTitle align="center">Thank You for your Feedback!</DialogTitle>
          <CloseButton onClick={handleCrossButtonClicked} />
          {data?.getAchievementDetails?.share?.imageLink ? (
            <AchievementImage
              src={data?.getAchievementDetails?.share?.imageLink}
            />
          ) : null}
          <FeedbackContainer sx={{ paddingTop: 0 }}>
            <Stack direction={"row"}>
              <Button
                variant="outlined"
                fullWidth
                onClick={onShare}
                size="small"
              >
                Post to your wall
              </Button>
            </Stack>
          </FeedbackContainer>
        </Fragment>
      ) : null}
    </>
  );
};

export default StrickComplete;

const AchievementImage = styled(RedirectLazyLoadImage)({
  width: "100%",
});

interface FeedbackModalForm {
  questionResponse: Array<{
    questionId: string;
    questionText: string;
    badgeId: string;
    responseText: string;
  }>;
}

const BackButton = styled(BackIconButton)({
  position: "absolute",
  top: 4,
  left: 4,
  zIndex: 99,
});

const CloseButton = styled(CloseIconButton)({
  position: "absolute",
  top: 4,
  right: 4,
  zIndex: 99,
});

const UnderstoodButton = styled(Button)({
  borderRadius: 20,
  alignSelf: "stretch",
});

const Container = styled("div")(({ theme }) => ({
  maxWidth: "600px",
  width: "100%",
  height: "100%",
  marginTop: 30,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 20,
  zIndex: 99,
  paddingTop: 50,
  position: "relative",
  overflowX: "hidden",

  "&::after": {
    content: '""',
    backgroundImage: isDarkMode(theme)
      ? "linear-gradient(rgba(255, 255, 255, 0.125),#1c1f26)"
      : "linear-gradient(#e4f1ff,#f6fffa)",
    position: "absolute",
    height: "68%",
    left: "-40%",
    right: "-40%",
    bottom: 0,
    borderTopLeftRadius: "45%",
    borderTopRightRadius: "45%",
    zIndex: -1,
  },
  "@media(min-width: 540px)": {
    padding: 20,
  },
}));

const FeedbackContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  gap: 8,
  padding: 20,
}));
const Title = styled(Typography)({
  fontSize: 24,
  fontWeight: "700",
  lineHeight: "36px",
  marginTop: 20,
  textAlign: "center",
  "@media(min-width: 540px)": {
    marginBottom: 20,
  },
});

const PowerUser = styled("span")({
  color: "#2576d8",
});

const Subtitle = styled(Typography)({
  fontSize: "1rem",
  fontWeight: "700",
  lineHeight: "1.5rem",
  marginBottom: 10,
  textAlign: "center",
  span: {
    color: "#1b6ac9",
  },
  whiteSpace: "pre-line",
});

const Image = styled(
  (
    props: React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >
  ) => <img height={80} width={80} {...props} />
)(({ theme }) => ({
  height: "auto",
  width: 150,
  marginTop: "5%",
  "@media (min-width: 540px)": {
    marginTop: "10%",
    height: 200,
    width: 180,
  },
}));

import { Form, Formik } from "formik";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  IconButtonProps,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BackIcon, ImageSmallIcon } from "icons";
import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import MentionsInput from "components/MentionsInput";
import EmojiPicker from "components/EmojiPicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AskInClub from "./AskInClub";
import { useGetListOfClubsWithQueryPermissionQuery } from "api";

export interface AskQueryData {
  text: string;
  clubId?: string;
  images?: Array<File>;
}

const AskQuery = () => {
  const mentionInputRef = useRef<HTMLTextAreaElement>(null);
  const [modal, setModal] = useState<"AskQuery" | "AskInClub">("AskInClub");
  const [cursorPosition, setCursorPosition] = useState<any>();
  const { data } = useGetListOfClubsWithQueryPermissionQuery();

  return (
    <Formik<AskQueryData> initialValues={{ text: "" }} onSubmit={console.log}>
      {({ values, setFieldValue }) => {
        const addEmoji = (emoji: any) => {
          const { text } = values;
          if (mentionInputRef.current && "native" in emoji) {
            const { selectionStart, selectionEnd, value } =
              mentionInputRef.current;
            if (selectionStart === undefined || selectionEnd === undefined)
              return;
            const position = getCursorPosition(text, value, selectionStart);
            const result =
              text.substring(0, position) +
              emoji.native +
              text.substring(position, text.length);
            console.log({ result });
            setFieldValue("text", result);
            setCursorPosition(selectionStart + emoji.native.length);
          }
        };

        useEffect(() => {
          function onpress(
            this: HTMLTextAreaElement,
            ev: HTMLElementEventMap["keypress"]
          ) {
            const { text } = values;
            const helps: Record<string, string> = {
              Enter: "\n",
              NumpadEnter: "\n",
              Space: " ",
            };
            if (
              ["Enter", "NumpadEnter", "Space"].includes(ev.code) &&
              mentionInputRef.current
            ) {
              const { selectionStart } = mentionInputRef.current;
              const newText = text.replace(
                /(^|\s)(#)([a-z\d-]+)/gi,
                "$1[$2$3:$3]" + helps[ev.code]
              );
              mentionInputRef.current.value = newText;
              setCursorPosition(selectionStart);
              setFieldValue("text", newText);
            }
          }
          mentionInputRef?.current?.addEventListener<"keypress">(
            "keypress",
            onpress
          );
          return () => {
            mentionInputRef?.current?.removeEventListener<"keypress">(
              "keypress",
              onpress
            );
          };
        }, [mentionInputRef.current, values.text]);

        useEffect(() => {
          if (mentionInputRef.current && cursorPosition) {
            mentionInputRef.current.selectionStart = cursorPosition;
            mentionInputRef.current.selectionEnd = cursorPosition;
            mentionInputRef.current.focus();
          }
        }, [mentionInputRef.current, cursorPosition]);

        const clubName = useMemo(() => {
          return data?.getListOfClubsWithQueryPermission?.find(
            (x) => x?.clubId === values.clubId
          )?.clubName;
        }, [data?.getListOfClubsWithQueryPermission, values.clubId]);
        return (
          <>
            {modal === "AskInClub" && <AskInClub setModal={setModal} />}
            {modal === "AskQuery" && (
              <Fragment>
                <ModalHeader />
                <DialogContent dividers>
                  <Typography sx={{ marginBottom: 2 }}>
                    Ask in{" "}
                    <Button
                      size="small"
                      variant="outlined"
                      endIcon={<ArrowDropDownIcon />}
                      onClick={() => setModal("AskInClub")}
                    >
                      {clubName ? clubName : "Select a club"}
                    </Button>
                  </Typography>
                  <MentionsInput
                    inputRef={mentionInputRef}
                    value={values.text}
                    onChange={(_, text) => setFieldValue("text", text)}
                    placeholder="Enter your query using $ to tag stocks, sectors, or industries, and # to tag specific topics."
                    suggestionsPortalHost={document.body}
                  />
                </DialogContent>
                <DialogActions
                  sx={{
                    "&&": {
                      justifyContent: "space-between",
                    },
                  }}
                >
                  <Stack direction="row">
                    <EmojiPicker onEmojiSelect={addEmoji} />
                    <IconButton>
                      <ImageSmallIcon />
                    </IconButton>
                  </Stack>
                  <Button variant="contained" type="submit" disableElevation>
                    Submit your query
                  </Button>
                </DialogActions>
              </Fragment>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default AskQuery;

const getCursorPosition = (
  mentionText: string,
  plainText: string,
  position: number
): number => {
  const slicePlainText = plainText.slice(0, position);
  let i: number,
    j: number,
    flag = 0,
    colon = 0;
  for (i = 0, j = 0; i < mentionText.length; i++) {
    if (slicePlainText.length === 0) {
      break;
    }
    if (
      mentionText[i] === "[" &&
      ["@", "#", "$"].includes(mentionText[i + 1])
    ) {
      console.log("[ Start");
      flag = 1;
      continue;
    }
    if (mentionText[i] === ":" && flag) {
      console.log(": Colon");
      colon = 1;
      continue;
    }
    if (colon && mentionText[i] != "]") {
      continue;
    }
    if (colon && mentionText[i] === "]") {
      colon = 0;
      flag = 0;
      console.log("] End");
      continue;
    }
    if (mentionText[i] === slicePlainText[j]) {
      j++;
    }
    if (j >= slicePlainText.length) {
      i++;
      break;
    }
  }
  return i;
};

const ModalHeader: FC<IconButtonProps> = (props) => {
  return (
    <ModalTitle>
      <IconButton className="back" {...props}>
        <BackIcon />
      </IconButton>
      Ask Query
      <IconButton className="close" {...props}>
        <CloseIcon />
      </IconButton>
    </ModalTitle>
  );
};

const ModalTitle = styled(DialogTitle)({
  "& .close": {
    visibility: "hidden",
  },
  "@media (min-width: 540px)": {
    "& .back": {
      visibility: "hidden",
    },
    "& .close": {
      visibility: "visible",
    },
  },
});

const CustomForm = styled(Form)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

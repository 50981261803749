import React, { FC } from "react";
import styled from "@emotion/styled";
import Modal from "components/Modal";
import { DialogProps } from "@mui/material";
import RequestACallbackForm from "./RequestACallbackForm";

interface RequestACallbackModalProps extends DialogProps {
    handleCloseModal?: () => void;
}

const RequestACallbackModal: FC<RequestACallbackModalProps> = ({ handleCloseModal, ...rest }) => {
    return (
        <CustomModal {...rest}>
            <RequestACallbackForm handleCloseModal={handleCloseModal} />
        </CustomModal>
    )
}

export default RequestACallbackModal;

const CustomModal = styled(Modal)`
    .MuiPaper-root {
        height: auto !important;
    }
    .MuiDialogActions-root {
        justify-content: center;
    }
    .MuiButton-root {
        max-width: 200px;
        width: 100%;
        text-transform: uppercase;
    }
    .MuiFormControl-root {
        margin-top: 10px;
    }
    .MuiTypography-body1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.25;
    }
    .MuiTypography-caption {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        margin-top: 10px;
        display: block;
        white-space: pre-line;
        text-align: center;
    }
    .MuiFormHelperText-root {
        margin-left: 0;
        margin-top: 2px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
    }
    .MuiInputBase-root {
        font-family: 'Roboto';
        font-style: normal;
    }
`;

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { FC, ReactNode, useCallback } from "react";
import styled from "@emotion/styled";
import Avatar from "components/Avatar";
import {
  Maybe,
  Club,
  useMeQuery,
  useGetFeatureToPlatformPermissionQuery,
  PlatformPermissionFromFeatureTypes,
  ClubPermissionsBasedOnMemberType,
} from "api";
import { DEFAULT_AVATAR } from "config";
import { getIsRingVisible } from "utills";
import { sendEvent } from "ga4";
import { ActionTypes, useCreateAndSharePostContext } from ".";
import { useLocation } from "react-router-dom";
import { useClubPermission } from "hooks";

import imgAskQuery from "assets/image/ask-query.png";
import imgStockIdea from "assets/image/stock-idea.png";
import imgNewPost from "assets/image/new-post.png";
import { Close } from "@mui/icons-material";

const extractClubId = (path) => {
  const match = path.match(/\/club\/([a-zA-Z0-9-]+)(?:\/.*|$)/);
  return match ? match[1] : null;
};

export const CreatePostButton: FC<{
  club?: Maybe<Club> | undefined;
  loadingFeed?: boolean;
}> = ({ club }) => {
  const [open, setOpen] = React.useState(false);
  const [, dispatch] = useCreateAndSharePostContext();
  const { data: me } = useMeQuery();
  const { pathname } = useLocation();
  const clubId = extractClubId(pathname);
  const { permissions } = useClubPermission(clubId);
  const { data } = useGetFeatureToPlatformPermissionQuery();

  const handleOnClick = () => {
    setOpen(true);
  };

  const handleOpenQueryModal = useCallback(() => {
    setOpen(false);
    dispatch?.({
      type: ActionTypes.OPEN_QUERY_MODAL,
      clubId,
    });
  }, [clubId, dispatch, setOpen]);

  const handleOpenPostModal = useCallback(() => {
    setOpen(false);
    dispatch?.({
      type: ActionTypes.OPEN_POST_MODAL,
      clubId,
    });
  }, [clubId, dispatch, setOpen]);

  const handleOpenStockIdeaModal = useCallback(() => {
    setOpen(false);
    dispatch?.({
      type: ActionTypes.OPEN_STOCK_IDEA_MODAL,
      clubId,
    });
  }, [clubId, dispatch, setOpen]);

  const menus: Array<ReactNode> = [];
  if (clubId) {
    // Check for club post
    if (permissions?.includes(ClubPermissionsBasedOnMemberType.CanPost)) {
      menus.push(
        <ListItem alignItems="flex-start" onClick={handleOpenPostModal} sx={{
          "&&":{
            cursor: "pointer"
          }
        }}>
          <ListItemAvatar>
            <Avatar
              alt="New Post"
              src={imgNewPost}
              sx={{ height: "24px", width: "24px" }}
            />
          </ListItemAvatar>
          <ListItemText primary="Create a Post" />
        </ListItem>
      );
    }
    if (permissions?.includes(ClubPermissionsBasedOnMemberType.CanQuery)) {
      menus.push(
        <ListItem alignItems="flex-start" onClick={handleOpenQueryModal} sx={{
          "&&":{
            cursor: "pointer"
          }
        }}>
          <ListItemAvatar>
            <Avatar
              alt="New Post"
              src={imgAskQuery}
              sx={{ height: "24px", width: "24px" }}
            />
          </ListItemAvatar>
          <ListItemText primary="Ask Query" />
        </ListItem>
      );
    }
    if (
      permissions?.includes(ClubPermissionsBasedOnMemberType.CanCreateStockIdea)
    ) {
      menus.push(
        <ListItem alignItems="flex-start" onClick={handleOpenStockIdeaModal} sx={{
          "&&":{
            cursor: "pointer"
          }
        }}>
          <ListItemAvatar>
            <Avatar
              alt="New Post"
              src={imgStockIdea}
              sx={{ height: "24px", width: "24px" }}
            />
          </ListItemAvatar>
          <ListItemText primary="Share stock idea" />
        </ListItem>
      );
    }
  } else {
    if (
      data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanPost
      )
    ) {
      menus.push(
        <ListItem alignItems="flex-start" onClick={handleOpenPostModal} sx={{
          "&&":{
            cursor: "pointer"
          }
        }}>
          <ListItemAvatar>
            <Avatar
              alt="New Post"
              src={imgNewPost}
              sx={{ height: "24px", width: "24px" }}
            />
          </ListItemAvatar>
          <ListItemText primary="Create a Post" />
        </ListItem>
      );
    }
    if (
      data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanQuery
      )
    ) {
      menus.push(
        <ListItem alignItems="flex-start" onClick={handleOpenQueryModal} sx={{
          "&&":{
            cursor: "pointer"
          }
        }}>
          <ListItemAvatar>
            <Avatar
              alt="New Post"
              src={imgAskQuery}
              sx={{ height: "24px", width: "24px" }}
            />
          </ListItemAvatar>
          <ListItemText primary="Ask Query" />
        </ListItem>
      );
    }

    if (
      data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanCreateStockIdea
      )
    ) {
      menus.push(
        <ListItem alignItems="flex-start" onClick={handleOpenStockIdeaModal} sx={{
          "&&":{
            cursor: "pointer"
          }
        }}>
          <ListItemAvatar>
            <Avatar
              alt="New Post"
              src={imgStockIdea}
              sx={{ height: "24px", width: "24px" }}
            />
          </ListItemAvatar>
          <ListItemText primary="Share stock idea" />
        </ListItem>
      );
    }
  }

  return (
    <React.Fragment>
      <CustomPaper variant="outlined">
        <Avatar
          src={me?.me?.avatar?.LowResUri || DEFAULT_AVATAR}
          alt="Avatar"
          hasRing={getIsRingVisible(me?.me?.membershipLevel)}
        />
        <Button fullWidth onClick={handleOnClick}>
          Start discussion
        </Button>
      </CustomPaper>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <IconButton onClick={() => setOpen(false)} sx={{
            "&&":{
              position: "absolute",
              top: "4px",
              right: "4px"
            }
          }}>
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List
            sx={{ width: 240, bgcolor: "background.paper" }}
          >
            {menus}
          </List>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const CustomPaper = styled(Paper)`
  padding: 20px 16px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  .MuiAvatar-root {
    margin-right: 8px;
  }
  .MuiButton-root {
    max-width: 100%;
    border-radius: 24px;
    justify-content: flex-start;
    padding-left: 16px;
    background-color: ${({ theme }: any) => theme.palette.action.selected};
    &:hover {
      background-color: ${({ theme }: any) => theme.palette.action.selected};
    }
  }
`;


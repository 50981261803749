import React, { FC } from "react";
import { DialogTitle, IconButton, Typography, IconButtonProps } from "@mui/material";
import { Close } from "@mui/icons-material";

interface ModalHeaderProps extends IconButtonProps {
    title?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const ModalHeader: FC<ModalHeaderProps> = ({ title, ...others }) => (
    <DialogTitle>
        <Typography>{title}</Typography>
        <IconButton {...others}>
            <Close />
        </IconButton>
    </DialogTitle>
)

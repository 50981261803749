import React, { FC } from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Maybe, User } from "api";
import DateTime from "components/DateTime";

interface PostHeaderSubTitleProps {
    user: Maybe<User> | undefined;
    isEdited: boolean | null | undefined;
    createdAt: any;
    handleOpenEditPostHistoryModal?: () => void;
}

const PostHeaderSubTitle: FC<PostHeaderSubTitleProps> = ({ user, createdAt, isEdited, handleOpenEditPostHistoryModal }) => (
    <CustomTypography variant="caption">
        <Link to={`/profile/${user?.username}`}>@{`${user?.username}`}</Link>
        <Dot>&#8226;</Dot>
        {isEdited && <> <a onClick={handleOpenEditPostHistoryModal}>Edited</a>
            <Dot>&#8226;</Dot></>}
        <DateTime date={createdAt} />
    </CustomTypography>
)

export default PostHeaderSubTitle;

const Dot = styled.div`
    margin: 0 4px;
`;

const CustomTypography = styled(Typography)`
    display: flex;
    align-items: center;
    color: rgb(92, 112, 130);
    a {
        text-decoration: none;
        color: inherit;
    }
    a:hover {
        text-decoration: underline;
        color: inherit;
        cursor: pointer;
    }
    font-size: 0.725rem;
    line-height: 0.875rem;
`;
import React, { FC } from "react";
import {
  Exact,
  GetPostDetailsByIdQuery,
  Maybe,
  PlatformPermissionFromFeatureTypes,
  User,
  useFollowUserV2Mutation,
  useGetFeatureToPlatformPermissionQuery,
  useMeQuery,
} from "api";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import { Button, ButtonProps } from "@mui/material";
import { usePermissionFeatureMapping } from "hooks";
import {
  UpgradeModalInitialised,
  UserFollowButtonClickedComponentName,
  sendEvent,
} from "ga4";
import { ApolloQueryResult } from "@apollo/client";

interface UserFollowButtonProps extends Exclude<ButtonProps, "onClick"> {
  user: Maybe<User> | undefined;
  hideUnfollowButton?: boolean;
  component_name: UserFollowButtonClickedComponentName;
  upgrade_component_name: UpgradeModalInitialised;
  refetchPost?: (
    variables?:
      | Partial<
          Exact<{
            postId: string;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetPostDetailsByIdQuery>>;
}

export const UserFollowButton: FC<UserFollowButtonProps> = ({
  user,
  hideUnfollowButton,
  component_name,
  upgrade_component_name,
  refetchPost,
  ...rest
}) => {
  const { data: me } = useMeQuery();
  if (!user) return null;
  if (user?.isUserDeleted)
    return (
      <Button hidden {...rest} disabled sx={{ visibility: "hidden" }}>
        Removed
      </Button>
    );
  const { platformFeatureMap } = usePermissionFeatureMapping();
  const [follow] = useFollowUserV2Mutation();
  const { handleModalOpen } = useUpgradePlanContext();
  const { data } = useGetFeatureToPlatformPermissionQuery();

  const handleFollow = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (
        !data?.getFeatureToPlatformPermission?.includes(
          PlatformPermissionFromFeatureTypes.CanFollow
        )
      ) {
        console.log(
          "features",
          ":",
          platformFeatureMap[PlatformPermissionFromFeatureTypes.CanFollow]
        );
        sendEvent({
          event: "upgrade_modal_initialised",
          component_name: upgrade_component_name as UpgradeModalInitialised,
          feature_name_list:
            platformFeatureMap[PlatformPermissionFromFeatureTypes.CanFollow],
        });
        handleModalOpen();
        return;
      }
      if (user?.isfollow === "False") {
        sendEvent({ event: "user_follow_button_clicked", component_name });
        follow({
          variables: { userId: `${user?.id}` },
          update: (cache, { data }) => {
            refetchPost?.();
            if (data?.followUserV2?.isfollow === "Resolved") {
              cache.modify({
                fields: {
                  getSuggestedPeople(existingUsers, { readField }) {
                    return existingUsers.filter(
                      (userRef: any) => user.id !== readField("id", userRef)
                    );
                  },
                  getFollowersCount(existing, { storeFieldName }) {
                    if (
                      storeFieldName ===
                      `getFollowersCount({"data":{"entity":"User","id":"${user?.id}"}})`
                    ) {
                      return existing + 1;
                    }
                    return existing;
                  },
                  getFollowingsCount(existing, { storeFieldName }) {
                    if (
                      storeFieldName ===
                      `getFollowingsCount({"data":{"entity":"User","id":"${me?.me?.id}"}})`
                    ) {
                      return existing + 1;
                    }
                    return existing;
                  },
                },
              });
            } else if (data?.followUserV2?.isfollow === "Pending") {
              cache.modify({
                fields: {
                  getSuggestedPeople(existingUsers, { readField }) {
                    return existingUsers.filter(
                      (userRef: any) => user.id !== readField("id", userRef)
                    );
                  },
                },
              });
            }
          },
        });
      }
    },
    [
      user?.username,
      user?.isfollow,
      data?.getFeatureToPlatformPermission,
      follow,
      refetchPost
    ]
  );

  if (user?.isfollow === "False")
    return (
      <Button
        variant="contained"
        disableElevation
        onClick={handleFollow}
        {...rest}
      >
        Follow
      </Button>
    );
  if (user?.isfollow === "Pending" && !hideUnfollowButton)
    return (
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        disabled
        {...rest}
      >
        Pending
      </Button>
    );
  if (user?.isfollow === "Resolved" && !hideUnfollowButton)
    return (
      <Button
        variant="text"
        color="secondary"
        disableElevation
        disabled
        {...rest}
      >
        Following
      </Button>
    );
  return null;
};

import React, { FC } from "react";
import { IconButton, Typography, Paper, styled, PaperProps, IconButtonProps, Button } from "@mui/material";
import { isIOS, isMobileOnly, isSafari } from "react-device-detect";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useSessionStorage } from "react-use";
import { reference } from "../UpgradeMessageBlurr";


const DownloadPrompt: FC<PaperProps> = (props) => {
  const [open, setOpen] = useSessionStorage<boolean>("use_app_popup", true);

  const handleClosePopup = () => {
    setOpen(false);
    if (reference?.current) {
      reference?.current?.classList?.remove("download-prompt-visible");
    }
  };

  // const link = useMemo(() => {
  //   let link: string | undefined;
  //   if (isAndroid) {
  //     link =
  //       "https://play.google.com/store/apps/details?id=com.stockedge.social&referrer=utm_source=sesocial&utm_medium=website&utm_content=download&utm_campaign=websiteinstall";
  //     // link = "intent://social.stockedge.com/#Intent;scheme=https;package=com.stockedge.social;end";
  //   }
  //   if (isIOS) {
  //     link = "https://apps.apple.com/us/app/stockedge-social/id1533958966";
  //   }
  //   return link;
  // }, [isAndroid, isIOS]);

  const launchAppOnAppleDevice = () => {
    handleClosePopup();
    window.location.href = "https://apps.apple.com/us/app/stockedge-social/id1533958966";
    // window.open('stockedge-social://');
  }

  const launchAppOnAndroidDevice = () => {
    handleClosePopup()
    window.open(`intent://social.stockedge.com/#Intent;scheme=https;package=com.stockedge.social;end`);
  }

  if (!isMobileOnly || !open || isSafari) return null;
  return (
    <FixedPopup {...props}>
      <Typography component={Button} onClick={isIOS ? launchAppOnAppleDevice : launchAppOnAndroidDevice} color="primary">
        Use the App
      </Typography>
      <AbsoluteIconButton onClick={handleClosePopup}>
        <CloseRoundedIcon fontSize="small" />
      </AbsoluteIconButton>
    </FixedPopup>
  );
};

export default DownloadPrompt;

const FixedPopup = styled((props: PaperProps) => (
  <Paper variant="elevation" elevation={0} {...props} />
))(({ theme }) => ({
  position: "fixed",
  zIndex: 1200,
  bottom: "44px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  borderRadius: 0,
  height: theme.spacing(4),
  ".MuiTypography-root": {
    width: "100%",
    textAlign: "center",
    fontSize: "13px",
    fontWeight: "bold",
    letterSpacing: "0.5px",
  },
  "@media (min-width: 768px)": {
    display: "none",
  },
}));

const AbsoluteIconButton = styled((props: IconButtonProps) => (
  <IconButton {...props} />
))({
  position: "absolute",
  right: 0,
});

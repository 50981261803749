import React, { FC, PropsWithChildren, useEffect } from "react";
import { TourProvider } from "@reactour/tour";
import { getTourSteps } from "utills/tour/mobile";
import { Button } from "@mui/material";
import { isMobile } from "react-device-detect";
import {
  TourSourceType,
  useAddGuidedTourStatusForUserMutation,
  useGetGuidedTourQuery,
} from "api";
import { useTheme } from "@mui/material/styles";
import ReactGA from "react-ga4";
import { TOUR_DONE, TOUR_SKIP } from "utills/events";

export const HomeTourProvider: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  const { data } = useGetGuidedTourQuery({
    variables: {
      tourComponent: "Home",
      tourSource: isMobile ? TourSourceType.App : TourSourceType.Web,
    },
  });

  const steps = getTourSteps(
    data?.getGuidedTour || [],
    "home",
    theme.palette.mode
  );

  const [addGuidedTourStatus] = useAddGuidedTourStatusForUserMutation();

  const skipFinishFunction = () => {
    const visitedTourKeys = steps.map((x) => x.key);

    addGuidedTourStatus({
      variables: {
        tourComponent: "Home",
        visitedTourKeys,
        tourSource: isMobile ? TourSourceType.App : TourSourceType.Web,
      },
      update: (cache, { data }) => {
        if (data?.addGuidedTourStatusForUser?.success) {
          cache.modify({
            fields: {
              getGuidedTour: () => [] as any,
            },
          });
        }
      },
    });
  };

  return (
    <>
      {data?.getGuidedTour && (
        <TourProvider
          steps={steps}
          showDots={false}
          onClickMask={() => null}
          badgeContent={({ totalSteps, currentStep }) =>
            currentStep + 1 + "/" + totalSteps
          }
          onClickClose={({ setIsOpen }) => {
            ReactGA.event(TOUR_SKIP, {});
            skipFinishFunction();
            setIsOpen(false);
          }}
          prevButton={({ currentStep, setCurrentStep }) => {
            return (
              <Button
                variant="contained"
                style={{
                  fontSize: isMobile ? 12 : 14,
                }}
                disabled={currentStep == 0}
                onClick={() => {
                  setCurrentStep((s) => s - 1);
                }}
              >
                Previous
              </Button>
            );
          }}
          nextButton={({
            currentStep,
            stepsLength,
            setIsOpen,
            setCurrentStep,
          }) => {
            const last = currentStep === stepsLength - 1;
            return (
              <Button
                variant="contained"
                style={{ fontSize: isMobile ? 12 : 14 }}
                onClick={() => {
                  if (last) {
                    ReactGA.event(TOUR_DONE, {});
                    skipFinishFunction();
                    setIsOpen(false);
                  } else {
                    setCurrentStep((s) => s + 1);
                  }
                }}
              >
                {last ? "Got it" : "Next"}
              </Button>
            );
          }}
          clipId="tourClipId"
          maskId="tourMaskId"
        >
          {children}
        </TourProvider>
      )}
    </>
  );
};

import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useNotificationCount } from "hooks";

const HelmetWithNotificationCount: FC<{ title: string }> = ({ title }) => {
    const count = useNotificationCount();

    if (count) title = `(${count}) ${title}`;
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    )
}

export default HelmetWithNotificationCount;

import React, { FC } from "react";
import { styled } from "@mui/material";

const GreenTick: FC<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
> = (props) => {
  return (
    <Image
      {...props}
      src={require("assets/image/green-check.webp")}
      alt="Green Tick"
    />
  );
};

export default GreenTick;

const Image = styled("img")({
  marginLeft: "2px",
  marginRight: "2px",
  position: "relative",
  bottom: "-2px",
});

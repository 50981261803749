import React, { FC, Fragment, PropsWithChildren, useCallback, useState } from "react";
import SubscriptionModalContext, { ModalType } from "./SubscriptionModalContext";
import { SubscriptionModal } from "./SubscriptionModal";



export const SubscriptionModalProvider: FC<PropsWithChildren> = (props) => {
    const [{ open, modal }, setState] = useState<{ open: boolean, modal: ModalType }>({ open: false, modal: undefined });

    const handleOpenModal = useCallback((modal?: ModalType) => setState({ open: true, modal }), [setState])
    const handleCloseModal = useCallback(() => setState({ open: false, modal: undefined }), [setState])

    return (
        <Fragment>
            <SubscriptionModalContext.Provider value={{
                open, modal,
                handleOpenModal,
                handleCloseModal
            }} {...props} />
            <SubscriptionModal open={open} modal={modal} handleCloseModal={handleCloseModal} />
        </Fragment>
    )
}
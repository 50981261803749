import { Maybe, User } from "api";
import { ConfirmOptions } from "material-ui-confirm";

export const convertArrToObj = (arr: Array<any>, key: string) => {
    return arr.reduce((ac, a) => ({ ...ac, [a[key]]: a }), {});
}
export const commaNumber = (number: number): string => {
    return number?.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
    }) || "";
}

export const calculateCursorPosition = (startPos: number, planText: string, mentionsText: string): number => {
    let k = 0, t = 0, flag = 0;
    for (let i = 0; i <= planText.length; i++) {
        if (mentionsText.charAt(k + t) != planText.charAt(k)) {
            while (mentionsText.charAt(k + t) != planText.charAt(k)) {
                if (mentionsText.charAt(k + t) == "[") {
                    flag = 1;
                } else if (mentionsText.charAt(k + t) == "]") {
                    flag = 0;
                }
                t++;
            }
        }
        if (k === startPos - 1) {
            if (flag === 0) {
                break;
            } else {
                while (flag) {
                    if (mentionsText.charAt(k + t) === "]") {
                        flag = 0;
                        break;
                    }
                    t++;
                }
                break;
            }
        }
        k++;
    }
    return k + t;
}

export const statusColorMap = {
    // new: "#212529",
    open: "#007bff",
    pending: "#17a2b8",
    hold: "#ffc107",
    solved: "#28a745",
    closed: "#dc3545"
}

export const AlertPaperconfigOption: ConfirmOptions = {
    confirmationText: "Continue",
    confirmationButtonProps: {
        variant: "contained",
        color: "primary",
        size: "small"
    },
    cancellationButtonProps: {
        style: {
            display: "none"
        }
    },
    dialogProps: {
        classes: {
            paper: "AlertPaper"
        }
    }
}

export const getUserType = (user: Maybe<User>) => {
    if (user?.isVerified) return "Blue Tick"
    if (user?.greenTick) return "Green Tick"
    if (user?.tick3) return "Yellow Tick"
    return "Non Verified";
}


/**
 * This is the post text truncate function
 * @param text This is the post text parameter
 * @param position This is the truncated position parameter
 * @param truncatedEnding This is the truncated ending string parameter
 * @returns returns the truncated post
 */
export const getTruncatedText = (text: string, position: number = 100, truncatedEnding: string = "..."): string => {
    if (text?.length <= position) return text;
    const pattern = /\[([#@$][^:]+[^\]]+)\]/gi;
    let match: RegExpExecArray | null;
    while ((match = pattern.exec(text)) !== null) {
        const start = match.index,
            end = match.index + match[0].length;

        if (position > start && position < end) {
            position = end;
            break;
        }
    }
    return `${text.slice(0, position)}${truncatedEnding}`;
};
import React, { FC, useState } from "react";
import {
    Maybe,
    Poll,
    Option,
    Post,
    useSelectPollOptionV2Mutation
} from "api";
import VotersModal from "components/VotersModal";
import { Button, Paper, CardContent, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import PollExpired from "components/PollExpired";
import SelectedPollOptionButton from "./SelectedPollOptionButton";
import { timeAgo } from "utills";



interface PollProps {
    post: Maybe<Post> | undefined;
}

export const PollComponent: FC<PollProps> = ({ post }) => {
    const poll = post?.getPoll;
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const votes = Number(poll?.votes) > 1 ? `${poll?.votes} votes` : `${poll?.votes} vote`;
    return (
        <>
            <CardContent>
                <Container variant="outlined">
                    <Typography component={Link} variant="body1" to={`/posts/${post?.postId}`}>{poll?.question}</Typography>
                    {(poll?.hasExpired || poll?.hasUserSelected) ?
                        poll?.options?.map(option => <SelectedPollOptionButton
                            key={option?.optionId}
                            option={option}
                            totalVotes={Number(poll?.votes)}
                        />) :
                        poll?.options?.map(option => <ActionOption
                            key={option?.optionId}
                            option={option}
                            poll={poll}
                        />)
                    }
                    <CountContainer>
                        <VotesButton onClick={handleOpen}>{votes}</VotesButton>•
                        {poll?.hasExpired ? <PollExpired /> : <span>{timeAgo(new Date(poll?.expiryDate))} left</span>}
                    </CountContainer>
                </Container>
            </CardContent>
            <VotersModal
                open={open}
                handleClose={handleClose}
                pollId={poll?.pollId}
            />
        </>
    )
}

const CountContainer = styled("div")`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: 600;

    span {
        &:first-child {
            margin-right: 5px;
        }
        &:last-child {
            margin-left: 5px;
        }
    }
`;

const VotesButton = styled(Button)`
    && {
        height: 24px;
        margin-right: 2px;
        .MuiButton-label {
            font-size: 12px;
            font-weight: bold;
            margin: 0;
        }
    }
`;


const ActionOption: FC<{
    option: Maybe<Option>;
    poll: Maybe<Poll> | undefined;
}> = ({ option, poll }) => {
    const [selectPollOption] = useSelectPollOptionV2Mutation();

    const handleSelectPoll = () => {
        selectPollOption({
            variables: {
                data: {
                    pollId: option?.pollId,
                    optionId: option?.optionId
                }
            },
        });
    }

    return <OptionButton onClick={handleSelectPoll}>{option?.optionText}</OptionButton>
}

const Container = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 8px;
    position: relative;
    margin-top: 10px;

    .MuiIconButton-root {
        position: absolute;
        padding: 8px;
        top: 4px;
        right: 4px;
        background-color: #2196f3;
        color: #fff;

        svg {
            width: 1.125rem;
            height: 1.125rem;
        }
    }
`;



const OptionBtn = styled("button")`
    font-size: 1rem;
    font-weight: 400;
    margin: 4px 0;
    padding: 8px;
    background-color: transparent;
    color: #333;
    border: 1px solid rgba(0,0,0,0.25);
    border-radius: 6px;
    cursor: pointer;
`;

const OptionButton = styled(OptionBtn)`
    border-color: #2196f3;
    color: #2196f3;
    &:hover, &:focus {
        background-color: #2196f3;
        color: #fff;
    } 
`;

// const SLink = styled(Link)<LinkProps>`
//     text-decoration: none;
//     color: #131722;
// `;
import React, { FC } from "react";
import { CircularProgress } from "@mui/material";
import styled from "@emotion/styled";

export const ModalLoader: FC<{ open: boolean }> = ({ open }) => {
    if (open)
        return (
            <Container>
                <CircularProgress size={24}/>
            </Container>
        )
    return null;
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:1;
    backdrop-filter: blur(0.25px);
    cursor: progress;
`;
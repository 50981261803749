import React, { FC, PropsWithChildren, useEffect } from "react";
import { ThemeProvider } from "@emotion/react";
import { responsiveFontSizes } from "@mui/material/styles";
import {
  PaletteMode,
  CssBaseline,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material";
import { useLocalStorage } from "react-use";
import darkTheme from "theme/dark.theme";
import lightTheme from "theme/light.theme";

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {
    return;
  },
});

const Theme: FC<PropsWithChildren> = ({ children }) => {
  const [value, setValue] = useLocalStorage<PaletteMode>("theme");

  useEffect(() => {
    if (!value) {
      console.log("Theme not set yet");
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        console.log("Dark Mode");
        setValue("dark");
      } else {
        console.log("Light Mode");
        setValue("light");
      }
    } else {
      console.log("Theme already set");
    }
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setValue(value === "light" ? "dark" : "light");
      },
    }),
    [value]
  );

  const theme = responsiveFontSizes(themes[value || "light"]);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <MUIThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          {children}
        </ThemeProvider>
      </MUIThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Theme;

import React, { FC, Fragment } from "react";
import ReactGA from "react-ga4";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
    Button,
    Stack,
    TextField
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, FormikHelpers } from "formik";
import CountryCodeDropdown from "./CountryCodeDropdown";
import * as Yup from "yup";
import { load } from "recaptcha-v3";
import { RECAPTHA_SITE_KEY, GRAPHQL_API_URL } from "config";
import axios from "axios";
import toast from "react-hot-toast";
import { ReCaptchaBranding } from "components/ReCaptchaBranding";
import { CALLBACK_REQUEST_COMPLETED } from "utills/events";

interface RequestACallbackFormProps {
    handleCloseModal?: () => void;
}

interface FormValues {
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneDialInCode: string;
    Phone: string;
    Message: string;
}

const RequestACallbackForm: FC<RequestACallbackFormProps> = ({ handleCloseModal }) => {
    const onSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        try {
            const recaptcha = await load(RECAPTHA_SITE_KEY, { autoHideBadge: true });
            const RecaptchaToken = await recaptcha.execute("submit");
            Object.assign(values, { Source: "stockedge-social" });
            await axios.post(
                `${GRAPHQL_API_URL}/validateCaptchaSocial`,
                {
                    ...values,
                    RecaptchaToken
                }
            );
            ReactGA.event(CALLBACK_REQUEST_COMPLETED);
            toast.success("Thank you for your valuable feedback");
            formikHelpers.resetForm();
        } catch (e) {
            console.error(e);
        } finally {
            handleCloseModal?.();
            formikHelpers.setSubmitting(false);
        }
    }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                FirstName: "",
                LastName: "",
                Email: "",
                PhoneDialInCode: "+91",
                Phone: "",
                Message: ""
            } as FormValues}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
        >
            {({ values, submitForm, handleChange, handleBlur, setFieldValue, errors, isSubmitting }) => (
                <Fragment>
                    <DialogTitle>
                        <Typography variant="h3">Request a Callback</Typography>
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Typography>Kindly fill in your details in the form below, our representative will contact you shortly.</Typography>
                        <Stack direction="row">
                            <TextField
                                name="FirstName"
                                label="First Name*"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                size="small"
                                sx={{ marginRight: 0.25 }}
                                error={Boolean(errors?.FirstName)}
                                helperText={errors?.FirstName}
                            />
                            <TextField
                                name="LastName"
                                label="Last Name*"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                size="small"
                                sx={{ marginLeft: 0.25 }}
                                error={Boolean(errors?.LastName)}
                                helperText={errors?.LastName}
                            />
                        </Stack>
                        <TextField
                            name="Email"
                            label="Email*"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            size="small"
                            error={Boolean(errors?.Email)}
                            helperText={errors?.Email}
                        />
                        <Stack direction="row">
                            <CountryCodeDropdown
                                variant="standard"
                                name="PhoneDialInCode"
                                label="Country"
                                value={values.PhoneDialInCode}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            <TextField
                                name="Phone"
                                label="Phone*"
                                onBlur={handleBlur}
                                value={values.Phone}
                                onChange={(e) => {
                                    e.preventDefault();
                                    const { value } = e.target;
                                    setFieldValue("Phone", value.replace(/\D/g, ""));
                                }}
                                fullWidth
                                size="small"
                                sx={{ marginLeft: 0.25 }}
                                error={Boolean(errors?.Phone)}
                                helperText={errors?.Phone}
                            />
                        </Stack>
                        <TextField
                            name="Message"
                            label="Message"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            size="small"
                            multiline
                            minRows={4}
                            maxRows={8}
                            error={Boolean(errors?.Message)}
                            helperText={errors?.Message}
                        />
                        <ReCaptchaBranding />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={submitForm} disabled={isSubmitting}>Submit</Button>
                    </DialogActions>
                </Fragment>
            )}
        </Formik>
    )
}

export default RequestACallbackForm;

const ValidationSchema = Yup.object().shape({
    FirstName: Yup.string()
        .required("Please enter the required field")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .test("len", "Must be less than 128 characters", (val) =>
            val ? val?.length <= 128 : true
        ),
    LastName: Yup.string()
        .required("Please enter the required field")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    Email: Yup.string()
        .email("Invalid email")
        .required("Please enter the required field"),
    Phone: Yup.string()
        .required("Please enter the required field")
        .test("len", "Must be exactly 10 numbers", (val) => val?.length === 10),
    Message: Yup.string()
        .matches(
            /^[aA-zZ0-9.,()@#%&!\s]+$/,
            "Only A-Z a-z 0-9 .,()@#%&! are allowed"
        )
        .test(
            "len",
            "The field Message must be a string with a maximum length of 256.",
            (val) => (val ? val?.length <= 256 : true)
        ),
});
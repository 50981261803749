import { Typography } from "@mui/material";
import { Club, Maybe, useMeQuery } from "api";
import React, { FC } from "react";
import styled from "@emotion/styled";
import Avatar from "components/Avatar";
import { getIsRingVisible } from "utills";
import { DEFAULT_AVATAR } from "config";

export const UserDisplay: FC<{ club?: Maybe<Club> | undefined }> = ({ club }) => {
  const { data } = useMeQuery();
  return (
    <UserDisplayContainer>
      <Avatar src={data?.me?.avatar?.showUri || DEFAULT_AVATAR} hasRing={getIsRingVisible(data?.me?.membershipLevel)}/>
      <div>
        <Typography variant="body1">
          {`${data?.me?.firstName} ${data?.me?.lastName} `}
          {Boolean(club) && <RightArrow>&#9658;</RightArrow>}
          {` ${club?.clubName || ''}`}
        </Typography>
        <Typography variant="subtitle2">@{data?.me?.username}</Typography>
      </div>
    </UserDisplayContainer>
  );
};


const RightArrow = styled.span`
  margin: 0 2px;
  font-size: 15px;
`;

const UserDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 8px;
  .MuiAvatar-root {
    height: 45px;
    width: 45px;
    margin-right: 10px;
  }
  .MuiTypography-body1 {
    line-height: 1.2;
    margin-bottom: 5px;
  }
`;
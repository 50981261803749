import { IconButton, IconButtonProps, Paper, Tooltip } from "@mui/material";
import React, { FC, memo, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import ReactPlayer from "react-player/lazy";
import { Close } from "@mui/icons-material";
import { style } from "d3-selection";

interface VideoProps extends IconButtonProps {
  video: File | undefined;
  videoThumbnail: File;
}

export const Video: FC<VideoProps> = memo(({ video, children, ...others }) => {
  console.log("VIDEO TEST", others?.videoThumbnail, "jshjhdfs.jpg");
  const [pause, setPause] = useState(true);

  const handlePause = () => {
    setPause(true);
  };
  const handlePlay = () => {
    setPause(false);
  };

  const containerRef = useRef<ReactPlayer>(null);
  const [containerHeight, setContainerHeight] = useState<any>(0);

  console?.log("Container", containerHeight);

  useEffect(() => {
    if (containerRef.current && video) {
      setContainerHeight(containerRef.current.props.height as number);
      // setContainerHeight(
      //   containerRef.current.getInternalPlayer()?.clientHeight as number
      // );
    }
  }, [containerRef, setContainerHeight, containerHeight, video]);

  return (
    <Container variant="outlined" containerHeight={containerHeight}>
      <Tooltip title={"Clear video"}>
        <IconButton {...others}>
          <Close />
        </IconButton>
      </Tooltip>
      {Boolean(video) && (
        <>
          <ReactPlayer
            ref={containerRef}
            className="react-player"
            config={{
              file: {
                attributes: {
                  poster:
                    others?.videoThumbnail !== undefined &&
                    pause &&
                    URL.createObjectURL(others?.videoThumbnail),
                  style: {
                    poster: {
                      objectFit: "cover",
                    },
                  },
                },
              },
            }}
            width="100%"
            url={URL.createObjectURL(video!)}
            controls
            onPause={(!pause as any) && handlePause}
            onPlay={handlePlay}
            playing={!pause}
          />
        </>
      )}
      {pause && children}
    </Container>
  );
});
const Container = styled(Paper)<{ containerHeight: number }>(
  ({ containerHeight }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    marginTop: "15px",

    "video[poster]": {
      // maxHeight: `${containerHeight} !important`,
      maxHeight: `272px !important`,
    },

    "& > div": {
      height: "272px !important",
    },

    ".MuiIconButton-root": {
      position: "absolute",
      right: "8px",
      top: "8px",
      padding: "8px",
      zIndex: 1,
      backgroundColor: "#2196f3",
      color: "#ffffff",

      "&:hover, &:active, &:focus": {
        backgroundColor: "#2196f3",
      },
    },
  })
);

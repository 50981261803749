import { createContext, useContext } from "react";
import { CreateAndSharePostData } from "./types";

const initialData: CreateAndSharePostData = [{modal: undefined}, undefined];

export const CreateAndSharePostContext =
  createContext<CreateAndSharePostData>(initialData);

export const useCreateAndSharePostContext = () => {
  const context = useContext(CreateAndSharePostContext);
  if (!context) {
    throw new Error("useCreateAndSharePostContext must be used within a CreateAndSharePostProvider");
  }
  return context;
};

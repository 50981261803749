import React, { FC, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { IconButton, styled } from "@mui/material";
import {
  ZoomInIcon,
  ZoomOutIcon,
  CloseIconCircle,
  LeftIcon80,
  RightIcon80,
} from "icons";
interface ImageZoomProps {
  images: Array<any>;
  index: number;
  handleClose: () => void;
}

const ImageZoom: FC<ImageZoomProps> = ({
  images,
  index: propsIndex,
  handleClose,
}) => {

  console.log("propsIndex", { propsIndex });
  const [index, setIndex] = useState<number>(propsIndex || 0);

  useEffect(() => { setIndex(propsIndex) }, [propsIndex])

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        //if esc key was not pressed in combination with ctrl or alt or shift
        const isNotCombinedKey = !(
          event.ctrlKey ||
          event.altKey ||
          event.shiftKey
        );
        if (isNotCombinedKey) {
          console.log("Escape key was pressed with out any group keys");
          if (handleClose) handleClose();
        }
      }
      if (event.key === "ArrowLeft") {
        setIndex((prev) => {
          if (prev > 0) return prev - 1;
          return prev;
        });
      }
      if (event.key === "ArrowRight") {
        setIndex((prev) => {
          if (prev < images.length - 1) return prev + 1;
          return prev;
        });
      }
    };
    document.addEventListener("keydown", onKeyDown);
    document.body.style.overflow = "hidden";
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.body.style.overflow = "auto";
    };
  }, [handleClose, setIndex]);

  const prevImage = (event: any) => {
    setIndex((prev) => {
      if (prev > 0) return prev - 1;
      return prev;
    });
  };

  const nextImage = (event: any) => {
    setIndex((prev) => {
      if (prev < images.length - 1) return prev + 1;
      return prev;
    });
  };

  return createPortal(
    <FixedContainer>
      {images.length > 1 && (
        <>
          <PrevImageButton onClick={prevImage} disabled={index === 0}>
            <LeftIcon80 />
          </PrevImageButton>
          <NextImageButton
            onClick={nextImage}
            disabled={images.length - 1 === index}
          >
            <RightIcon80 />
          </NextImageButton>
        </>
      )}
      <TransformWrapper initialScale={1} key={index} centerOnInit={true}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }: any) => (
          <>
            <Tools>
              <IconButton onClick={() => zoomIn()} color="primary">
                <ZoomInIcon />
              </IconButton>
              <IconButton onClick={() => zoomOut()} color="primary">
                <ZoomOutIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  resetTransform();
                  if (handleClose) handleClose();
                }}
              >
                <CloseIconCircle />
              </IconButton>
            </Tools>
            <TransformComponent>
              <Image
                src={`${images[index]?.uri?.HighResUri}`}
                alt="test"
              />
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </FixedContainer>,
    document.body
  );
};

export default ImageZoom;

const FixedContainer = styled("div")`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 9999;
  & > .react-transform-wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
  }
  /* .react-transform-wrapper {
    height: 100vh;
    width: 100vw;
  }
  & .react-transform-component {
    background-color: rgba(0, 0, 0, 0.85);
    height: 100% !important;
    width: 100% !important;
  } */
`;

const Tools = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  height: 64px;
  padding: 0 10px;
  z-index: 99;

  .MuiIconButton-root {
    background: #5a5959;
    padding: 8px;
    height: 42px;
    width: 42px;
    margin-left: 8px;
  }
  svg {
    stroke: #fff;
  }
`;

const Image = styled("img")`
  object-fit: contain;
  max-height: 400px;
  width: 100%;
  height: 100%;
`;

const Button = styled("button")`
  position: fixed;
  z-index: 99999999;
  top: 0;
  bottom: 0;
  width: 60px;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  outline: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 200px;
  margin: auto;

  &:disabled {
    background: rgba(255, 255, 255, 0.2);
    color: grey;
  }

  @media (max-width: 480px) {
    height: 72px;
    width: 40px;
  }
`;

const PrevImageButton = styled(Button)`
  left: 0;
`;

const NextImageButton = styled(Button)`
  right: 0;
`;

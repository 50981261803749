import { Fab, Tooltip } from "@mui/material";
import React, { FC, useCallback } from "react";
import styled from "@emotion/styled";
import { PlusCircle32Icon } from "icons";
import { useCreatePost } from "./CreatePostProvider";
import { Club, Maybe } from "api";
import { isEmpty } from "lodash-es";
import { COLORS } from "assets/colors";
import { sendEvent } from "ga4";

export const CreatePostFAB: FC<{ club?: Maybe<Club> | undefined }> = ({
  club,
}) => {
  const { handleOpenModal: handleOpenCreatePostModal } = useCreatePost();

  const isHome = location.pathname.includes("/home"),
    isClub = location.pathname.includes("/club") && isEmpty(club);

  const handleOnClick = useCallback(() => {
    handleOpenCreatePostModal("Post", club ?? null);
  }, [handleOpenCreatePostModal, club]);

  if (isClub) return null;
  let renderText = `Start a discussion `;
  if (club) {
    renderText += ` in ${club?.clubName}`;
  }

  const onClick = () => {
    sendEvent({
      event: "create_post_floater_clicked",
    });
  };
  return (
    <Tooltip title={renderText} placement="left" onClick={onClick}>
      <FabButton
        color="primary"
        onClick={handleOnClick}
        className={isHome ? "hide-fab" : ""}
        sx={{
          backgroundColor: COLORS.buttonActive,
          color: COLORS.buttonTextActive,
        }}
        data-tut="reactour__button_to_post"
      >
        <PlusCircle32Icon />
      </FabButton>
    </Tooltip>
  );
};

const FabButton = styled(Fab)`
  position: fixed;
  width: 48px;
  height: 48px;
  cursor: pointer;
  right: 16px;
  bottom: 16px;
  display: flex;
  &.MuiFab-primary {
    background-color: ${COLORS.buttonActive};
  }
  svg {
    stroke: ${COLORS.buttonActive};
    fill: #ffffff;
  }
  @media (max-width: 959px) {
    display: none;
  }
`;

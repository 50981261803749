import React, {
  FC,
  Fragment,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import NPSModal from "./NPSModal";
import { useMeQuery } from "api";
import { useGetExternalResources } from "hooks";

const NPS: FC<PropsWithChildren> = ({ children }) => {
  const { data } = useMeQuery();
  const { externalResources } = useGetExternalResources();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let instance: NodeJS.Timeout;
    if (data?.me?.showNpsV2 && externalResources?.NPS_TIME) {
      instance = setTimeout(() => {
        setOpen(Boolean(data?.me?.showNpsV2));
      }, Number(externalResources?.NPS_TIME));
    }
    return () => {
      clearTimeout(instance);
    };
  }, [data?.me?.showNpsV2, externalResources?.NPS_TIME, setOpen]);

  const onhandleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      {children}
      <NPSModal open={open} onhandleClose={onhandleClose} />
    </Fragment>
  );
};

export default NPS;

export type State = {
  text: string;
  images: File[];
  video: File | null;
  doc: File | null;
  loading: boolean;
  edit: boolean;
  index: number;
};

export const initialState: State = {
  text: "",
  images: [],
  video: null,
  doc: null,
  loading: false,
  edit: false,
  index: -1,
};

export enum ActionKind {
  Init = "INIT",
  Loading = "LOADING",
  TextChange = "TEXT_CHANGE",
  VideoChange = "VIDEO_CHANGE",
  DocumentChange = "DOCUMENT_CHANGE",
  AddImage = "ADD_IMAGE",
  EditImage = "EDIT_IMAGE",
  SaveImage = "SAVE_IMAGE",
  DeleteImage = "DELETE_IMAGE",
  Back = "BACK",
}

export type Action =
  | {
      type: ActionKind.Init;
    }
  | {
      type: ActionKind.Loading;
      loading: boolean;
    }
  | {
      type: ActionKind.TextChange;
      text: string;
    }
  | {
      type: ActionKind.VideoChange;
      video: File | null;
    }
  | {
      type: ActionKind.DocumentChange;
      doc: File | null;
    }
  | {
      type: ActionKind.AddImage;
      image: File;
    }
  | {
      type: ActionKind.EditImage;
      index: number;
    }
  | {
      type: ActionKind.SaveImage;
      index: number;
      image: File;
    }
  | {
      type: ActionKind.DeleteImage;
      index: number;
    }
  | {
      type: ActionKind.Back;
    };

export type Reducer<State, Action> = (state: State, action: Action) => State;

export const reducer: Reducer<State, Action> = (
  state: State,
  action: Action
): State => {
  switch (action.type) {
    case ActionKind.Init:
      return initialState;

    case ActionKind.Loading:
      return {
        ...state,
        loading: action.loading,
      };

    case ActionKind.TextChange:
      return {
        ...state,
        text: action.text,
      };

    case ActionKind.VideoChange:
      return {
        ...state,
        video: action.video,
      };

    case ActionKind.DocumentChange:
      return {
        ...state,
        doc: action.doc,
      };

    case ActionKind.AddImage:
      return {
        ...state,
        images: [action.image, ...state.images],
      };

    case ActionKind.EditImage:
      return {
        ...state,
        edit: true,
        index: action.index,
      };

    case ActionKind.Back:
      return {
        ...state,
        edit: false,
      };

    case ActionKind.SaveImage:
      return {
        ...state,
        images: [
          ...state.images.slice(0, action.index),
          action.image,
          ...state.images.slice(action.index + 1),
        ],
        edit: false,
        index: -1,
      };

    case ActionKind.DeleteImage:
      return {
        ...state,
        images: state.images.filter((_, i) => i !== action.index),
      };

    default:
      return state;
  }
};

import { Stack, Typography } from "@mui/material";
import {
  Club,
  Maybe,
  useGetFeatureToPlatformPermissionQuery,
  PlatformPermissionFromFeatureTypes,
  useMeQuery,
} from "api";
import { useAuthContext } from "context/AuthContext";
import React, { FC } from "react";
import styled from "@emotion/styled";
import { DEFAULT_AVATAR } from "config";
import { Button } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ModalType } from "./PostCreator";
import Avatar from "components/Avatar";
import { getIsRingVisible } from "utills";
import NameWithTick from "components/NameWithTick";

interface UserDisplayProps {
  club?: Maybe<Club> | undefined;
  audience: Club | undefined;
  setModal: React.Dispatch<React.SetStateAction<ModalType>>;
}

export const UserDisplay: FC<UserDisplayProps> = ({
  club,
  audience,
  setModal,
}) => {
  const { data } = useMeQuery();
  const { user } = useAuthContext();
  const { data: permission } = useGetFeatureToPlatformPermissionQuery();
  const handleOpenAudienceModal = () => setModal("CHOOSE_AUDIANCE");

  const canPostInFeed = permission?.getFeatureToPlatformPermission?.includes(
    PlatformPermissionFromFeatureTypes?.CanPost
  );

  console.log("User Display", { club, audience, canPostInFeed });

  return (
    <UserDisplayContainer>
      <Avatar
        src={data?.me?.avatar?.showUri || DEFAULT_AVATAR}
        hasRing={getIsRingVisible(data?.me?.membershipLevel)}
      />
      <div>
        <Stack flexDirection="row">
          <NameWithTick
            firstName={data?.me?.firstName}
            lastName={data?.me?.lastName}
            isVerified={data?.me?.isVerified}
            greenTick={data?.me?.greenTick}
            tick3={data?.me?.greenTick}
            sx={{ width: "auto" }}
          />
          <RightArrow>&#9658;</RightArrow>
          {club ? (
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              disabled
              disableElevation
              sx={{
                padding: "0 4px",
                marginLeft: "4px",
              }}
            >
              {club?.clubName}
            </Button>
          ) : (
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              disableElevation
              sx={{
                padding: "0 4px",
                marginLeft: "4px",
              }}
              endIcon={<ExpandMore />}
              onClick={handleOpenAudienceModal}
            >
              {audience
                ? audience?.clubName
                : canPostInFeed
                ? "Feed"
                : "Select Audience"}
            </Button>
          )}
        </Stack>
        <Typography variant="subtitle2">@{user?.username}</Typography>
      </div>
    </UserDisplayContainer>
  );
};

const RightArrow = styled.span`
  margin: 0 2px 2px 4px;
  font-size: 15px;
`;

const UserDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 8px;
  .MuiAvatar-root {
    height: 45px;
    width: 45px;
    margin-right: 10px;
  }
  .MuiTypography-body1 {
    line-height: 1.2;
    margin-bottom: 5px;
  }
`;

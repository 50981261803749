import React, { useEffect } from "react";
import {
  Exact,
  GetFeedByTypeQuery,
  InputMaybe,
  useCheckNewPostsByFeedTypeQuery,
} from "api";
import NewPrompt from "components/NewPrompt";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { useAuth } from "react-oidc-context";

interface NewPostPromptProps {
  refetch: (
    variables?:
      | Partial<
          Exact<{
            feedType?: InputMaybe<string> | undefined;
            offset?: InputMaybe<number> | undefined;
            limit?: InputMaybe<number> | undefined;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetFeedByTypeQuery>>;
  date: string | undefined;
  feedType: string;
}

const POLL_INTERVAL = 1000 * 60;

const NewPostPrompt: React.FC<NewPostPromptProps> = ({
  refetch,
  date,
  feedType,
}) => {
  const { error } = useAuth();
  const { cache } = useApolloClient();
  const { data, startPolling, stopPolling } = useCheckNewPostsByFeedTypeQuery({
    variables: { date, feedType },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    startPolling(POLL_INTERVAL);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (error?.message?.includes("invalid_grant")) {
      stopPolling();
    }
  }, [error?.message, stopPolling]);

  useEffect(() => {
    if (data?.checkNewPostsByFeedType) {
      stopPolling();
    }
  }, [data?.checkNewPostsByFeedType, stopPolling]);

  const handleNewPostClick = () => {
    cache.modify({
      fields: {
        checkNewPostsByFeedType: () => false,
      },
    });
    refetch();
    startPolling(POLL_INTERVAL);
  };

  if (data?.checkNewPostsByFeedType)
    return (
      <NewPrompt
        title={"New posts"}
        handleClick={handleNewPostClick}
        topFixValue={100}
      />
    );
  return null;
};

export default NewPostPrompt;

import React, {
  Fragment,
  ReactNode,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import BadgeModal from "./BadgeModal";
import { useGetPopUpQuery, useHasSeenPopUpMutation } from "api";
import RunningStrick from "components/BadgeSystem/RunningStrick";
import MissedStrick from "components/BadgeSystem/MissedStrick";
import StrickComplete from "components/BadgeSystem/StrickComplete";
import { sendEvent } from "ga4";
export interface IBadgeContext {
  handleOpenModal: (
    achievementType: string,
    sourceOfClick?: string,
    isMe?: boolean
  ) => void;
}

export const BadgeContext = createContext<IBadgeContext>({} as IBadgeContext);

export type BadgeHandle = {
  refetch: () => void;
};

type Props = {
  children: ReactNode;
};

export const BadgeProvider = forwardRef<BadgeHandle, Props>(
  ({ children }, ref) => {
    const [open, setOpen] = useState(false);
    const { data, refetch } = useGetPopUpQuery({
      fetchPolicy: "no-cache",
    });
    const [hasSeenPopUp] = useHasSeenPopUpMutation();
    const [{ achievementType, sourceOfClick, isMe }, setModalData] = useState<{
      achievementType?: string;
      sourceOfClick?: string;
      isMe?: boolean;
    }>({});

    const currentPopup = useMemo(() => {
      const currentPopup = data?.getPopUp?.data?.[0];
      if (currentPopup) {
        const { data, ...rest } = currentPopup;
        return { ...rest, data: JSON.parse(data as any) };
      }
      return undefined;
    }, [data?.getPopUp?.data]);

    useImperativeHandle(
      ref,
      () => {
        return {
          refetch,
        };
      },
      [refetch]
    );

    useEffect(() => {
      let timeout: NodeJS.Timeout;
      if (currentPopup?.popUpType && currentPopup?.popUpDelay) {
        timeout = setTimeout(() => {
          if (currentPopup?.popUpType) {
            sendEvent({
              event: "popup_Initialised",
              pop_up_type: currentPopup?.popUpType,
            });
          }
          setOpen(true);
        }, currentPopup?.popUpDelay);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, [currentPopup, setOpen]);

    const onhandleClose = async () => {
      try {
        if (achievementType) {
          sendEvent({
            event: "popup_cross_clicked",
            pop_up_type: achievementType,
          });
          setModalData({});
        } else {
          if (currentPopup?.popUpType) {
            sendEvent({
              event: "popup_cross_clicked",
              pop_up_type: currentPopup?.popUpType,
            });
            setModalData({});
          }
          await hasSeenPopUp({
            variables: { popUpType: currentPopup?.popUpType },
            update: (cache) => {
              cache.evict({
                id: cache.identify({
                  popUpType: currentPopup?.popUpType,
                  __typename: "popUp",
                }),
              });
            },
          });
        }
      } finally {
        setOpen(false);
      }
    };

    const handleOpenModal = (
      achievementType: string,
      sourceOfClick?: string,
      isMe?: boolean
    ) => {
      sendEvent({ event: "popup_Initialised", pop_up_type: achievementType });
      setModalData({ achievementType, sourceOfClick, isMe });
      setOpen(true);
    };

    const handleUnderstood = (cta_text: string) => async () => {
      try {
        if (achievementType) {
          sendEvent({
            event: "popup_cta_clicked",
            pop_up_type: achievementType,
            cta_text,
          });
          setModalData({});
        } else {
          if (currentPopup?.popUpType) {
            sendEvent({
              event: "popup_cta_clicked",
              pop_up_type: currentPopup?.popUpType,
              cta_text,
            });
            setModalData({});
          }
          await hasSeenPopUp({
            variables: { popUpType: currentPopup?.popUpType },
            update: (cache) => {
              cache.evict({
                id: cache.identify({
                  popUpType: currentPopup?.popUpType,
                  __typename: "popUp",
                }),
              });
            },
          });
        }
      } finally {
        setOpen(false);
      }
    };

    const handlePopupClose = () => {
      return hasSeenPopUp({
        variables: { popUpType: currentPopup?.popUpType },
        update: (cache) => {
          cache.evict({
            id: cache.identify({
              popUpType: currentPopup?.popUpType,
              __typename: "popUp",
            }),
          });
        },
      });
    };

    const handleModalClose = () => {
      setModalData({});
      setOpen(false);
    };

    return (
      <Fragment>
        <BadgeContext.Provider value={{ handleOpenModal }}>
          {children}
        </BadgeContext.Provider>
        <BadgeModal open={open} scroll="body">
          {achievementType ? (
            <Fragment>
              {"PowerUserStreak" === achievementType ? (
                <RunningStrick
                  handleUnderstood={handleUnderstood("understood")}
                  handleModalClose={onhandleClose}
                />
              ) : "PowerUser_WeeksStreakCompletion" === achievementType ? (
                <StrickComplete
                  achievementType={
                    achievementType || (currentPopup?.popUpType as string)
                  }
                  sourceOfClick={sourceOfClick}
                  handleModalClose={onhandleClose}
                  handleCrossButtonClicked={onhandleClose}
                />
              ) : null}
            </Fragment>
          ) : (
            <Fragment>
              {["PowerUser_WeekCompleted"].includes(
                currentPopup?.popUpType as string
              ) ? (
                <RunningStrick
                  handleUnderstood={handleUnderstood("understood")}
                  handleModalClose={onhandleClose}
                />
              ) : currentPopup?.popUpType === "PowerUser_WeekMissed" ? (
                <MissedStrick
                  handleUnderstood={handleUnderstood("understood")}
                  handleModalClose={onhandleClose}
                />
              ) : ["PowerUser_StreakCompleted"].includes(
                  currentPopup?.popUpType as string
                ) ? (
                <StrickComplete
                  achievementType={currentPopup?.data?.achievementType}
                  sourceOfClick={currentPopup?.data?.sourceOfClick}
                  handleCrossButtonClicked={onhandleClose}
                  handleModalClose={handleModalClose}
                  handlePopupClose={handlePopupClose}
                />
              ) : null}
            </Fragment>
          )}
        </BadgeModal>
      </Fragment>
    );
  }
);

export const useBadgeContext = () => {
  return useContext(BadgeContext);
};

export default BadgeContext;

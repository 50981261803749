import React, { createContext } from "react";

export interface ISharePostContext {
  open: boolean;
  post: any;
  handleOpenModal: (post: any) => void;
  handleCloseModal: () => void;
}

export const SharePostContext = createContext<ISharePostContext | undefined>(
  undefined
);

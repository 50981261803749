import React, { FC } from "react";
import {
  DollarTag,
  DollarTagEnum,
  Maybe,
  PlatformPermissionFromFeatureTypes,
  useDollarFollowMutation,
  useDollarUnfollowMutation,
  useGetFeatureToPlatformPermissionQuery,
} from "api";
import {
  updateCacheOnUnFollowDollarTag,
  updateCacheOnFollowDollarTag,
} from "config/apollo";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import { Button, ButtonProps } from "@mui/material";
import { usePermissionFeatureMapping } from "hooks";
import {
  SectorFollowButtonClickedComponentName,
  StockFollowButtonClickedComponentName,
  UpgradeModalInitialised,
  sendEvent,
} from "ga4";

interface DollartagFollowUnfollowButtonProps
  extends Exclude<ButtonProps, "onClick"> {
  dollar: Maybe<DollarTag> | undefined;
  showUnfollowButton?: boolean;
  hideFollowingButton?: boolean;
  component_name:
    | StockFollowButtonClickedComponentName
    | SectorFollowButtonClickedComponentName;
  upgrade_component_name: UpgradeModalInitialised;
}

export const DollartagFollowUnfollowButton: FC<
  DollartagFollowUnfollowButtonProps
> = ({
  dollar,
  showUnfollowButton,
  hideFollowingButton,
  component_name,
  upgrade_component_name,
  ...rest
}) => {
  if (!dollar) return null;

  const { platformFeatureMap } = usePermissionFeatureMapping();
  const [follow] = useDollarFollowMutation();
  const [unfollow] = useDollarUnfollowMutation();
  const { handleModalOpen } = useUpgradePlanContext();
  const { data } = useGetFeatureToPlatformPermissionQuery();

  const handleFollow: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (
      !data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanFollow
      )
    ) {
      sendEvent({
        event: "upgrade_modal_initialised",
        component_name: upgrade_component_name as UpgradeModalInitialised,
        feature_name_list:
          platformFeatureMap[PlatformPermissionFromFeatureTypes.CanFollow],
      });
      handleModalOpen();
      return;
    }

    if (dollar.dollarTagType === DollarTagEnum.Stock) {
      sendEvent({
        event: "stock_follow_button_clicked",
        component_name: component_name as StockFollowButtonClickedComponentName,
      });
    } else {
      sendEvent({
        event: "sector_follow_button_clicked",
        component_name:
          component_name as SectorFollowButtonClickedComponentName,
      });
    }

    follow({
      variables: { dollarTagId: `${dollar?.dollarTagId}` },
      update: (cache, { data }) => {
        updateCacheOnFollowDollarTag(cache, dollar, data);
      },
    });
  };

  const handleUnfollow: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (
      !data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanFollow
      )
    ) {
      sendEvent({
        event: "upgrade_modal_initialised",
        component_name: upgrade_component_name as UpgradeModalInitialised,
        feature_name_list:
          platformFeatureMap[PlatformPermissionFromFeatureTypes.CanFollow],
      });
      handleModalOpen();
      return;
    }

    if (dollar.dollarTagType === DollarTagEnum.Stock) {
      sendEvent({
        event: "stock_unfollow_button_clicked",
        component_name: "stock_details",
      });
    } else {
      sendEvent({
        event: "sector_unfollow_button_clicked",
        component_name: "sector_details",
      });
    }
    unfollow({
      variables: { dollarTagId: `${dollar?.dollarTagId}` },
      update: (cache, { data }) => {
        updateCacheOnUnFollowDollarTag(cache, dollar, data);
      },
    });
  };

  if (!dollar?.isfollow)
    return (
      <Button
        variant="contained"
        disableElevation
        onClick={handleFollow}
        {...rest}
      >
        Follow
      </Button>
    );

  if (dollar?.isfollow && showUnfollowButton)
    return (
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        onClick={handleUnfollow}
        {...rest}
      >
        Unfollow
      </Button>
    );
  if (dollar?.isfollow && !hideFollowingButton)
    return (
      <Button
        variant="contained"
        color="secondary"
        disabled
      >
        Following
      </Button>
    );
  return null;
};

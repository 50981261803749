import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { DialogContent, DialogActions, Divider } from "@mui/material";
import { useResizeDetector } from "react-resize-detector";
import MentionsInput from "components/MentionsInput";
import { calculateCursorPosition } from "helper";
import EmojiPicker from "components/EmojiPicker";


interface Props {
  setModal: React.Dispatch<React.SetStateAction<"CREATE_A_POLL" | "EDIT_IMAGE" | "TEXT_POST">>;
  header: React.ReactNode;
  postHeader: React.ReactNode;
  uploadImageButton: React.ReactNode;
  uploadVideoButton: React.ReactNode;
  uploadAudioButton: React.ReactNode;
  uploadPdfButton: React.ReactNode;
  renderImages: React.ReactNodeArray;
  createPostButton: React.ReactNode;
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
  renderPoll: React.ReactNode;
  renderSherePost: React.ReactNode;
  renderVideo: React.ReactNode;
  renderAudio: React.ReactNode;
  renderDoc: React.ReactNode;
}

export const PostInput: FC<Props> = ({
  text,
  setText,
  header,
  postHeader,
  uploadImageButton,
  uploadVideoButton,
  uploadAudioButton,
  uploadPdfButton,
  renderImages,
  createPostButton,
  renderPoll,
  renderSherePost,
  renderVideo,
  renderAudio,
  renderDoc
}) => {
  const mentionInputRef = useRef<HTMLTextAreaElement>(null);
  const [cursorPosition, setCursorPosition] = useState<any>();
  const { ref } = useResizeDetector();
  console.log({ renderImages });

  useEffect(() => {
    function onpress(this: Document, ev: KeyboardEvent) {
      const helps: Record<string, string> = {
        Enter: "\n",
        NumpadEnter: "\n",
        Space: " ",
      };
      if (["Enter", "NumpadEnter", "Space"].includes(ev.code)) {
        const newText = text.replace(
          /(^|\s)(#)([a-z\d-]+)/gi,
          "$1[$2$3:$3]" + helps[ev.code]
        );
        setText(newText);
      }
    }
    document.addEventListener("keypress", onpress);
    return () => {
      document.removeEventListener("keypress", onpress);
    };
  }, [mentionInputRef, text, setText]);

  const addEmoji = useCallback((emoji:any) => {
    if (mentionInputRef.current && "native" in emoji) {
      const { selectionStart: startPos, selectionEnd: endPos, value: txt } = mentionInputRef.current
      if (startPos === undefined || endPos === undefined) return;
      const cursorPosition = calculateCursorPosition(startPos, txt, text);
      const result = text.substring(0, cursorPosition + 1) + emoji.native + text.substring(cursorPosition + 1, text.length);
      mentionInputRef.current.value = result;
      // Redefine the cursor position
      setCursorPosition(startPos + emoji.native.length);
      setText(result);
    }
  }, [mentionInputRef, setText, text, setCursorPosition])

  useEffect(() => {
    if (mentionInputRef.current && cursorPosition) {
      mentionInputRef.current.selectionStart = cursorPosition;
      mentionInputRef.current.selectionEnd = cursorPosition;
      mentionInputRef.current.focus();
    }
  }, [mentionInputRef.current, cursorPosition]);

  return (
    <Fragment>
      {header}
      <Divider />
      <DialogContent
        onClick={() => {
          if (mentionInputRef.current) {
            mentionInputRef.current.focus();
          }
        }}
        ref={ref}
      >
        {postHeader}
        <MentionsInput
          inputRef={mentionInputRef}
          value={text}
          onChange={(_, text) => setText(text)}
          placeholder="Write your post here. Use @ to tag members, $ to tag stocks, sectors & industries , # to tag topics."
          suggestionsPortalHost={ref.current}
        />
        {renderImages}
        {renderVideo}
        {renderAudio}
        {renderDoc}
        {renderPoll}
        {renderSherePost}
      </DialogContent>
      <DialogActions>
        {uploadImageButton}
        {uploadVideoButton}
        {uploadAudioButton}
        {uploadPdfButton}
        <EmojiPicker onEmojiSelect={addEmoji} />
        {createPostButton}
      </DialogActions>
    </Fragment>
  );
};

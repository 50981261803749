import React, { FC } from "react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

export const LoadMoreLoader: FC = () => (
    <Container>
        <CircularProgress />
    </Container>
)

const Container = styled.div`
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
`
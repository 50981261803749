import React, { FC } from "react";
import { IconButton, styled } from "@mui/material";
import { Close, Edit } from "@mui/icons-material";

interface ImageProps {
    image: File;
    onClickDelete?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    onClickEdit?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const Image: FC<ImageProps> = ({ image, onClickEdit, onClickDelete }) => (
    <RelativeContainer>
        <Absolute>
            <IconButton onClick={onClickEdit}>
                <Edit />
            </IconButton>
            <IconButton onClick={onClickDelete}>
                <Close />
            </IconButton>
        </Absolute>
        <Img src={URL.createObjectURL(image)} alt={image.name} />
    </RelativeContainer>
)

const RelativeContainer = styled("div")`
    position: relative;
    width: 100%;
    height: 280px;
    margin-top: 10px;

    .MuiIconButton-root {
    right: 8px;
    top: 8px;
    padding: 8px;
    margin-left: 4px;
    background-color: #2196f3;
    &:hover, &:active , &:focus {
        background-color: #2196f3;
    }
    color: #ffffff;
    height: 32px;
    width: 32px;
  }
  .MuiButton-root {
    left: 8px;
    top: 8px;
    padding: 0 12px;
    background-color: #2196f3;
    &:hover, &:active , &:focus {
        background-color: #2196f3;
    }
    color: #ffffff;
    height: 30px;
    svg {
      height: 15px;
      width: 15px;
    }
  }
  svg {
    height: 20px;
    width: 20px;
  }
`;

const Img = styled("img")`
    width: 100%;
    height: 100%;
    object-fit: contain;
    box-shadow: 0 0 0.5px 0.5px rgb(0 0 0 / 25%);
    border-radius: 6px;
`;

const Absolute = styled("div")`
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
`;
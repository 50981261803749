import { Box, useMediaQuery } from "@mui/material";
import { detect } from "detect-browser";
import React, { FC, PropsWithChildren, useState } from "react";
import { withAuthenticationRequired } from "react-oidc-context";
import styled from "@emotion/styled";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import Header from "./components/Header";
import PushNotification from "./PushNotification";
import { CreatePostProvider } from "components/CreatePost";
import { SharePostProvider, SharePostModal } from "components/SharePost";
import { ProductTourProvider } from "providers/ProductTour";
import BottomNavigation from "layouts/MainLayout/components/ButtomNavigation";
import { Outlet } from "react-router-dom";
import NPS from "context/nps";
import { BadgeProvider, BadgeHandle } from "context/badge/BadgeContext";
import { SourceVisitorCode } from "components/SourceVisitorCode";
import { FloatingButtonMenu } from "features/create-and-share-post";
import CreateAndSharePostProvider from "features/create-and-share-post";

export const badgeRef = React.createRef<BadgeHandle>();

const isAllowPushNotifications = (): boolean => {
  const browser = detect();
  if (browser?.name === "ios") return false;
  if (browser?.name === "safari") return false;
  if (browser?.name === "crios") return false;
  if (browser?.name === "chromium-webview") return false;
  return true;
};

const MainLayout: FC<PropsWithChildren<any>> = ({ children }) => {
  const isVisible = useMediaQuery("(min-width:960px)");
  const [openPrompt, setOpenPrompt] = useState(false);

  return (
    <NPS>
      <SourceVisitorCode />
      <ProductTourProvider>
        <CreateAndSharePostProvider>
          <CreatePostProvider>
            <SharePostProvider>
              <BadgeProvider ref={badgeRef}>
                <Header
                  openPrompt={openPrompt as any}
                  setOpenPrompt={setOpenPrompt}
                />
                <Main>
                  {isVisible && <LeftPanel />}
                  <div>
                    <Outlet />
                    <Box height={80} />
                  </div>
                  {isVisible && <RightPanel />}
                </Main>
                {isAllowPushNotifications() && <PushNotification />}
                <SharePostModal />
                <FloatingButtonMenu />
                <BottomNavigation />
              </BadgeProvider>
            </SharePostProvider>
          </CreatePostProvider>
        </CreateAndSharePostProvider>
      </ProductTourProvider>
    </NPS>
  );
};

export default withAuthenticationRequired(MainLayout, {
  OnRedirecting: () => <div>Redirecting to the login page...</div>,
});

const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 960px) {
    grid-column-gap: 10px;
    grid-template-columns: 280px 1fr 280px;
  }
  @media only screen and (min-width: 1140px) {
    grid-template-columns: 320px 1fr 320px;
  }
  @media only screen and (min-width: 1281px) {
    grid-template-columns: 360px 1fr 360px;
  }
`;

import { Maybe, useGetFeatureToClubPermissionByIdQuery } from "api";

export const useClubPermission = (clubId?: Maybe<string> | undefined) => {
    const { data } = useGetFeatureToClubPermissionByIdQuery({
        variables: { clubId },
        skip: !clubId
    });
    return {
        permissions: data?.getFeatureToClubPermissionById?.permissions,
        token: data?.getFeatureToClubPermissionById?.club_permissions_token
    }
}
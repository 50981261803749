import React, { FC } from "react";
import styled from "@emotion/styled";
import { CircularProgress, Paper } from "@mui/material";

const Loader: FC = () => (
    <LoaderContainer>
        <Image src={require("assets/fabicon.webp")} alt="logo" />
        <LoaderWraper>
            <CircularProgress size={16} />
        </LoaderWraper>
    </LoaderContainer>
);

export default Loader;

const LoaderContainer = styled(Paper)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999999;
`;

const Image = styled.img`
  height: 56px;
  width: 56px;
`;

const LoaderWraper = styled.div`
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

import {
  GetMultiPartUploadMediaSignedUrlsQuery,
  GetMultiPartUploadMediaSignedUrlsDocument,
} from "./../api/index";
import { useApolloClient } from "@apollo/client";
import {
  CreateMultipartUploadDocument,
  CreateMultipartUploadQuery,
  CreateMultipartUploadQueryVariables,
  InputMaybe,
  SignedUrlMedia,
  CompleteMultipartUploadQuery,
  CompleteMultipartUploadQueryVariables,
  CompleteMultipartUploadDocument,
  PartDetailsInput,
  GetMultiPartUploadMediaSignedUrlsQueryVariables,
} from "api";
import axios from "axios";
import { UPLOAD_LARGE_FILE_THUMBNAIL } from "config";
import { isEmpty } from "lodash-es";
import toast from "react-hot-toast";
import { useAuth } from "react-oidc-context";

const useUploadLargeFile = () => {
  const auth = useAuth();
  const client = useApolloClient();

  // const uploadChunkToS3UsingPresignedURL = async (
  //   media: InputMaybe<InputMaybe<SignedUrlMedia>>,
  //   chunkFile: Blob
  // ) => {
  //   try {
  //     const {
  //       data: { getMediaSignedUrl },
  //       errors,
  //     } = await client.query<
  //       GetMediaSignedUrlQuery,
  //       GetMediaSignedUrlQueryVariables
  //     >({
  //       query: GetMediaSignedUrlDocument,
  //       variables: {
  //         media,
  //       },
  //     });

  //     if (errors?.length) throw Error("File upload failed");
  //     if (getMediaSignedUrl?.[0]?.signedURL) {
  //       const res = await axios.put(
  //         getMediaSignedUrl?.[0]?.signedURL,
  //         chunkFile,
  //         {
  //           headers: {
  //             "Content-Type": "application/octet-stream",
  //           },
  //         }
  //       );

  //       return {
  //         PartNumber: media?.MultiUpload?.PartNumber,
  //         ETag: res.headers.etag.replaceAll('"', ""),
  //       };
  //     } else throw Error("File upload error");
  //   } catch (e) {
  //     throw Error("File upload failed");
  //   }
  // };


  const uploadLargeFile = async (file: File) => {
    try {
      console.log("FILE", file);
      const chunkSize = 5 * 1024 * 1024; // Minimum chunk size 5MB
      const totalParts = Math.ceil(file.size / chunkSize);
      const fileName = file.name;

      const {
        data: { createMultipartUpload },
      } = await client.query<
        CreateMultipartUploadQuery,
        CreateMultipartUploadQueryVariables
      >({
        query: CreateMultipartUploadDocument,
        variables: { MIME: file.type, fileName },
      });

      if (!createMultipartUpload) return;
      const { key, uploadId, uploadfilename } = createMultipartUpload;
      const media: InputMaybe<
        InputMaybe<SignedUrlMedia> | InputMaybe<SignedUrlMedia>[]
      > = [];

      for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
        media.push({
          MultiUpload: {
            Key: key,
            PartNumber: partNumber,
            UploadId: uploadId,
          },
        });
      }
      // const {
      //   data: { getMediaSignedUrl },
      // } = await client.query<
      //   GetMediaSignedUrlQuery,
      //   GetMediaSignedUrlQueryVariables
      // >({
      //   query: GetMediaSignedUrlDocument,
      //   variables: {
      //     media,
      //   },
      // });

      // if (!getMediaSignedUrl) return;

      // QUERY: getMultiPartUploadMediaSignedUrls
      const {
        data: { getMultiPartUploadMediaSignedUrls },
        error: getMultiPartUploadMediaSignedUrlsERROR,
      } = await client.query<
        GetMultiPartUploadMediaSignedUrlsQuery,
        GetMultiPartUploadMediaSignedUrlsQueryVariables
      >({
        query: GetMultiPartUploadMediaSignedUrlsDocument,
        variables: {
          Key: key,
          UploadId: uploadId,
          filesize: file.size,
        },
      });
      if (
        getMultiPartUploadMediaSignedUrlsERROR ||
        isEmpty(getMultiPartUploadMediaSignedUrls?.signedURLs)
      ) {
        toast?.error(
          getMultiPartUploadMediaSignedUrls?.error?.message as string
        );
        return;
      }

      console.log("new", getMultiPartUploadMediaSignedUrls);

      const promiseArray: Array<Promise<any>> = [];

      for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
        const start = (partNumber - 1) * chunkSize;
        const end = partNumber * chunkSize;
        const blob = file.slice(start, end);
        console.log(
          "PUSH PEHLE",
          getMultiPartUploadMediaSignedUrls?.signedURLs[partNumber - 1]
            ?.signedUrl
        );
        promiseArray.push(
          axios.put(
            getMultiPartUploadMediaSignedUrls?.signedURLs[partNumber - 1]
              ?.signedUrl as string,
            blob,
            {
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          )
        );
      }

      console.log("PROMISE", promiseArray);

      // const {signedUrls,error} = getMultiPartUploadMediaSignedUrls
      // const parts = await Promise.all(promiseArray);
      const parts: InputMaybe<PartDetailsInput>[] = [];

      for (const promise of promiseArray) {
        const result: any = await promise;

        parts.push({
          PartNumber: promiseArray?.indexOf(promise) + 1,
          ETag: result?.headers?.etag?.replaceAll('"', ""),
        });
      }
      // console.log("VALUES", values);

      // const parts =  values.map((res, key) => {
      //   console.log("PART",res,key)
      //   return {
      //     PartNumber: key + 1,
      //     ETag: res?.headers?.etag,
      //   };
      // });

      console.log("PARTS", parts);

      const { errors } = await client.query<
        CompleteMultipartUploadQuery,
        CompleteMultipartUploadQueryVariables
      >({
        query: CompleteMultipartUploadDocument,
        variables: {
          data: {
            key: key,
            uploadId: uploadId,
            parts: parts,
          },
        },
      });
      if (errors?.length) throw Error("Complete Media Upload Failed");

      return {
        name: uploadfilename as string,
        type: file.type.replace(/\/.*/i, "/*"),
        uri: uploadfilename as string,
      };
    } catch (error: any) {
      // Handle GraphQL errors
      if (error.graphQLErrors) {
        console.error("GraphQL Errors:", error.graphQLErrors);
      }
      // Handle network errors
      if (error.networkError) {
        console.error("Network Error:", error.networkError);
      }
      // Handle any other errors
      console.error("An unexpected error occurred:", error);
    }
  };

  return uploadLargeFile;
};

export default useUploadLargeFile;

import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

export const PageLoading: FC = () => (
    <Container>
        <CircularProgress size={20} />
    </Container>
);

export default PageLoading;

const Container = styled.div`
  max-width: 540px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 160px;
`;
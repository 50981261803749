import { colors } from "theme/light.colors";
import createTheme from "./common.theme";

const lightTheme = createTheme("light", colors);

export default lightTheme;

// const theme = createTheme({
//     shape: {
//         borderRadius: 8
//     },
//     palette: {
//         mode: "light",
//         background: {
//             default: colors.body.background,
//             paper: colors.headerColor
//         }
//     },
//     typography: {
//         fontFamily: ["Trebuchet MS", "sans-serif"].join(","),
//         allVariants: {
//             color: colors.body.color
//         },
//         subtitle2: {
//             lineHeight: "1rem",
//             whiteSpace: "pre-wrap",
//             overflowWrap: "break-word",
//             wordWrap: "break-word",
//             wordBreak: "break-word"
//         },
//         caption: {
//             color: 'rgb(92, 112, 130)',
//             fontSize: '0.875rem',
//             lineHeight: 1.3333,
//             fontWeight: 400
//         },
//         h3: {
//             color: colors.fontColor.title,
//             fontSize: '1.875rem',
//             fontWeight: 'bold'
//         },
//         h4: {
//             fontSize: "1.5rem"
//         },
//         h5: {
//             color: colors.fontColor.subtitle,
//             fontSize: '1.25rem',
//         },
//         button: {
//             textTransform: "initial"
//         }
//     },
//     mixins: {
//         toolbar: {
//             minHeight: 56,
//             "@media (min-width:600px)": {
//                 minHeight: 56
//             }
//         }
//     },
//     components: {
//         MuiAppBar: {
//             styleOverrides: {
//                 colorPrimary: {
//                     backgroundColor: colors.headerColor,
//                     color: colors.body.color,
//                     boxShadow: "0 0.35px 0 rgb(0 0 0 / 20%)"
//                 }
//             }
//         },
//         MuiTypography: {
//             styleOverrides: {
//                 button: {
//                     textTransform: "initial"
//                 },
//                 h4: {
//                     color: colors.body.color,
//                     fontSize: "1.5rem"
//                 }
//             }
//         },
//         MuiAvatar: {
//             styleOverrides: {
//                 root: {
//                     backgroundColor: "#fff",
//                     color: colors.body.color
//                 }
//             }
//         },
//         MuiButton: {
//             styleOverrides: {
//                 containedPrimary: {
//                     backgroundColor: "#2196f3",
//                     color: "#ffffff",
//                     fontWeight: "bold"
//                 },
//                 textPrimary: {
//                     color: colors.body.color
//                 },
//                 root: {
//                     "&.Mui-disabled": {
//                         "backgroundColor": "#7170701f"
//                     }
//                 }
//             }
//         },
//         MuiListItem: {
//             styleOverrides: {
//                 root: {
//                     color: colors.body.color
//                 }
//             }
//         },
//         MuiTableCell: {
//             defaultProps: {
//                 align: "left"
//             },
//             styleOverrides: {
//                 root: {
//                     padding: 12
//                 }
//             }
//         },
//         MuiCard: {
//             defaultProps: {
//                 variant: "outlined"
//             },
//             styleOverrides: {
//                 root: {
//                     "@media (max-width:540px)": {
//                         borderRadius: 0,
//                         border: 0,
//                         marginBottom: 12
//                     }
//                 }
//             }
//         },
//         MuiBottomNavigation: {
//             styleOverrides: {
//                 root: {
//                     height: 48
//                 }
//             }
//         },
//         MuiBottomNavigationAction: {
//             styleOverrides: {
//                 root: {
//                     minWidth: 60
//                 }
//             }
//         },
//         MuiPaper: {
//             defaultProps: {
//                 variant: "outlined",
//                 elevation: 0
//             }
//         },
//         MuiButtonGroup: {
//             styleOverrides: {
//                 grouped: {
//                     fontSize: "0.725rem",
//                     lineHeight: "1rem",
//                     padding: 4
//                 }
//             }
//         }
//     }
// })

// export default theme;
// export const colors = {
//     body: {
//         background: "#ffffff",
//         color: "#131722"
//     },
//     backgroundColor: {
//         body: "#fafafa",
//         header: "#ffffff",
//         post: "#ffffff",
//         comment: "#f9f9f9",
//         reply: "#ffffff"
//     },
//     fontColor: {
//         title: "#131722",
//         subtitle: "#5c7082",
//         link: "#1976d2",
//         buttonPrimary: "#1da1f3",
//         buttonSecondary: "#fafafa",
//         dollar: "#1f4386",
//         hashtag: "#1b95e0",
//         user: "#1b95e0",
//     },
//     // backgroundColor: "#fafafa",
//     headerColor: "#f8f8f8",
//     cardColor: "#00000006",
//     postCardColor: "#ffffff",
//     commentCardColor: "#f9f9f9",
//     replyCardColor: "#ffffff",
//     // fontColor: "#131722",
//     subFontColor: "#5c7082",
// };

import { Colors } from "./common.theme";

export const colors: Colors | any = {
  background: {
    default: "#fafafa",
    card: "#ffffff",
    header: "#ffffff",
    disableButton: "#16181c",
    transparent: "rgba(0, 0, 0, 0.024)",
    mention: "#fff",
    selectedpoll:"#b3daff"
  },
  color: {
    default: "#131722",
    title: "#5c7082",
    subtitle: "#131722",
  },
  mention: {
    background: "#fff",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    focusColor: "#2196f3",
  },
  poll: {
    background: "rgba(0, 0, 0, 0.072)",
  },
  // selectedpoll: {
  //   background: "red",
  // },
};

import React from "react";
import { useGetReferBannerQuery } from "api";
import { CircularProgress, Skeleton } from "@mui/material";
import styled from "@emotion/styled";

const ReferBanner = () => {
  const { data, loading } = useGetReferBannerQuery();
//   if (loading)
//     return (
//       <LoaderContainer>
//         <CircularProgress />
//       </LoaderContainer>
//     );
  return (
    <div>
      {data?.getReferBanner && !loading ? (
        <Image src={data?.getReferBanner} alt="Refer Banner" />
      ) : (
        <Skeleton variant="rectangular" sx={{ width: "100%",height:"200px" }} />
      )}
    </div>
  );
};

export default ReferBanner;

const LoaderContainer = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
`;

import { isArray } from "lodash-es";

const imageCompression = async<T>(images: T): Promise<T | undefined> => {
    // Load browser-image-compression module only when needed
    const { default: compression } = await import("browser-image-compression");
    if (isArray(images))
        return Promise.all(
            images.map(i => compression(i, {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            }))
        ) as T
    else if (images instanceof File)
        return compression(images, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }) as T
    return undefined;
}

export default imageCompression;

import React, { FC } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Link, Typography,
    styled
} from "@mui/material";
import SimpleBar from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';
import { Close } from "@mui/icons-material";
import { PLAN_MONTHLY, PLAN_QUARTERLY, PLAN_YEARLY } from "config";
import GreenTick from "components/GreenTick";
import CancelIcon from '@mui/icons-material/Cancel';
import { useGetExternalResources } from "hooks";

interface PlanModalProps extends DialogProps {
    onhandleClose: () => void;
}

const PlanModal: FC<PlanModalProps> = ({ onhandleClose, ...rest }) => {
    const { externalResources } = useGetExternalResources()

    const handleClose = () => {
        onhandleClose();
    };

    const UTM_URL = `&utm_source=social.stockedge.com&utm_medium=website&utm_campaign=subscribe-web-popup&CallbackUrl=${window.location.href}`;
    return (
        <CustomModal scroll="body" onClose={handleClose} {...rest}>
            <CustomDialogTitle>
                Upgrade Plan
                <CustomIconButton onClick={handleClose}>
                    <Close />
                </CustomIconButton>
            </CustomDialogTitle>
            <DialogContent>
                {(externalResources?.RESTRICTION_MODAL_OFFER_INFO as string)?.trim() ? <Typography sx={{
                    color: "#ff7b00",
                    textAlign: "center",
                    whiteSpace: "pre-line",
                    marginBottom: "10px",
                    fontSize: "16px"
                }}>{externalResources?.RESTRICTION_MODAL_OFFER_INFO}</Typography> : null}
                <Typography
                    variant="body2"
                    align="center"
                    style={{
                        wordWrap: "break-word", fontWeight: "normal", whiteSpace: "pre-line", marginTop: 12, maxWidth: "400px",
                        margin: "auto"
                    }}>
                    Unlock the full potential of StockEdge Club. Upgrade your plan to benefit from the power of this exclusive community
                </Typography>

                <SimpleBar>
                    <GridContainer>
                        <Card>
                            <Typography variant="h5">Monthly</Typography>
                            <PriceWrap>
                                <span>
                                    {' '}
                                    ₹<Rupees>2499</Rupees> / Month
                                </span>
                            </PriceWrap>
                            <div style={{ height: 50 }} />
                            <FeatureContainer>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />Stock Recommendations </Feature>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />Query Resolution</Feature>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />Webinars - 1</Feature>
                                <Feature><CancelIcon style={{ color: "#f3aeae", height: 15, width: 15, marginRight: 8 }} />999 Courses</Feature>
                            </FeatureContainer>
                            <Button variant="contained" href={`${PLAN_MONTHLY}${UTM_URL}`}>
                                Subscribe
                            </Button>
                        </Card>
                        <Card style={{ border: "2.5px solid #4B75CB" }}>
                            <FloatingCaption>Popular</FloatingCaption>
                            <Typography variant="h5">Yearly</Typography>
                            <PriceWrap>
                                <span>
                                    ₹<Rupees>1999</Rupees> / Month
                                </span>
                            </PriceWrap>
                            <BackgroundDiv>
                                <Typography variant="caption">You pay ₹23989/year</Typography>
                            </BackgroundDiv>
                            <FeatureContainer>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />Stock Recommendations </Feature>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />Query Resolution</Feature>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />Webinars - Unlimited</Feature>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />999 Courses - Unlimited</Feature>
                            </FeatureContainer>
                            <Button variant="contained" href={`${PLAN_YEARLY}${UTM_URL}`}>
                                Subscribe
                            </Button>
                        </Card>
                        <Card>
                            <FloatingCaption>Best Deal</FloatingCaption>
                            <Typography variant="h5">3 Yearly</Typography>
                            <PriceWrap>
                                <span>
                                    ₹<Rupees>1667</Rupees> / Month
                                </span>
                            </PriceWrap>
                            <BackgroundDiv>
                                <Typography variant="caption">You pay ₹59999 / 3 Years</Typography>
                            </BackgroundDiv>
                            <FeatureContainer>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />Stock Recommendations </Feature>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />Query Resolution</Feature>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />Webinars - Unlimited</Feature>
                                <Feature><GreenTick style={{ height: 15, width: 15, marginRight: 8 }} />999 Courses - Unlimited</Feature>
                            </FeatureContainer>
                            <Button variant="contained" href={`${PLAN_QUARTERLY}${UTM_URL}`}>
                                Subscribe
                            </Button>
                        </Card>
                    </GridContainer>
                </SimpleBar>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-around" }}>
                <Link href="/plans" target="_blank">
                    Know more
                </Link>
            </DialogActions>
        </CustomModal>
    );
};

export default PlanModal;

const Rupees = styled("span")`
  font-size: 32px;
  font-weight: 700;
`;

const PriceWrap = styled("div")`
  padding: 0px 4px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #1d458a;
  font-size: 19px;
  white-space: nowrap;
`;

const CustomModal = styled(Dialog)`
  .MuiPaper-root {
    width: 100%;
    max-width: 680px;
  }
`;

const CustomDialogTitle = styled(DialogTitle)({
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600
})

const BackgroundDiv = styled("div")`
    background-color: #e8f0fa;
    padding: 7px;
    border-radius: 2px;
    margin-bottom: 15px;
    width: 180px;
    display: flex;
    justify-content: center;
`;
const FeatureContainer = styled("div")`
    height: 180px;
`;

const Feature = styled("p")`
    padding-bottom: 2px;
    font-size: 11px;
    display: flex;
    flex-direction: row;
`;
const FloatingCaption = styled("div")`
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #1D458A;
    box-shadow: inset 5px -5px 20px rgba(192, 107, 26, 0.2);
    border-radius: 0px 8px 0px 20px;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #4B75CB;
`;

const Card = styled("div")`
    position: relative;
    box-shadow: 0px 5px 20px rgba(227, 234, 239, 0.37);
    border-radius: 10px;
    padding: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 200px;
    margin:0px 5px ;
    border: .1px solid #7c75758a;

    .MuiButton-root {
        background: #1b6ac9;
        border-radius: 5px;
        width: 180px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        display: flex;
        align-items: center;
        text-align: center;
        &:hover, &:active {
            background: #1b6ac9;
        }
    }
`;

const GridContainer = styled("div")`
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
`;

const CustomIconButton = styled(IconButton)({
    position: 'absolute',
    top: 8,
    right: 8
})


import { Typography, Link } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

export const ReCaptchaBranding = () => (
    <CustomTypography>This site is protected by reCAPTCHA and the Google{"\n"}
        <Link href="https://policies.google.com/privacy" target="_blank">Privacy Policy</Link> and&nbsp; 
        <Link href="https://policies.google.com/terms" target="_blank">Terms of Service</Link>&nbsp;apply.
    </CustomTypography>
)

const CustomTypography = styled(Typography)`
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    white-space: pre-line;
    margin-bottom: 16px;
`;
import { FC } from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardProps, styled } from "@mui/material";
import { Maybe, Post } from "api";
import PostHeader from "./PostHeader";
import MediaPostNew from "./MediaPostNew";
import TextPost from "components/TextPost";
import { PollComponent } from "./PollComponent";
import { isEmpty } from "lodash-es";
import ImagePost from "./ImagePost";
import PostUnavailable from "./PostUnavailable";
import PostOffensiveContentText from "components/PostOffensiveContentText";

interface SharePostComponentProps {
  post: Maybe<Post> | undefined;
  disabled?: boolean;
}

const SharePostComponent: FC<SharePostComponentProps> = ({
  post,
  disabled,
}) => {
  if (!post) return <PostUnavailable />;

  return (
    <Link to={`/posts/${post?.postId}`} style={{ textDecoration: "none" }}>
      <PointerEventNone>
        <CustomCard
          sx={disabled ? { pointerEvents: "none" } : {}}
          data-category={post?.categoryDisplayDetails?.categoryDisplayName}
          isCategoryAvailable={Boolean(
            post?.categoryDisplayDetails?.categoryDisplayName
          )}
        >
          <PostHeader post={post} />
          <PostOffensiveContentText post={post}>
            {!isEmpty(post?.textV2) && (
              <CardContent>
                <TextPost text={post?.textV2} />
              </CardContent>
            )}
            <ImagePost media={post?.media} to={`/posts/${post?.postId}`} />
            <MediaPostNew post={post} isPostDetails={false} />
            {post?.hasPoll && post?.getPoll && <PollComponent post={post} />}
          </PostOffensiveContentText>
        </CustomCard>
      </PointerEventNone>
    </Link>
  );
};

export default SharePostComponent;

// const OffensiveContent = styled.div`
//   line-height: 22px;
//   color: #5c7082;
//   font-family: "Trebuchet MS", sans-serif;
//   font-size: 15px;
//   margin-bottom: 5px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 8px 16px;
//   border-radius: 4px;
//   a,
//   p {
//     display: inline;
//     text-align: center;
//   }
//   p {
//     color: #536471;
//     margin-right: 5px;
//   }
//   background-color: #eff1fe;
//   a {
//     color: #2196f3;
//     cursor: pointer;
//     white-space: nowrap;
//     margin-left: 5px;
//   }
// `;

const PointerEventNone = styled("div")`
  pointer-events: none;
`;

const CustomCard = styled(Card)<CardProps & { isCategoryAvailable?: boolean }>(
  ({ theme, isCategoryAvailable }) => ({
    ".MuiCardActions-root": {
      ".MuiButton-root": {
        flex: "0 1 100%",
      },
    },
    margin: "8px 8px 8px 8px",
    marginTop: isCategoryAvailable ? "32px" : "8px",
    borderRadius: isCategoryAvailable ? "0px 5px 5px 5px" : "5px",
    position: "relative",
    overflow: "visible",
    "&:after": {
      position: "absolute",
      whiteSpace: "nowrap",
      padding: "4px 12px",
      fontSize: "12px",
      fontWeight: "600",
      top: "-27px",
      left: "-1px",
      border: theme.mention.border,
      borderBottom: "none",
      backgroundColor: theme.palette.background.paper,
      borderRadius: "5px 5px 0px 0px",
    },
    "&[data-category]:after": {
      content: "attr(data-category)",
      display: "block",
    },
    "&[data-category='']:after": {
      display: "none",
    },
  })
);

import React, { FC } from "react";
import { Button, ButtonProps } from "@mui/material";
import styled from "@emotion/styled";
import { Maybe, Option } from "api";
import { CheckCircle } from "@mui/icons-material";

interface SelectedPollOptionButton extends ButtonProps {
  option: Maybe<Option>;
  totalVotes: number;
}

const SelectedPollOptionButton: FC<SelectedPollOptionButton> = ({
  option,
  totalVotes,
}) => {
  const width = (Number(option?.votes) * 100) / Number(totalVotes || 1);
  return (
    <CustomButton
      variant="outlined"
      disabled
      endIcon={<EndIcon>{Math.round(width) + "%"}</EndIcon>}
      width={width + "%"}
      selected={option?.hasUserSelected}
    >
      <span>
        {option?.optionText}
        {/* {option?.hasUserSelected && <CheckCircle />} */}
      </span>
    </CustomButton>
  );
};

export default SelectedPollOptionButton;

const EndIcon = styled.span`
  font-size: 0.875rem !important;
  font-weight: bold;
`;

const CustomButton = styled(Button)<{
  width: string;
  selected: Maybe<boolean> | undefined;
}>`
  && {
    justify-content: flex-start;
    background-color: transparent;
    border-color: ${({ theme, selected }: any) =>
      selected ? "rgb(27 106 201)" : theme.poll.background};
    color: inherit;
    margin-top: 8px;
    position: relative;
    .MuiSvgIcon-root {
      height: 1rem;
      width: 1rem;
      margin-left: 4px;
    }
    .MuiButton-endIcon {
      margin-left: auto;
    }
    span {
      z-index: 1;
    }
    &:after {
      content: "";
      position: absolute;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: ${({ theme, selected }: any) =>
        selected ? theme.selectedpoll.background : theme.poll.background};
      width: ${({ width }) => width};
    }
  }
`;

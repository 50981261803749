import React, { FC, useState } from "react";
import { SharePostContext } from "./SharePostContext";

export const SharePostProvider = ({ children }) => {
  const [{ open, post }, setState] = useState<{ open: boolean; post: any }>({
    open: false,
    post: null,
  });

  const handleOpenModal = (post: any) => {
    setState({ open: true, post });
  };

  const handleCloseModal = () => {
    setState({ open: false, post: null });
  };

  return (
    <SharePostContext.Provider
      value={{ open, post, handleOpenModal, handleCloseModal }}
    >
      {children}
    </SharePostContext.Provider>
  );
};

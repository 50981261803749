import { Maybe, User } from "api";

export const FOLLOW_STOCK = 'stock_follow_button_clicked';
export const FOLLOW_SECTOR = 'sector_follow_button_clicked';
export const FOLLOW_TOPIC = "hashtag_follow_button_clicked";
export const FOLLOW_USER = "user_follow_button_clicked";
export const JOIN_CLUB = "club_join_button_clicked";
export const ADD_WATCHLIST = "watchlist_added";

export const UNFOLLOW_STOCK = 'stock_unfollow_button_clicked';
export const UNFOLLOW_SECTOR = 'sector_unfollow_button_clicked';
export const UNFOLLOW_TOPIC = "hashtag_unfollow_button_clicked";
export const UNFOLLOW_USER = "user_unfollow_button_clicked";
export const LEAVE_CLUB = "club_leave_button_clicked";
export const REMOVE_WATCHLIST = "watchlist_removed";

export const CLICK_LEARN = "learn_clicked";
export const CLICK_WEBINARS = "webinars_clicked";
export const CLICK_COURSES = "courses_clicked";
export const CLICK_NAVBAR = "bottom_nav_bar_clicked";
export const CLICK_UPGRADE = "upgrade_button_clicked";
export const CLICK_UPGRADE_BUTTON = "navbar_upgrade_button_clicked";
export const CLICK_REFER_AND_EARN_BUTTON = "refer_and_earn_button_clicked";
export const CLICK_PROMOTION = "promotion_tab_clicked";
export const CLICK_SEE_ALL = "see_all_clicked";
export const CLICK_SEARCH = "query_search";
export const CLICK_SEARCH_RESULT = "search_result_clicked";
export const CLICK_SEARCH_INIT = "search_initialized";
export const CLICK_MENU = "burger_menu_initialized";
export const CLICK_MENU_MODULE = "module_drawer_menu_clicked"

export const POST_CREATED = "create_post_clicked";
export const USER_LOG_OUT = "user_logged_out";
export const USER_LOG_IN = "is_logged_in";
export const USER_ENGAGED = "user_engaged";


export const ONBOARDING_SKIP = "onboarding_module_skipped";
export const ONBOARDING_FINISH = "onboarding_module_completed";
export const ONBOARDING_INIT = "onboarding_module_initialized";
export const ONBOARDING_PROFILE_COMPLIT = "onboarding_module_profile_completed";
export const ONBOARDING_STOCK_ADD = "onboarding_module_added_stocks";
export const ONBOARDING_SECTOR_ADD = "onboarding_module_added_sectors";
export const ONBOARDING_TOPICS_ADD = "onbaording_module_added topics";

export const POST_NOTIFICATION_ENABLED = "post_notification_enabled";
export const POST_NOTIFICATION_DISABLED = "post_notification_disabled";

export const VIEW_OLDER_POSTS_CLICKED = "view_older_posts_clicked";

export const TOUR_SKIP = "tour_skipped";
export const TOUR_DONE = "tour_completed";


export const SEARCH_TAG_ADD = "search_tag_added";
export const POST_SEARCH = "post_search";

export const CALLBACK_REQUEST_INITIATED = "callback_request_initiated";
export const CALLBACK_REQUEST_COMPLETED = "callback_request_completed";

export const BADGE_STREAK_CLICKED = "badge_streak_clicked";
export const BADGE_REWARD_CLICKED = "badge_reward_clicked";


export const getEventScreen = () => {
    if (location.pathname.includes('/profile/')) {
        return 'Profile | StockEdge Social';
    } else {
        return document.title.replace(/^\(\d+\)\s*/, '');
    }
}

export const getTypeOfExpert = (user: Maybe<User> | undefined): string => {
    if (user?.isVerified) return "Blue Tick";
    if (user?.greenTick) return "Green Tick";
    if (user?.tick3) return "Yellow Tick";
    return "Non Verified";
}
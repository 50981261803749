export const getCursorPosition = (
  mentionText: string,
  plainText: string,
  position: number
): number => {
  const slicePlainText = plainText.slice(0, position);
  let i: number,
    j: number,
    flag = 0,
    colon = 0;
  for (i = 0, j = 0; i < mentionText.length; i++) {
    if (slicePlainText.length === 0) {
      break;
    }
    if (
      mentionText[i] === "[" &&
      ["@", "#", "$"].includes(mentionText[i + 1])
    ) {
      console.log("[ Start");
      flag = 1;
      continue;
    }
    if (mentionText[i] === ":" && flag) {
      console.log(": Colon");
      colon = 1;
      continue;
    }
    if (colon && mentionText[i] != "]") {
      continue;
    }
    if (colon && mentionText[i] === "]") {
      colon = 0;
      flag = 0;
      console.log("] End");
      continue;
    }
    if (mentionText[i] === slicePlainText[j]) {
      j++;
    }
    if (j >= slicePlainText.length) {
      i++;
      break;
    }
  }
  return i;
};

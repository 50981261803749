import React, { FC, ReactNode, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { IconButton, Typography } from "@mui/material";
import { BackIcon } from "icons";
import { Link, useNavigate } from "react-router-dom";
import {
  GetClubDocument,
  GetClubQuery,
  GetClubQueryVariables,
  GetDollarTagDetailsDocument,
  GetDollarTagDetailsQuery,
  GetDollarTagDetailsQueryVariables,
  GetHashTagDetailsDocument,
  GetHashTagDetailsQuery,
  GetHashTagDetailsQueryVariables,
  ProfileInfoDocument,
  ProfileInfoQuery,
  ProfileInfoQueryVariables
} from "api";
import short from "short-uuid";
import { useWindowWidth } from "@react-hook/window-size";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";

interface FilterPostProps {
  hashTagId: string | undefined;
  userId: string | undefined;
  dollarId: number | undefined;
  clubId: string | undefined;
}

const FilterContainer: FC<FilterPostProps> = ({
  hashTagId,
  userId,
  dollarId,
  clubId,
}) => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [renderArray, setRenderArray] = useState<Array<ReactNode>>([]);

  useEffect(() => {
    const requests: Array<Promise<ApolloQueryResult<any>>> = [];
    if (userId) {
      requests.push(
        client.query<ProfileInfoQuery, ProfileInfoQueryVariables>({
          query: ProfileInfoDocument,
          variables: { data: { userId } }
        })
      )
    }
    if (hashTagId) {
      requests.push(
        client.query<GetHashTagDetailsQuery, GetHashTagDetailsQueryVariables>({
          query: GetHashTagDetailsDocument,
          variables: { hashtagId: hashTagId }
        })
      )
    }
    if (dollarId) {
      requests.push(
        client.query<GetDollarTagDetailsQuery, GetDollarTagDetailsQueryVariables>({
          query: GetDollarTagDetailsDocument,
          variables: { dollarTagId: Number(dollarId) }
        })
      )
    }
    if (clubId) {
      requests.push(
        client.query<GetClubQuery, GetClubQueryVariables>({
          query: GetClubDocument,
          variables: { clubId }
        })
      )
    }

    Promise.all(requests).then(values => {
      const data = values.reduce((prev: any, { data }) => {
        if (data?.getClub) return {
          ...prev,
          getClub: data?.getClub
        }
        if (data?.getProfileInfo) return {
          ...prev,
          getProfileInfo: data?.getProfileInfo
        }
        if (data?.getDollarTagDetails) return {
          ...prev,
          getDollarTagDetails: data?.getDollarTagDetails
        }
        if (data?.getHashTagDetails) return {
          ...prev,
          getHashTagDetails: data?.getHashTagDetails
        }
      }, {});
      const renderArray: Array<ReactNode> = [];
      if (data?.getDollarTagDetails || data?.getHashTagDetails) {
        renderArray.push(<span> for</span>)
      }
      if (data?.getDollarTagDetails) {
        renderArray.push(
          <MentionStockTagLink
            key={short.generate()}
            // target="_blank"
            to={`/${data.getDollarTagDetails.dollarTagType}/${data.getDollarTagDetails.slug}/${data.getDollarTagDetails.dollarTagId}`}
            replace={window.location.pathname.includes(
              `/${data.getDollarTagDetails.dollarTagType}/${data.getDollarTagDetails.slug}/${data.getDollarTagDetails.dollarTagId}`
            )}
          >
            {` $${data.getDollarTagDetails.dollarTagName}`}
          </MentionStockTagLink>
        )
      }
      if (data?.getHashTagDetails) {
        renderArray.push(
          <MentionHashTagLink
            key={short.generate()}
            // target="_blank"
            to={`/hashtag/${data?.getHashTagDetails.hashtag?.hashtagName}`}
            replace={window.location.pathname.includes(
              `/hashtag/${data?.getHashTagDetails.hashtag?.hashtagName}`
            )}
          >
            {` #${data?.getHashTagDetails.hashtag?.hashtagName}`}
          </MentionHashTagLink>
        )
      }
      if (data?.getProfileInfo) {
        renderArray.push(
          <>
            <span> by </span>
            <MentionUserLink
              key={short.generate()}
              // target="_blank"
              to={`/profile/${data?.getProfileInfo?.username}`}
              replace={window.location.pathname.includes(
                `/profile/${data?.getProfileInfo?.username}`
              )}
            >
              {` @${data?.getProfileInfo?.firstName} ${data?.getProfileInfo?.lastName}`}
            </MentionUserLink>
          </>
        )
      }
      if (data?.getClub) {
        renderArray.push(
          <>
            <span> in </span>
            <MentionUserLink
              key={short.generate()}
              // target="_blank"
              to={`/club/${data.getClub.clubId}`}
              replace={window.location.pathname.includes(
                `/club/${data.getClub.clubId}`
              )}
            >
              {` ${data.getClub.clubName}`}
            </MentionUserLink>
          </>
        )
      }
      setRenderArray(renderArray);
    })
  }, [hashTagId, userId, dollarId, clubId, setRenderArray])

  const width = useWindowWidth();
  return (
    <Container>
      {width >= 540 ? (
        <ButtonContainer onClick={() => navigate(-1)}>
          <IconButton edge="start" color="inherit" className="Only-Mobile">
            <BackIcon />
          </IconButton>
          <Typography sx={{ cursor: "pointer" }}>Back</Typography>
        </ButtonContainer>
      ) : null}
      <TextContainer>
        <Typography pt={2}>
          Search results
          {renderArray}
        </Typography>
      </TextContainer>
    </Container>
  );
};

export default FilterContainer;

const Container = styled.div`
  max-width: 540px;
  margin: auto;
`;

const ButtonContainer = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  padding-top: 5px;
`;

const TextContainer = styled.div`
  padding: 5px;
  padding-inline: 8px;
`;

const MentionUserLink = styled(Link)`
  text-decoration: none;
  color: rgb(27, 149, 224);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionHashTagLink = styled(Link)`
  text-decoration: none;
  color: #1b6ac9;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionStockTagLink = styled(Link)`
  text-decoration: none;
  color: rgb(58 105 186);
  font-weight: 600;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

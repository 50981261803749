import React, { FC, useCallback, useRef, useState } from "react";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Maybe, useGetVoterListQuery } from "api";
import { isEmpty } from "lodash-es";
import UserListItem from "components/UserListItem";
import Modal from "./Modal";

const VotersModal: FC<{
  open: boolean;
  handleClose: () => void;
  pollId?: Maybe<string> | undefined;
}> = ({ open, handleClose, pollId }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6">Voters</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Voters pollId={pollId} />
      <DialogActions />
    </Modal>
  );
};

const Voters: FC<{ pollId: Maybe<string> | undefined }> = ({ pollId }) => {
  const observer = useRef<any>();
  const [end, setEnd] = useState(false);

  const { data, loading, fetchMore } = useGetVoterListQuery({
    variables: { pollId: `${pollId}`, limit: 20, offset: 0 },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const lastElement = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !end) {
          console.log("Last child visited!!");
          fetchMore({
            variables: { offset: data?.getVoterList?.length },
          }).then(({ data }) => {
            if (isEmpty(data?.getVoterList || [])) {
              setEnd(true);
            }
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [end, setEnd, loading, fetchMore, data?.getVoterList]
  );
  return (
    <DialogContent>
      {data?.getVoterList?.map((user, index, arr) => (
        <UserListItem
          key={user?.id}
          user={user}
          ref={index + 1 === arr.length ? lastElement : null}
          component_name="poll_voters_modal"
          upgrade_component_name="poll_voters_modal"
          button_component_name="poll_voters_modal"
        />
      ))}
      {loading ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        data?.getVoterList?.length == 0 && (
          <Stack justifyContent="center" alignItems="center">
            <span>No Voter Found</span>
          </Stack>
        )
      )}
    </DialogContent>
  );
};

export default VotersModal;

import { ListItem, Stack, Card, Button, styled } from "@mui/material";
import { Link as ReactLink } from "react-router-dom";

export const SliderWraper = styled(Card)`
  margin-top: 16px;
  &:last-child {
    margin-bottom: 16px;
  }
`;

export const CustomListItem = styled(ListItem)`
  .MuiListItemButton-root {
    border-radius: 6px;
  }
  .MuiListItemText-root {
    margin-top: 0;
    margin-bottom: 0;
  }
  .MuiTypography-body1 {
    font-size: 0.9375rem;
    word-wrap: break-word;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .MuiTypography-body2 {
    font-size: 0.725rem;
    color: rgb(92, 112, 130);
  }
  .MuiButton-root {
    position: absolute;
    right: 8px;
    text-transform: capitalize;
    font-size: 0.725rem;
    display: none;
  }
  &.follow:hover {
    .MuiTypography-body1 {
      max-width: calc(100% - 64px);
    }
  }
  &:hover {
    .MuiTypography-body1 {
      text-decoration: underline;
    }
    .MuiButton-root {
      display: inline-flex;
    }
  }
`;

export const SeeAllStack = styled(Stack)`
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  .MuiButton-text {
    font-size: 0.725rem;
    text-transform: capitalize;
    .MuiButton-endIcon {
      margin-left: 2px;
    }
  }
`;

export const MaxContainer = styled("div")`
  max-width: 540px;
  margin: auto;
`;

export const Container = styled("article")`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px 10px 10px;
  border-radius: 6px;
  margin: 10px 0;
  position: relative;

  .MuiIconButton-root {
    position: absolute;
    padding: 8px;
    top: 4px;
    right: 4px;
    background-color: #2196f3;
    color: #fff;

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
`;

export const Question = styled("p")`
  font-size: 1rem;
  line-height: 1.24rem;
  margin-bottom: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

export const Option = styled("button")`
  font-size: 1rem;
  font-weight: 400;
  margin: 4px 0;
  padding: 8px;
  background-color: transparent;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
`;

export const ActiveOption = styled(Option)<{ width?: number }>`
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.125);
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.055);
    width: ${({ width }) => `${width || 0}%`};
  }
  svg {
    width: 0.65em;
    height: 0.65em;
    margin-left: 5px;
  }
  span {
    &:first-child {
      display: inline-flex;
      align-items: center;
    }
  }
`;

export const OptionButton = styled(Option)`
  border-color: #2196f3;
  color: #2196f3;
  &:hover,
  &:focus {
    background-color: #2196f3;
    color: #fff;
  }
`;

export const Link = styled(ReactLink)`
  text-decoration: none;
  color: #131722;
`;

export const CountContainer = styled("div")`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 600;

  span {
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
`;

export const VotesButton = styled(Button)`
  && {
    height: 24px;
    margin-right: 2px;
    .MuiButton-label {
      font-size: 12px;
      font-weight: bold;
      margin: 0;
    }
  }
`;

import React, { FC } from "react";
import { Typography, styled, Box, BoxProps } from "@mui/material";
import { Maybe, User } from "api";

interface NameWithTickProps extends BoxProps {
  firstName: Maybe<string> | undefined;
  lastName: Maybe<string> | undefined;
  isVerified: boolean | null | undefined;
  greenTick: boolean | null | undefined;
  tick3: boolean | null | undefined;
}

const NameWithTick: FC<NameWithTickProps> = ({
  firstName,
  lastName,
  isVerified,
  greenTick,
  tick3,
  ...rest
}) => (
  <Container {...rest}>
    <Name>
      {firstName} {lastName}
    </Name>
    {isVerified ? (
      <Image src={require("assets/image/blue-check.webp")} alt="Blue Tick" />
    ) : greenTick ? (
      <Image src={require("assets/image/green-check.webp")} alt="Green Tick" />
    ) : tick3 ? (
      <Image src={require("assets/image/yellow-tick.webp")} alt="Yellow Tick" />
    ) : null}
  </Container>
);

export default NameWithTick;

const Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 4,
  minWidth: 0,
  width: "100%",
});

const Name = styled(Typography)({
  fontSize: "0.875rem",
  fontWeight: "bold",
  textDecorationStyle: "unset",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textDecoration: "none",
  "@media (min-width: 540px)": {
    fontSize: "1rem",
  },
});

const Image = styled("img")({
  height: 14,
  width: 14,
});

import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Maybe, User } from "api";
import Avatar from "components/Avatar"
import { getIsRingVisible } from "utills";

interface PostHeaderAvatarProps {
    user: Maybe<User> | undefined;
}

const PostHeaderAvatar: FC<PostHeaderAvatarProps> = ({ user, ...rest }) => (
    <Link to={`/profile/${user?.username}`}>
        <Avatar
            alt={user?.username || "Avatar"}
            src={user?.avatar?.LowResUri}
            hasRing={getIsRingVisible(user?.membershipLevel)}
            {...rest}
        />
    </Link>
)

export default PostHeaderAvatar;
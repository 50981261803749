import { Typography, styled } from "@mui/material";
import React, { ReactNode } from "react";
import { ModalType } from "./SubscriptionModalContext";

export const subscriptionsContents = (modal?: ModalType): ReactNode => (
  <DiscussionContent />
);

const DiscussionContent = () => (
  <>
    <IconContainer>
      <Image src={require("assets/view_only.webp")} />
    </IconContainer>
    <Heading>More engagement requires upgrading your level</Heading>
    <Description>
      Your plan restricts you to use the full potential of StockEdge Social.
      Upgrade your plan to benefit from the power of this exclusive community.
    </Description>
  </>
);

const Heading = styled(Typography)`
  && {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 1.1125rem;
    line-height: 1.5rem;
    text-align: center;
    color: #1f4386 !important;
    margin: 5px 0 32px 0;
  }
`;

const Description = styled(Typography)`
  && {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.92rem;
    line-height: 24px;
    text-align: center;
    color: #5c7082 !important;
    margin-bottom: 0.5rem;
  }
`;

const IconContainer = styled("div")`
  margin: 0 auto 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled("img")`
  height: 64px;
`;

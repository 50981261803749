import React from "react";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import imgPin from "assets/image/pin.webp";

const Pin = () => {
    return (
        <Container>
            <img src={imgPin} alt="pin" />
            <Typography>Pinned Post</Typography>
        </Container>
    )
}

export default Pin;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    padding-top: 8px;
    img {
        height: 24px;
        margin-bottom: -2px;
    }
    .MuiTypography-body1 {
        font-size: 14px;
        line-height: 24px;
    }
`;
import React, { FC, Fragment, Suspense, lazy, useState } from "react";
import { Avatar, AvatarProps, styled } from "@mui/material";
import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component";
import { sendEvent } from "ga4";

const MemberShipLevelModal = lazy(() => import("./MemberShipLevelModal"));
interface CustomAvatarProps extends AvatarProps {
  hasRing?: boolean;
  isBig?: boolean;
  isModalActive?: boolean;
}

const RedirectLazyLoadImage =
  LazyLoadImage as unknown as React.ComponentClass<LazyLoadImageProps>;
  
const CustomAvatar: FC<CustomAvatarProps> = ({
  alt,
  src,
  isBig,
  hasRing,
  isModalActive,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenModal = () => {
    sendEvent({ event: "mem_level_icon_clicked" });
    setOpen(true);
  };
  const handleCloseModal = () => {
    sendEvent({ event: "mem_level_modal_close_button_clicked" });
    setOpen(false);
  };

  return (
    <Fragment>
      <StyledAvatar hasRing={hasRing} isModalActive={isModalActive} {...rest}>
        <CustomLazyLoadImage alt={alt} src={src} hasRing={hasRing} />
        {hasRing ? (
          <svg
            id="Capa_1"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleOpenModal}
          >
            <circle cx="256" cy="256" fill="#1b6ac9" r="256" />
            <path
              d="m412.924 205.012c-1.765-5.43-6.458-9.388-12.108-10.209l-90.771-13.19-40.594-82.252c-2.527-5.12-7.742-8.361-13.451-8.361s-10.924 3.241-13.451 8.362l-40.594 82.252-90.771 13.19c-5.65.821-10.345 4.779-12.109 10.209s-.292 11.391 3.796 15.376l65.683 64.024-15.506 90.404c-.965 5.627 1.348 11.315 5.967 14.671 4.62 3.356 10.743 3.799 15.797 1.142l81.188-42.683 81.188 42.683c5.092 2.676 11.212 2.189 15.797-1.142 4.619-3.356 6.933-9.043 5.968-14.671l-15.506-90.404 65.682-64.024c4.088-3.986 5.559-9.947 3.795-15.377z"
              fill="#ffffd5"
            />
          </svg>
        ) : null}
      </StyledAvatar>
      {isModalActive ? (
        <Suspense fallback={<Fragment />}>
          <MemberShipLevelModal open={open} setOpen={setOpen} />
        </Suspense>
      ) : null}
    </Fragment>
  );
};

export default CustomAvatar;

const CustomLazyLoadImage =  styled(RedirectLazyLoadImage)<{ hasRing?: boolean }>(
  ({ hasRing }) => ({
    width: "100%",
    height: "100%",
    textAlign: "center",
    objectFit: "cover",
    color: "transparent",
    border: hasRing ? "2px solid white" : "none",
    borderRadius: "100%",
  })
);

const StyledAvatar = styled(Avatar)<
  AvatarProps & { hasRing?: boolean; isModalActive?: boolean }
>(({ hasRing, isModalActive }) => ({
  overflow: "visible",
  border: hasRing ? `2.5px solid #1b6ac9` : "none",
  svg: {
    height: "min(30px, 40%)",
    width: "min(30px, 40%)",
    position: "absolute",
    right: "-2%",
    bottom: "-6%",
    cursor: isModalActive ? "pointer" : "auto",
  },
}));

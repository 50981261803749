import React, { FC, Fragment, ReactNode } from "react";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Skeleton,
  styled,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Maybe,
  Reply,
  Post,
  Comment,
  useIsReportLazyQuery,
  useDeleteReplyMutation,
  PlatformPermissionFromFeatureTypes,
  useGetFeatureToPlatformPermissionQuery,
  useMeQuery,
} from "api";
import UserHeader from "./UserHeader";
import { useReportModal } from "components/ReportModal";
import { useConfirm } from "material-ui-confirm";
import toast from "react-hot-toast";
import { gql } from "@apollo/client";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import { usePermissionFeatureMapping } from "hooks";
import { sendEvent } from "ga4";
import MoreMenu from "components/MoreMenu";
interface CommentHeaderProps {
  post: Maybe<Post> | undefined;
  comment: Maybe<Comment> | undefined;
  reply: Maybe<Reply>;
  loading?: boolean;
}

const CommentHeader: FC<CommentHeaderProps> = ({
  post,
  comment,
  reply,
  loading: loadingReply,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { platformFeatureMap } = usePermissionFeatureMapping();
  const confirm = useConfirm();
  const reportModal = useReportModal();
  const { handleModalOpen } = useUpgradePlanContext();
  const { data: user } = useMeQuery();
  const [deleteReply] = useDeleteReplyMutation();
  const [getIsReport, { data, loading }] = useIsReportLazyQuery({
    fetchPolicy: "network-only",
  });
  const { data: platformPermission } = useGetFeatureToPlatformPermissionQuery();

  const handleDeleteComment = React.useCallback(() => {
    confirm({
      title: "Are you sure to delete this reply?",
      confirmationText: "Delete",
      confirmationButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
      dialogProps: {
        PaperProps: {
          style: {
            maxWidth: 400,
          },
        },
      },
    })
      .then(() => {
        const variables = {
          data: {
            postId: post?.postId + "",
            commentId: `${comment?.commentId}`,
            replyId: reply?.replyId + "",
          },
        };
        deleteReply({
          variables,
          update: (cache, { data }) => {
            cache.updateFragment(
              {
                id: cache.identify(comment!),
                fragment: gql`
                  fragment UpdateCommentOnDeleteReply on Comment {
                    replycount
                  }
                `,
              },
              (d) => ({
                ...d,
                replycount: d.replycount - 1,
              })
            );
            cache.evict({ id: cache.identify(reply!) });
            toast.success("Successfully deleted!");
          },
        });
      })
      .catch(() => {
        console.log("error");
      });
  }, [post?.postId, comment?.commentId, deleteReply]);

  const isMe = reply?.User?.id === user?.me?.id;
  const open = Boolean(anchorEl);

  const onClick = () => {
    sendEvent({
      event: "user_button_clicked",
      component_name: "reply",
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    getIsReport({
      variables: {
        postId: post?.postId,
        commentId: comment?.commentId,
        replyId: reply?.replyId,
      },
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menus: Array<ReactNode> = [
    <MenuItem
      key="report-on-reply"
      onClick={() => {
        if (
          !platformPermission?.getFeatureToPlatformPermission?.includes(
            PlatformPermissionFromFeatureTypes.CanReport
          )
        ) {
          sendEvent({
            event: "upgrade_modal_initialised",
            component_name: "post_details",
            feature_name_list:
              platformFeatureMap[PlatformPermissionFromFeatureTypes.CanReport],
          });
          handleModalOpen();
          return;
        }
        reportModal?.handleOpenModal(
          "REPLY",
          post?.postId!,
          comment?.commentId!,
          reply?.replyId!
        );
      }}
      disabled={data?.getIsReported}
    >
      {data?.getIsReported ? "Already reported" : "Report this reply"}
    </MenuItem>,
  ];
  if (isMe) {
    menus.push(
      <MenuItem key="delete-reply" onClick={handleDeleteComment}>
        Delete reply
      </MenuItem>
    );
  }

  return (
    <Container onClick={onClick}>
      <UserHeader
        user={reply?.User}
        createdAt={reply?.createdAt}
        loading={loadingReply as any}
      />
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="More Menu"
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <MoreMenu anchorEl={anchorEl} handleClose={handleClose}>
        {loading ? (
          <MenuItem sx={{ justifyContent: "center" }} disabled>
            <CircularProgress size={20} />
          </MenuItem>
        ) : (
          menus
        )}
      </MoreMenu>
    </Container>
  );
};

export default CommentHeader;

const Container = styled("div")`
  display: flex;
  align-items: flex-start;
  padding: 8px;

  .MuiIconButton-root {
    padding: 6px;
    margin-left: auto;
    .MuiSvgIcon-root {
      height: 18px;
      width: 18px;
    }
  }
`;

import { Box } from "@mui/material";
import React, { FC, Suspense, lazy } from "react";
import styled from "@emotion/styled";
import SimpleBarReact from "simplebar-react";
import MenuList from "./MenuList";

const Clubs = lazy(() => import("./components/Clubs"));
const MustFollowContainer = lazy(() => import("./components/MustFollowContainer"));
const SuggestedPeople = lazy(() => import("./components/SuggestedPeople"));

const LeftPanel: FC = () => {
  return (
    <StickyBox>
      <OnHoverScroll>
        <MenuList />
        <Suspense fallback={<div />}>
          <Clubs />
        </Suspense>
        <Suspense fallback={<div />}>
        <MustFollowContainer/>
        </Suspense>
        <Suspense fallback={<div />}>
          <SuggestedPeople />
        </Suspense>
      </OnHoverScroll>
    </StickyBox>
  );
};

export default LeftPanel;

const StickyBox = styled(Box)`
  height: calc(100vh - 64px);
  position: sticky;
  top: 64px;
`;

const OnHoverScroll = styled(SimpleBarReact)`
  max-height: calc(100vh - 64px);
  padding: 0 8px 8px 8px;
`;

import React from "react";
import styled from "@emotion/styled";

const MediaComment = ({ media }) => {
    const docs = media?.filter(x => x?.type.includes('application/')) || [];
    if (docs.length === 0) return null;
    return (
        <Container>
            {docs.map((item, key) =>
                <Relative
                    href={item?.uri?.showUri}
                    target="_blank"
                    rel="noreferrer"
                    title="Click to view pdf"
                    key={key}
                >
                    <Image src={item?.uri?.ThumbnailUrl} alt="" />
                    <Thumbnail src={require("assets/image/pdf.webp")} alt="thumbnail" />
                </Relative>
            )}
        </Container>
    );
};


export default MediaComment;

const Image = styled.img`
    width: auto;
    object-fit: cover;
    object-position: center;
    max-height: 240px;
    max-width: 240px;
    border-radius: 4px;
    display: inline-flex;
    cursor: pointer;
    margin-bottom: 4px;
    background-color: #fff;
    &:not(:last-child) {
      margin-right: 4px;
    }
`;

const Thumbnail = styled.img`
  position: absolute;
  height: 32px;
  bottom: 5px;
  right: 5px;
`;

const Relative = styled.a`
  position: relative;
`;

const Container = styled.div`
    margin-top: 8px;
`;

import React, { FC, Fragment, useMemo } from 'react';
import { styled } from "@mui/material";
import { Maybe } from 'api';

const Fire: FC<{ state: Maybe<string> | undefined|undefined, day: string | undefined }> = ({ state, day }) => {
    const renderSvg = useMemo(() => {
        if (state === "Visited") return (
            <Fragment>
                <g>
                    <path d="M31.6666 20.1667C31.6666 28.9202 24.5637 36.0144 15.8061 36C7.09815 35.9856 -0.00959029 28.8579 9.71319e-06 20.1491C0.00318618 17.1458 0.843257 14.3398 2.29822 11.9482C2.51705 11.5897 3.03206 11.5609 3.28843 11.8939C3.29479 11.9011 3.30043 11.9091 3.30601 11.9163C3.81467 12.5735 4.84956 12.3842 5.10912 11.5953C6.77761 6.52187 9.80937 1.63166 13.4244 0.0581727C13.8851 -0.142298 14.3938 0.200408 14.3869 0.702785C14.3867 0.719443 14.3865 0.736102 14.3864 0.752761C14.3712 3.79476 16.8186 6.32584 19.8596 6.40674C19.9718 6.4097 20.0831 6.40942 20.1938 6.40589C22.7922 6.32373 25.1718 7.84886 26.0925 10.2801C26.2574 10.7158 26.4126 11.1548 26.5576 11.5952C26.8171 12.3842 27.852 12.5734 28.3607 11.9162C28.3662 11.909 28.3718 11.901 28.3782 11.8938C28.637 11.5576 29.1528 11.5944 29.3732 11.957C30.8282 14.351 31.6666 17.161 31.6666 20.1667Z" fill="url(#paint0_linear_382_2)" />
                    <path d="M27.7806 24.0527C27.7806 30.6578 22.4209 36.0108 15.8128 35.9999C9.24199 35.9891 3.87876 30.6107 3.88596 24.0394C3.88836 21.7732 4.52225 19.6559 5.6201 17.8513C5.78521 17.5807 6.17387 17.559 6.36728 17.8103C6.37208 17.8157 6.37632 17.8217 6.38055 17.8271C6.76441 18.323 7.54526 18.1802 7.74107 17.5849C9.00015 13.7567 11.2878 10.0667 14.0156 8.87944C14.3632 8.72817 14.747 8.98674 14.7419 9.3658C14.7417 9.37836 14.7416 9.39093 14.7415 9.40356C14.7301 11.699 16.5768 13.6089 18.8714 13.6698C18.9561 13.6721 19.0401 13.6719 19.1236 13.6692C21.0843 13.6072 22.8798 14.758 23.5745 16.5926C23.6989 16.9214 23.8161 17.2526 23.9255 17.5849C24.1213 18.1803 24.9022 18.3231 25.286 17.8272C25.2902 17.8218 25.2945 17.8158 25.2993 17.8103C25.4945 17.5566 25.8837 17.5844 26.05 17.8579C27.148 19.6643 27.7806 21.7847 27.7806 24.0527Z" fill="url(#paint1_linear_382_2)" />
                    <path d="M9.71319e-06 20.1491C-0.00959029 28.8579 7.09815 35.9856 15.8061 36C24.5637 36.0144 31.6666 28.9202 31.6666 20.1667C31.6666 19.436 31.6167 18.717 31.5208 18.0125H0.145845C0.0507627 18.7112 0.000786184 19.4243 9.71319e-06 20.1491Z" fill="url(#paint2_linear_382_2)" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_382_2" x1="5.42535" y1="10.0551" x2="21.7046" y2="26.3343" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FEE45A" />
                        <stop offset="1" stopColor="#FEA613" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_382_2" x1="-2.07703" y1="2.55273" x2="21.5785" y2="26.2082" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FEDBBD" />
                        <stop offset="1" stopColor="#FD3581" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_382_2" x1="15.8333" y1="20.3296" x2="15.8333" y2="33.988" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FE646F" stopOpacity="0" />
                        <stop offset="0.2966" stopColor="#FC616A" stopOpacity="0.297" />
                        <stop offset="0.6214" stopColor="#F5575D" stopOpacity="0.621" />
                        <stop offset="0.9585" stopColor="#EB4747" stopOpacity="0.959" />
                        <stop offset="1" stopColor="#E94444" />
                    </linearGradient>
                </defs>
            </Fragment>
        )
        else return (
            <Fragment>
                <g opacity={state === "Missed" ? "0.7" : "1"}>
                    <path d="M31.6666 20.1667C31.6666 28.9202 24.5637 36.0144 15.8061 36C7.09815 35.9856 -0.00959029 28.8579 9.71319e-06 20.1491C0.00318618 17.1458 0.843257 14.3398 2.29822 11.9482C2.51705 11.5897 3.03206 11.5609 3.28843 11.8939C3.29479 11.9011 3.30043 11.9091 3.30601 11.9163C3.81467 12.5735 4.84956 12.3842 5.10912 11.5953C6.77761 6.52187 9.80937 1.63166 13.4244 0.0581727C13.8851 -0.142298 14.3938 0.200408 14.3869 0.702785C14.3867 0.719443 14.3865 0.736102 14.3864 0.752761C14.3712 3.79476 16.8186 6.32584 19.8596 6.40674C19.9718 6.4097 20.0831 6.40942 20.1938 6.40589C22.7922 6.32373 25.1718 7.84886 26.0925 10.2801C26.2574 10.7158 26.4126 11.1548 26.5576 11.5952C26.8171 12.3842 27.852 12.5734 28.3607 11.9162C28.3662 11.909 28.3718 11.901 28.3782 11.8938C28.637 11.5576 29.1528 11.5944 29.3732 11.957C30.8282 14.351 31.6666 17.161 31.6666 20.1667Z" fill="url(#paint0_linear_382_3)" />
                    <path d="M27.7806 24.0526C27.7806 30.6577 22.421 36.0107 15.8128 35.9998C9.24199 35.989 3.87876 30.6106 3.88596 24.0393C3.88836 21.7731 4.52225 19.6558 5.6201 17.8512C5.78521 17.5806 6.17387 17.5589 6.36728 17.8102C6.37208 17.8156 6.37632 17.8216 6.38055 17.827C6.76441 18.3229 7.54526 18.1801 7.74107 17.5848C9.00015 13.7566 11.2878 10.0666 14.0156 8.87935C14.3632 8.72808 14.747 8.98664 14.7419 9.3657C14.7417 9.37827 14.7416 9.39083 14.7415 9.40347C14.7301 11.6989 16.5768 13.6088 18.8714 13.6697C18.9561 13.672 19.0401 13.6718 19.1236 13.6691C21.0843 13.6071 22.8798 14.7579 23.5745 16.5925C23.6989 16.9213 23.8161 17.2525 23.9255 17.5849C24.1213 18.1802 24.9022 18.323 25.286 17.8271C25.2902 17.8217 25.2945 17.8157 25.2993 17.8102C25.4945 17.5565 25.8837 17.5843 26.05 17.8578C27.148 19.6642 27.7806 21.7846 27.7806 24.0526Z" fill="url(#paint1_linear_382_3)" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_382_3" x1="7" y1="6" x2="18" y2="36" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#CAE0FF" />
                        <stop offset="1" stopColor="#4E7BC4" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_382_3" x1="9.5" y1="14" x2="20" y2="36" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#8FBAFB" />
                        <stop offset="1" stopColor="#3664AF" />
                    </linearGradient>
                </defs>
            </Fragment>
        )
    }, [state])
    return (
        <Container>
            {state === "Missed" ? <svg className="cross" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_181_229)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.44556 8.87458L8.87445 6.44568L10.7904 4.52969C11.1534 4.16666 11.1534 3.5728 10.7904 3.20977C10.4274 2.84674 9.8335 2.84674 9.47047 3.20977L7.55448 5.12571L5.12559 7.5546L3.20965 9.4706C2.84662 9.83362 2.84662 10.4275 3.20965 10.7905C3.57267 11.1535 4.16654 11.1535 4.52957 10.7905L6.44556 8.87458Z" fill="#ED3B3B" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.87442 7.5546L6.44553 5.12571L4.52954 3.20977C4.16651 2.84674 3.57264 2.84674 3.20962 3.20977C2.84659 3.5728 2.84659 4.16666 3.20962 4.52969L5.12556 6.44568L7.55445 8.87458L9.47044 10.7905C9.83347 11.1535 10.4273 11.1535 10.7904 10.7905C11.1534 10.4275 11.1534 9.83362 10.7904 9.4706L8.87442 7.5546Z" fill="#ED3B3B" />
                </g>
                <defs>
                    <clipPath id="clip0_181_229">
                        <rect width="14" height="14" fill="white" />
                    </clipPath>
                </defs>
            </svg> : null}
            {state === "Visited" ? <svg className="tick" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.53816 10.6348C5.50633 10.6348 5.47484 10.6282 5.44568 10.6155C5.41652 10.6027 5.39031 10.584 5.36871 10.5607L0.799775 5.61839C0.769317 5.58544 0.749125 5.54433 0.741671 5.50008C0.734217 5.45584 0.739824 5.41038 0.757805 5.36927C0.775786 5.32816 0.805361 5.29318 0.842912 5.26862C0.880462 5.24406 0.924359 5.23098 0.969228 5.23098H3.16846C3.20148 5.23098 3.23411 5.23807 3.26416 5.25176C3.29421 5.26545 3.32097 5.28542 3.34264 5.31034L4.8696 7.06705C5.03462 6.71429 5.35407 6.12694 5.91466 5.41123C6.7434 4.35315 8.28489 2.79705 10.9222 1.39231C10.9732 1.36517 11.0325 1.35812 11.0884 1.37257C11.1443 1.38702 11.1928 1.4219 11.2242 1.47034C11.2557 1.51878 11.2678 1.57725 11.2583 1.63421C11.2487 1.69116 11.2181 1.74246 11.1726 1.77798C11.1625 1.78585 10.1457 2.58662 8.9754 4.05336C7.89835 5.40313 6.46661 7.61021 5.7621 10.4595C5.74972 10.5096 5.72094 10.554 5.68034 10.5858C5.63973 10.6176 5.58966 10.6349 5.53809 10.6349L5.53816 10.6348Z" fill="#00CC2D" />
            </svg> : null}
            <svg width="36" height="40" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                {renderSvg}
                <text x="50%" y="70%" dominantBaseline="middle" textAnchor="middle" fill='#fff' fontSize={14} fontWeight="bold">{day}</text>
            </svg>
        </Container>
    )
}

export default Fire;

const Container = styled("div")({
    position: "relative",
    ".cross": {
        position: "absolute",
        right: -2,
        top: -7
    },
    ".tick": {
        position: "absolute",
        right: -2,
        top: -7
    },
})
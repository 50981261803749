import React from 'react'
import { css, Global } from '@emotion/react'
import trebuc from "assets/fonts/trebuc.ttf";
import { useTheme } from '@mui/material/styles';

const GlobalStyles = () => {
    const theme = useTheme();
    return (
        <Global styles={css`
                @font-face {
                    font-family: 'Trebuchet MS';
                    src: url(${trebuc}) format('truetype');
                    font-display: swap;
                }
                html, body, #output {
                    width: 100%;
                    height: 100%;
                }

                reach-portal {
                    --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
                    --rsbs-bg: ${theme.palette.background.default};
                    --rsbs-handle-bg: rgb(255 255 255 / 25%);
                    --rsbs-max-w: auto;
                    --rsbs-ml: env(safe-area-inset-left);
                    --rsbs-mr: env(safe-area-inset-right);
                    --rsbs-overlay-rounded: 16px;
                    [data-rsbs-overlay]:after {
                        z-index: 9999;
                    }
                }

                a.MuiTypography-root {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .AlertPaper.MuiDialog-paper {
                    max-width: 360px;
                    .MuiDialogActions-root {
                        justify-content: center;
                    }
                }
                /* Css for mention input */
                .mention {
                    min-height: calc(2 * 14px) !important;
                }
                .mention__input {
                    border: 0;
                    outline: 0;
                    padding: 0 !important;
                    margin: 0 !important;
                    font-size: 14px !important;
                    line-height: 18px !important;
                    font-weight: 400 !important;
                    min-height: 36px !important;
                    color: inherit;
                }

                .mention__highlighter {
                    padding: 0 !important;
                    margin: 0 !important;
                    font-size: 14px !important;
                    line-height: 18px !important;
                    font-weight: 400 !important;
                }

                .mention__suggestions {
                    min-width: 200px !important;
                    max-width: 320px !important;
                    max-height: 240px !important;
                    background-color: ${theme.mention.background} !important;
                    border-radius: 4px;
                    border:  ${theme.mention.border};
                    box-shadow: 0 4px 13px -3px rgb(0 0 0 / 10%);
                    overflow-y: auto;
                }

                .mention__suggestions__item {
                    padding: 4px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .mention__suggestions__item:not(:last-child) {
                    border-bottom: ${theme.mention.border};
                }

                .mention__suggestions__item--focused {
                    background-color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.15)" : "rgba(0,0,0,0.15)"};
                    color: #fff !important;
                }

                .mention_user_mention {
                    background-color: ${theme.mention.focus};
                    color: #fff !important;
                    font-size: 14px !important;
                    line-height: 18px !important;
                    font-weight: 400 !important;
                    z-index: 1;
                    position: relative;
                    border-radius: 2px;
                }

                .mention_hastag_mention {
                    background-color: ${theme.mention.focus};
                    color: #fff !important;
                    font-size: 14px !important;
                    line-height: 18px !important;
                    font-weight: 400 !important;
                    z-index: 1;
                    position: relative;
                    border-radius: 2px;
                }

                .mention_stock_mention {
                    background-color: ${theme.mention.focus};
                    color: #fff !important;
                    font-size: 14px !important;
                    line-height: 18px !important;
                    font-weight: 400 !important;
                    z-index: 1;
                    position: relative;
                    border-radius: 2px;
                }
            `}
        />
    )
}

export default GlobalStyles;
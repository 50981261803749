import React, { FC, Fragment, ReactNode, Suspense, lazy } from "react";
import { Menu, Stack, useMediaQuery } from "@mui/material";
import 'react-spring-bottom-sheet/dist/style.css';

const BottomSheet = lazy(() => import("react-spring-bottom-sheet").then(({ BottomSheet }) => ({ default: BottomSheet })))

interface MoreMenuProps {
    anchorEl: HTMLElement | null;
    handleClose?: (() => void) | undefined;
    children: ReactNode;
}

const MoreMenu: FC<MoreMenuProps> = ({ anchorEl, children, handleClose }) => {
    const isDesktop = useMediaQuery('(min-width:540px)');
    const open = Boolean(anchorEl);
    
    if (isDesktop)
        return (
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                variant="menu"
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        width: '100%',
                        maxWidth: '264px',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {children}
            </Menu>
        )
    return (
        <Suspense fallback={<Fragment />}>
            <BottomSheet
                open={open}
                onDismiss={handleClose}
            >
                <Stack paddingX={1} paddingBottom={1}>
                    {children}
                </Stack>
            </BottomSheet>
        </Suspense>
    )
}

export default MoreMenu;

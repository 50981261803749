import React from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import useRequestACall from "./useRequestACall";
import DownloadPrompt from "components/DownloadPrompt/DownloadPrompt";

const RequestACallButton = () => {
  const { handleOpen } = useRequestACall();
  return (
    <HeaderStyledButton
      variant="contained"
      color="primary"
      disableElevation
      onClick={handleOpen}
    >
      Request a Call
    </HeaderStyledButton>
  );
};

export const RequestACallFixedButton = () => {
  const { handleOpen } = useRequestACall();
  return (
    <>
      <DownloadPrompt />
      <FixedStyledButton
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleOpen}
      >
        Request a Call
      </FixedStyledButton>
    </>
  );
};

export default RequestACallButton;

const StyledButton = styled(Button)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.75;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  background: #1d458a;
  border-radius: 4px;
  white-space: nowrap;
  margin-right: 16px;
  &:hover,
  &:active {
    background: #1d458a;
  }
`;

const HeaderStyledButton = styled(StyledButton)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const FixedStyledButton = styled(StyledButton)`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  margin: 0;
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 9999;
  border-radius: 0;
  @media (min-width: 768px) {
    display: none;
  }
`;

import { IconButton, IconButtonProps, Paper, Tooltip } from "@mui/material";
import React, { FC, memo, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import ReactPlayer from "react-player/lazy";
import { Close } from "@mui/icons-material";
import { Maybe, Media } from "api";

interface VideoProps extends IconButtonProps {
  video: File | Maybe<Media> | undefined;
  videoThumbnail: File;
}

function isFile(input) {
  if ("File" in window && input instanceof File) return true;
  else return false;
}

export const Video: FC<VideoProps> = ({ video, children, ...others }) => {
  const src = isFile(video)
    ? URL.createObjectURL(video as any)
    : (video as any)?.uri?.showUri;

  const poster = isFile(others?.videoThumbnail)
    ? URL.createObjectURL(others?.videoThumbnail as any)
    : (video as any)?.uri?.ThumbnailUrl && (video as any)?.uri?.ThumbnailUrl;

  console.log("BOPOLEAN", poster, isFile(others?.videoThumbnail));

  const [pause, setPause] = useState(true);

  // console.log("URI", (video as any)?.uri?.ThumbnailUrl);
  // console.log("URI thumbnail", others?.videoThumbnail as any);
  // console.log(
  //   "URI thumbnail link",
  //   URL.createObjectURL(others?.videoThumbnail as any)
  // );

  const handlePause = () => {
    setPause(true);
  };
  const handlePlay = () => {
    setPause(false);
  };

  const containerRef = useRef<ReactPlayer>(null);
  const [containerHeight, setContainerHeight] = useState<any>(0);

  console?.log("Container", containerHeight);

  useEffect(() => {
    if (containerRef.current && video) {
      setContainerHeight(containerRef.current.props.height as number);
      // setContainerHeight(
      //   containerRef.current.getInternalPlayer()?.clientHeight as number
      // );
    }
  }, [containerRef, setContainerHeight, containerHeight, video, poster]);

  return (
    <Container variant="outlined" containerHeight={containerHeight}>
      <Tooltip title={"Clear video"}>
        <IconButton {...others}>
          <Close />
        </IconButton>
      </Tooltip>
      {Boolean(video) && (
        <>
          {/* {pause && <PosterImage src={poster} alt="Video thumbnail" />} */}
          <ReactPlayer
            key={poster && poster}
            config={{
              file: {
                attributes: {
                  poster: pause ? poster : undefined,
                },
              },
            }}
            width="100%"
            url={src}
            controls
            onPlay={handlePlay}
            onPause={handlePause}
            playing={!pause}
          />
        </>
      )}
      {pause && children}
    </Container>
  );
};

const Container = styled(Paper)<{ containerHeight: number }>(
  ({ containerHeight }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    marginTop: "15px",

    "video[poster]": {
      // maxHeight: `${containerHeight} !important`,
      maxHeight: `272px !important`,
    },

    "& > div": {
      height: "272px !important",
    },

    ".MuiIconButton-root": {
      position: "absolute",
      right: "8px",
      top: "8px",
      padding: "8px",
      zIndex: 1,
      backgroundColor: "#2196f3",
      color: "#ffffff",

      "&:hover, &:active, &:focus": {
        backgroundColor: "#2196f3",
      },
    },
  })
);

const PosterImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: -20px;
  right: 0px;
  width: 100%;
  height: -webkit-fill-available;
  object-fit: contain;
  z-index: 0;
  background: black;
`;

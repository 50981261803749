export default {
  pureWhite: "#FFFFFF", // card color
  white: "#f7f9fa",
  black: "#13161a",
  green: "#3DCC65", // Stocktag color
  blue: "#031B4E", // Text color | Bottom bar selected ICON color
  lightBlue: "#1da1f3", // Button follow following color | #tag color
  mutedBlue: "#e8f3ff",
  danger: "#dd4b39",
  warning: "#FBD71F", // watchlist badge color
  muted: "#c9ccd1",
  mutedLight: "#e6e8ea", // Background color app
  mutedDark: "#5c7082", // small size text color | ICON color
  buttonAppSyncColor:"#1b6ac9"
};

import React, { FC, Fragment } from "react";
import {
  Button,
  DialogTitle,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  Checkbox,
  CircularProgress,
  Divider,
  DialogProps,
  styled,
} from "@mui/material";
import { Maybe, Club } from "api";
import Modal from "components/Modal";
import { Close } from "@mui/icons-material";
import { getReadableText } from "components/TextPost";
import { Formik, FormikHelpers } from "formik";

interface ClubDisclaimerModalProps
  extends Exclude<DialogProps, "children" | "onClose" | "onClick"> {
  loading?: boolean;
  club: Maybe<Club> | undefined;
  handleOnSubmit: (
    values: {
      agreedToDisclaimer: boolean;
    },
    formikHelpers: FormikHelpers<{
      agreedToDisclaimer: boolean;
    }>
  ) => void;
  handleCloseModal: () => void;
}

export const ClubDisclaimerModal: FC<ClubDisclaimerModalProps> = ({
  club,
  loading,
  handleOnSubmit,
  handleCloseModal,
  ...rest
}) => {
  return (
    <CustomModal onClose={handleCloseModal} {...rest}>
      <Formik
        initialValues={{ agreedToDisclaimer: false }}
        onSubmit={handleOnSubmit}
      >
        {({ values, handleChange, handleBlur, submitForm }) => (
          <Fragment>
            {loading && (
              <AbsoluteContainer>
                <CircularProgress />
              </AbsoluteContainer>
            )}
            <DialogTitle>
              Disclaimer
              <IconButton onClick={handleCloseModal}>
                <Close />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Typography>{getReadableText(club?.disclaimer || "")}</Typography>
            </DialogContent>
            <CheckBoxLabel>
              <Checkbox
                name="agreedToDisclaimer"
                checked={values.agreedToDisclaimer}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
                color="primary"
              />{" "}
              I understand
            </CheckBoxLabel>
            <DialogActions>
              <Button onClick={handleCloseModal} disableElevation>
                Cancel
              </Button>
              <Button
                onClick={submitForm}
                disabled={!values.agreedToDisclaimer}
                variant="contained"
                disableElevation
              >
                Accept
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Formik>
    </CustomModal>
  );
};

const CheckBoxLabel = styled("label")`
  display: flex;
  align-items: center;
`;

const AbsoluteContainer = styled("div")`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

const CustomModal = styled(Modal)`
  .MuiPaper-root {
    height: auto !important;
    max-height: 500px;
  }
  .MuiDialogContent-root {
    .MuiTypography-root {
      white-space: pre-line;
    }
  }
  .MuiCheckbox-root {
    padding: 6px;
    margin-left: 8px;
  }
  .MuiCheckbox-colorPrimary {
    color: #1da1f3;
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #1da1f3;
  }
  .MuiCircularProgress-root {
    height: 32px !important;
    width: 32px !important;
  }
`;

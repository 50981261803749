import React, { useState, Fragment } from "react";
import { ReportModalContext } from "./ReportModalContext";
import { ReportModal } from "./ReportModal";
import { PostReport } from "./PostReport";
import { CommentReport } from "./CommentReport";
import { ReplyReport } from "./ReplyReport";

export type Entity = "POST" | "COMMENT" | "REPLY" | undefined;
export type HandleOpenModal = (entity: Entity, postId: string | undefined, commentId: string | undefined, replyId: string | undefined) => void;

interface IState {
  open: boolean;
  entity: "POST" | "COMMENT" | "REPLY" | undefined;
  postId: string | undefined;
  commentId: string | undefined;
  replyId: string | undefined;
}

export const ReportModalProvider = ({ children }) => {
  const [{
    open,
    entity,
    postId,
    commentId,
    replyId
  }, setState] = useState<IState>({
    open: false,
    entity: undefined,
    postId: undefined,
    commentId: undefined,
    replyId: undefined
  });

  const handleOpenModal: HandleOpenModal = (entity, postId, commentId, replyId) => {
    setState({ open: true, entity, postId, commentId, replyId });
  };

  const handleCloseModal = () => {
    setState({ open: false, entity: undefined, postId: undefined, commentId: undefined, replyId: undefined });
  };

  return (
    <Fragment>
      <ReportModalContext.Provider
        value={{ open, handleOpenModal, handleCloseModal }}
      >
        {children}
      </ReportModalContext.Provider>
      <ReportModal open={open} onClose={handleCloseModal}>
        {entity === "POST" && <PostReport handleCloseModal={handleCloseModal} postId={postId}/>}
        {entity === "COMMENT" && <CommentReport handleCloseModal={handleCloseModal} postId={postId} commentId={commentId}/>}
        {entity === "REPLY" && <ReplyReport handleCloseModal={handleCloseModal} postId={postId} commentId={commentId} replyId={replyId}/>}
      </ReportModal>
    </Fragment>
  );
};

import React, { FC, Fragment, useState } from "react";
import styled from "@emotion/styled";
import { CardContent } from "@mui/material";
import { Maybe, Media } from "api";
import { Link } from "react-router-dom";
import ImageZoom from "components/ImageZoom";

interface ImagePostProps {
  media?: Maybe<Maybe<Media>[]> | undefined;
  to: string;
}

const ImageComment: FC<ImagePostProps> = ({ media, to }) => {
  const images = media?.filter(x => x?.type.includes('image/'));
  const noOfImages = images?.length;

  if (!noOfImages) return null;
  return (
    <CardContent component={Link} to={to} sx={{
      padding: 0,
      display: 'flex',
      flexWrap: 'wrap'
    }}>
      {images?.map((image, key) => <Image
        key={key}
        src={image?.uri?.HighResUri}
        alt=""
      />)}
    </CardContent>
  );
};

export default ImageComment;

interface ImageCommentWithPreviewerProps {
  media?: Maybe<Maybe<Media>[]> | undefined;
}

interface State {
  imagePreview: boolean;
  index: number;
}

export const ImageCommentWithPreviewer: FC<ImageCommentWithPreviewerProps> = ({ media }) => {
  const [{ imagePreview, index }, setState] = useState<State>({
    imagePreview: false,
    index: 0,
  });
  const images = media?.filter(x => x?.type.includes('image/'));
  const noOfImages = images?.length;
  const styles: React.CSSProperties = {};
  const handleSetImagePreview = (event: React.SyntheticEvent, index: number) => {
    event.preventDefault();
    setState({ imagePreview: true, index });
  };

  const handleResetImagePreview = () => {
    console.log("close");
    setState({ imagePreview: false, index: 0 });
  };



  if (!noOfImages) return null;
  if (noOfImages == 2) {
    styles.display = "grid";
    styles.gridTemplateColumns = "1fr 1fr";
    styles.gap = 4;
    styles.gridAutoRows = 360;
  }
  // if (noOfImages == 3) {

  // }

  const renderImages = images?.slice(0, 4)?.map((image, key) => <Image
    key={key}
    src={image?.uri?.HighResUri}
    alt=""
    onClick={(e) => handleSetImagePreview(e, key)}
    title="click to preview"
  />)
  return (
    <Fragment>
      <CardContent sx={{
        padding: 0,
        display: 'flex',
        flexWrap: 'wrap'
      }}>
        {renderImages}
      </CardContent>
      {imagePreview && (
        <ImageZoom
          images={images}
          index={index}
          handleClose={handleResetImagePreview}
        />
      )}
    </Fragment>
  );
};

const Image = styled.img`
    width: auto;
    object-fit: cover;
    object-position: center;
    max-height: 125px;
    max-width: 240px;
    border-radius: 4px;
    display: inline-flex;
    cursor: pointer;
    margin-bottom: 4px;
    margin-left: 4px;
`;

const CardMedia = styled(Link)`
  text-decoration: none;
  display: block;
  margin: 10px 0;
`;

const CardMedia2 = styled(CardMedia)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
  grid-auto-rows: 360px;
  ${Image} {
    height: 100%;
  }
`;

const CardMedia3 = styled(CardMedia2)`
  grid-auto-rows: 180px;
  img {
    &:first-child {
      grid-row: 1 / 3;
    }
  }
  @media (min-width: 360px) {
    grid-auto-rows: 200px;
  }
  @media (min-width: 540px) {
    grid-auto-rows: 240px;
  }
`;

const CardMedia4 = styled(CardMedia2)`
  grid-auto-rows: 180px;
  @media (min-width: 360px) {
    grid-auto-rows: 200px;
  }
  @media (min-width: 540px) {
    grid-auto-rows: 240px;
  }
`;

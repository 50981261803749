import { Maybe } from "api";

export type ModalType =
  | "POST"
  | "ASK_QUERY"
  | "EDIT_ASK_QUERY"
  | "SHARE_STOCK_IDEA"
  | "EDIT_SHARE_STOCK_IDEA"
  | "SHARE_POST"
  | "EDIT_POST"
  | "EDITED_POST_HOSTORIES"
  | "SHARED_POSTS"
  | "MEDIA_OF_POSTS";

export type State = {
  modal: ModalType | undefined;
  userId?: Maybe<string>;
  postId?: Maybe<string>;
  clubId?: Maybe<string>;
  sharePostId?: Maybe<string>;
  isAudienceChangeAble?: boolean;
};

export enum ActionTypes {
  OPEN_POST_MODAL = "OPEN_POST_MODAL",
  OPEN_QUERY_MODAL = "OPEN_QUERY_MODAL",
  OPEN_EDIT_QUERY_MODAL = "OPEN_EDIT_QUERY_MODAL",
  OPEN_STOCK_IDEA_MODAL = "OPEN_STOCK_IDEA_MODAL",
  OPEN_EDIT_STOCK_IDEA_MODAL = "OPEN_EDIT_STOCK_IDEA_MODAL",
  OPEN_POST_SHARE_MODAL = "OPEN_POST_SHARE_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
  EDIT_POST = "EDIT_POST",
  EDITED_POST_HISTORIES = "EDITED_POST_HISTORIES",
  SHARED_POSTS = "SHARED_POSTS",
  MEDIA_OF_POSTS = "MEDIA_OF_POSTS",
}

export type SetOpenPostModalAction = {
  type: ActionTypes.OPEN_POST_MODAL;
  clubId?: string;
  isAudienceChangeAble?: boolean;
};

export type SetOpenQueryModalAction = {
  type: ActionTypes.OPEN_QUERY_MODAL;
  clubId?: string;
  isAudienceChangeAble?: boolean;
};

export type SetOpenStockIdeaModalAction = {
  type: ActionTypes.OPEN_STOCK_IDEA_MODAL;
  clubId?: string;
  isAudienceChangeAble?: boolean;
};

export type SetOpenPostShareModalAction = {
  type: ActionTypes.OPEN_POST_SHARE_MODAL;
  sharePostId: string;
  clubId?: string;
  isAudienceChangeAble?: boolean;
};

export type SetOpenEditPostModalAction = {
  type: ActionTypes.EDIT_POST;
  postId: string;
  clubId?: string;
  sharePostId?: Maybe<string> | undefined;
  isAudienceChangeAble?: boolean;
};

export type SetOpenEditQueryModalAction = {
  type: ActionTypes.OPEN_EDIT_QUERY_MODAL;
  postId: string;
};

export type SetOpenEditStockIdeaModalAction = {
  type: ActionTypes.OPEN_EDIT_STOCK_IDEA_MODAL;
  postId: string;
};

export type SetOpenSharedPostsModalAction = {
  type: ActionTypes.SHARED_POSTS;
  postId: string;
};

export type SetOpenEditedPostHistoriesModalAction = {
  type: ActionTypes.EDITED_POST_HISTORIES;
  postId: string;
};

export type SetOpenMediaOfPostsModalAction = {
  type: ActionTypes.MEDIA_OF_POSTS;
  userId: Maybe<string>;
};

export type SetCloseModalAction = {
  type: ActionTypes.CLOSE_MODAL;
};

export type Actions =
  | SetOpenPostModalAction
  | SetOpenQueryModalAction
  | SetOpenEditQueryModalAction
  | SetOpenStockIdeaModalAction
  | SetOpenEditStockIdeaModalAction
  | SetOpenPostShareModalAction
  | SetOpenEditPostModalAction
  | SetOpenSharedPostsModalAction
  | SetOpenMediaOfPostsModalAction
  | SetOpenEditedPostHistoriesModalAction
  | SetCloseModalAction;

export type CreateAndSharePostData = [
  State,
  React.Dispatch<Actions> | undefined
];

import React, { FC } from "react";
import { styled } from "@mui/material";
import { Maybe, Comment, Post, Reply, TagType } from "api";
import CommentHeader from "./CommentHeader";
import Comments from "ui/Comments";
import { CommentBottomActions } from "./CommentBottomActions";
import TextPost from "components/TextPost";
import { Card, Stack } from "@mui/material";
import ImageComment, { ImageCommentWithPreviewer } from "./ImageComment";
import ImageReplyNew from "./ImageReplyNew";
import { Link } from "react-router-dom";
import Avatar from "components/Avatar";
import { getIsRingVisible } from "utills";
import { sendEvent } from "ga4";
interface ReplyComponentProps {
  post: Maybe<Post> | undefined;
  comment: Maybe<Comment> | undefined;
  reply: Maybe<Reply>;
  replyCountComponent?: React.ReactNode;
}

const ReplyComponent: FC<ReplyComponentProps> = ({
  post,
  comment,
  reply,
  replyCountComponent,
}) => {
  const onMentionedTagTypeClick = (tagType: TagType, paramsLower?: string) => {
    if (tagType === TagType?.UserTag) {
      sendEvent({
        event: "user_button_clicked",
        component_name: "reply_mention",
      });
    } else if (tagType === TagType?.HashTag) {
      sendEvent({
        event: "topic_button_clicked",
        component_name: "reply_mention",
      });
    } else if (
      tagType === TagType?.DollarTag &&
      paramsLower?.includes("stock/")
    ) {
      sendEvent({
        event: "stock_button_clicked",
        component_name: "reply_mention",
      });
    } else if (
      tagType === TagType?.DollarTag &&
      (paramsLower?.includes("sector/") || paramsLower?.includes("industry/"))
    ) {
      sendEvent({
        event: "sector_button_clicked",
        component_name: "reply_mention",
      });
    }
  };

  return (
    <Stack direction="row" spacing={1} sx={{ marginLeft: 1, marginBottom: 1 }}>
      <Link to={`/profile/${encodeURI(`${reply?.User?.username}`)}`}>
        <Avatar
          src={reply?.User?.avatar?.LowResUri}
          hasRing={getIsRingVisible(reply?.User?.membershipLevel)}
          alt="Avatar"
        />
      </Link>
      <CustomCard>
        <CommentHeader post={post} comment={comment} reply={reply} />
        <Body>
          <TextPost
            text={reply?.textV2}
            onMentionedTagTypeClick={onMentionedTagTypeClick}
          />
        </Body>
        {/* <ImageComment media={reply?.media} to={`/posts/${post?.postId}`} /> */}
        <ImageReplyNew media={reply?.media} />
        {replyCountComponent}
        <CommentBottomActions post={post} comment={comment} reply={reply} />
      </CustomCard>
    </Stack>
  );
};

export default ReplyComponent;

const Body = styled("div")`
  padding: 8px 12px 8px 12px;
`;

export const RepliesContainer = styled("div")`
  padding: 16px 8px 8px 16px;
`;

const Container = styled("div")`
  border: 1px solid rgba(0, 0, 0, 0.065);
  border-radius: 6px;
  background-color: #fff;
  padding: 8px 8px 4px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const CustomCard = styled(Card)`
  flex: 1;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

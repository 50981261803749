import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

export type CreatePostType = 'CREATE_FEED_POST' | 'CREATE_CLUB_POST';
export interface IPostContextState {
  open: boolean;
  clubId: string | undefined;
}

export const CreatePostContext = createContext<ICreatePostContext>({} as ICreatePostContext);

export const CreatePostProvider: FC<PropsWithChildren> = ({ children }) => {
  const [{ open, clubId }, setState] = useState<IPostContextState>({ open: false, clubId: undefined });

  const handleModalOpen = (clubId?: string | undefined) => {
    setState({ open: true, clubId });
  };
  const handleModalClose = () => setState({ open: false, clubId: undefined });

  return (
    <CreatePostContext.Provider value={{ open, clubId, handleModalOpen, handleModalClose }}>
      {children}
    </CreatePostContext.Provider>
  );
};

export const useCreatePostContext = () => useContext(CreatePostContext);

export default CreatePostContext;

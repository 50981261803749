import React, { FC, Fragment, useEffect, useState } from "react";
import { DialogTitle, Typography, IconButton, DialogActions, DialogContent, Divider } from "@mui/material";
import { Close } from "@mui/icons-material";
import styled from "@emotion/styled";
import Modal from "components/Modal";
import axios from 'axios';
import { POST_DISCLAIMER } from "config";
import { getReadableText } from "components/TextPost";
// store data here
const areaDataCache = {};

export const getDisclaimer = async () => {
    // if cache doesn't contain data
    if (!areaDataCache["disclaimer"]) {
        // load data and add it to cache
        const { data } = await axios.get(POST_DISCLAIMER);
        areaDataCache["disclaimer"] = data
    }
    // cached data
    return areaDataCache["disclaimer"];
};

const PostDisclaimer = () => {
    const [open, setOpen] = useState(false);
    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => setOpen(false);
    return (
        <Fragment>
            <Typography
                component="a"
                variant="caption"
                onClick={handleOpenModal}
                sx={{
                    marginLeft: 'auto',
                    fontSize: '0.725rem',
                    cursor: 'pointer'
                }}
            >Disclaimer</Typography>
            <Modal open={open} onClose={handleCloseModal}>
                <Disclaimer handleCloseModal={handleCloseModal} />
            </Modal>
        </Fragment>
    )
}

export default PostDisclaimer;

interface DisclaimerProps {
    handleCloseModal: () => void;
}

const Disclaimer: FC<DisclaimerProps> = ({ handleCloseModal }) => {
    const [disclaimerText, setDisclaimerText] = useState("");
    useEffect(() => {
        getDisclaimer().then(setDisclaimerText);
    }, [])
    return (
        <Fragment>
            <DialogTitle>
                <Typography variant="h3">Disclaimer</Typography>
                <IconButton onClick={handleCloseModal}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <DisclaimerText variant="body1">{getReadableText(disclaimerText || "")}</DisclaimerText>
            </DialogContent>
            <DialogActions />
        </Fragment>
    )
}

const DisclaimerText = styled(Typography)`
    white-space: pre-line;
`;
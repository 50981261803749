import React, { FC } from "react";
import {
  Stack,
  Button,
  Typography,
  CardActions,
  Skeleton,
} from "@mui/material";
import styled from "@emotion/styled";
import classNames from "classnames";
import {
  Maybe,
  Post,
  Comment,
  PlatformPermissionFromFeatureTypes,
  useGetFeatureToPlatformPermissionQuery,
  ClubPermissionsBasedOnMemberType,
  useToggleUpvoteCommentMutation,
  useToggleDownvoteCommentMutation,
} from "api";
import { useNavigate } from "react-router-dom";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import { useClubPermission } from "hooks";
import { usePermissionFeatureMapping } from "hooks";
import { sendEvent } from "ga4";

interface Props {
  post: Maybe<Post> | undefined;
  comment: Maybe<Comment>;
  isReplyExists?: boolean;
  handleShowInlineReply?: () => void;
  loading: boolean;
}

export const CommentBottomActions: FC<Props> = ({
  post,
  comment,
  isReplyExists,
  loading,
  handleShowInlineReply,
}) => {
  const navigate = useNavigate();
  const { handleModalOpen } = useUpgradePlanContext();
  const [toggleUpvote] = useToggleUpvoteCommentMutation();
  const [toggleDownvote] = useToggleDownvoteCommentMutation();
  const { platformFeatureMap, clubFeatureMap } = usePermissionFeatureMapping();

  const { data: platformPermission } = useGetFeatureToPlatformPermissionQuery();
  const { permissions, token } = useClubPermission(post?.Club?.clubId);

  const variables = {
    postId: post?.postId,
    commentId: comment?.commentId,
    clubId: post?.Club?.clubId,
    clubBasedPermissionToken: token,
  };
  const clubEngage = permissions?.includes(
    ClubPermissionsBasedOnMemberType.CanEngage
  );

  const handleCommentUpvote = () => {
    if (post?.Club) {
      if (!clubEngage) {
        sendEvent({
          event: "upgrade_modal_initialised",
          component_name: "post_details",
          feature_name_list: clubFeatureMap[post?.Club?.clubId as string],
        });
        handleModalOpen();
        return;
      }
    } else if (
      !platformPermission?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanEngage
      )
    ) {
      sendEvent({
        event: "upgrade_modal_initialised",
        component_name: "post_details",
        feature_name_list:
          platformFeatureMap[PlatformPermissionFromFeatureTypes.CanEngage],
      });
      handleModalOpen();
      return;
    }
    sendEvent({
      event: "user_engaged",
      icon_name: "upvote",
    });
    toggleUpvote({
      variables,
    });
  };

  const handleCommentDownvote = () => {
    if (post?.Club) {
      if (!clubEngage) {
        sendEvent({
          event: "upgrade_modal_initialised",
          component_name: "post_details",
          feature_name_list: clubFeatureMap[post?.Club?.clubId as string],
        });
        handleModalOpen();
        return;
      }
    } else if (
      !platformPermission?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanEngage
      )
    ) {
      sendEvent({
        event: "upgrade_modal_initialised",
        component_name: "post_details",
        feature_name_list:
          platformFeatureMap[PlatformPermissionFromFeatureTypes.CanEngage],
      });
      handleModalOpen();
      return;
    }
    sendEvent({
      event: "user_engaged",
      icon_name: "downvote",
    });
    toggleDownvote({
      variables,
    });
  };

  const handleComment: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (post?.Club) {
      if (!clubEngage) {
        sendEvent({
          event: "upgrade_modal_initialised",
          component_name: "post_details",
          feature_name_list: clubFeatureMap[post?.Club?.clubId as string],
        });
        handleModalOpen();
        return;
      }
    } else if (
      !platformPermission?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanEngage
      )
    ) {
      sendEvent({
        event: "upgrade_modal_initialised",
        component_name: "post_details",
        feature_name_list:
          platformFeatureMap[PlatformPermissionFromFeatureTypes.CanEngage],
      });
      handleModalOpen();
      return;
    }
    sendEvent({
      event: "user_engaged",
      icon_name: "reply",
    });
    if (handleShowInlineReply) {
      handleShowInlineReply();
    } else {
      navigate(`/posts/${post?.postId}`);
    }
  };

  const isShownPostBottom = Boolean(post?.Club) ? clubEngage : true;
  return (
    <CustomCardActions>
      {isShownPostBottom && loading ? (
        <CustomStack gap={2} direction="row" isReplyExists={true}>
          {Array.from(new Array(3))?.map((btn, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              sx={{ width: "25%", borderRadius: "5px", height: "25px" }}
            />
          ))}
        </CustomStack>
      ) : (
        <CustomStack direction="row" isReplyExists={!!isReplyExists}>
          <Button
            color="inherit"
            onClick={handleCommentUpvote}
            className={classNames({ active: comment?.isUpvoted })}
            fullWidth
          >
            <UpvoteIcon />
            {Boolean(comment?.upvotecount) && (
              <Typography variant="caption">{comment?.upvotecount}</Typography>
            )}
          </Button>
          <Button
            color="inherit"
            onClick={handleCommentDownvote}
            className={classNames({ active: comment?.isDownvoted })}
            fullWidth
          >
            <DownvoteIcon />
            {Boolean(comment?.downvotecount) && (
              <Typography variant="caption">
                {comment?.downvotecount}
              </Typography>
            )}
          </Button>
          <Button
            color="inherit"
            onClick={handleComment}
            disabled={post?.Club ? !clubEngage : false}
            fullWidth
          >
            <ReplyIcon />
            {Boolean(comment?.replycount) && (
              <Typography variant="caption">{comment?.replycount}</Typography>
            )}
          </Button>
        </CustomStack>
      )}
    </CustomCardActions>
  );
};

const UpvoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
  </svg>
);

const DownvoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17" />
  </svg>
);

const ReplyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="9 14 4 9 9 4"></polyline>
    <path d="M20 20v-7a4 4 0 0 0-4-4H4"></path>
  </svg>
);

const CustomCardActions = styled(CardActions)`
  padding-bottom: 4px;
  .MuiTypography-subtitle2 {
    margin-left: 8px;
    line-height: 1.5rem;
  }
`;

const CustomStack = styled(Stack)<{ isReplyExists: boolean }>`
  border-top: 1px solid ${({ theme }: any) => theme?.palette?.divider};
  flex: 1;
  padding-top: 4px;
  ${({ theme, isReplyExists }: any) =>
    isReplyExists &&
    `
        border-bottom: 1px solid ${theme?.palette?.divider};
        padding-bottom: 4px;
    `}

  .MuiButton-root {
    &.active {
      color: #2094dd;
    }
  }

  .MuiTypography-caption {
    font-size: 1rem;
    font-weight: bold;
    margin-left: 6px;
    color: inherit;
  }
`;

import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { isEmpty } from "lodash-es";
import { User,  UserProfileDetailsFromIdp } from "api";


interface IAuthContext {
  isAuth: boolean;
  user: User;
  profile: UserProfileDetailsFromIdp | undefined;
  setProfile: React.Dispatch<React.SetStateAction<UserProfileDetailsFromIdp | undefined>>;
  isViewOnly: boolean;
  setUserInfo: (user: User | null | undefined, isViewOnly: boolean) => void;
  updateUserInfo: (user: User) => void;
  removeUserInfo: () => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [profile, setProfile] = useState<UserProfileDetailsFromIdp>()
  const [data, setData] = useState<{ user: User, isViewOnly: boolean }>({ user: {}, isViewOnly: false });

  const setUserInfo = (user: any, isViewOnly: any) => {
    setData({ user, isViewOnly });
  };

  const updateUserInfo = (user: Partial<User>) => {
    setData(prev => ({ ...prev, user: { ...prev.user, ...user } }));
  }

  const removeUserInfo: RemoveUserInfo = () => {
    setData({ user: {}, isViewOnly: false });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth: !isEmpty(data?.user),
        ...data,
        profile,
        setProfile,
        setUserInfo,
        updateUserInfo,
        removeUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContext;

import React, { FC } from "react";
import styled from "@emotion/styled";
import ReactPlayer from "react-player/lazy";
import { Maybe, Post } from "api";
import { Link } from "react-router-dom";
import DisplayAudioPlayer from "./DisplayAudioPlayer";
import { isEmpty } from "lodash-es";
import { Portrait } from "@mui/icons-material";
import defaultCover from "../../assets/image/google.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

interface MediaPostProps {
  post: Maybe<Post> | undefined;
  isPostDetails: boolean;
}

const MediaPost: FC<MediaPostProps> = ({ post, isPostDetails = false }) => {
  const media = post?.media?.filter(
    (x) =>
      x?.type?.includes("video") ||
      x?.type?.includes("audio") ||
      x?.type?.includes("application")
  );
  let containerStyle: any = {};

  if (Number(post?.media?.length) >= 2 && !isPostDetails) {
    containerStyle = {
      gridTemplateColumns: "1fr 1fr",
      gridGap: 4,
      gridAutoRows: 320,
    };
  }

  if (isEmpty(media)) return null;
  return (
    <Container to={`/posts/${post?.postId}`} style={containerStyle}>
      {media?.map((mediaItem, key, arr) => {
        console.log("Media item", mediaItem);
        if (mediaItem?.type && new RegExp("video").test(mediaItem?.type)) {
          return (
            <>
              {" "}
              {!mediaItem?.uri?.ThumbnailUrl && (
                // <span
                //   style={{
                //     position: "absolute",
                //     left: "16rem",
                //     alignSelf: "center",
                //     justifySelf: "center",
                //   }}
                // >
                <PlayArrowIcon
                  sx={{
                    position: "absolute",
                    color: "white",
                    // top: "9rem",
                    fontSize: "3.1875rem",

                    top: 0,
                    bottom: 0,
                    margin: "auto",
                    left: 0,
                    right: 0,
                  }}
                />
                // </span>
              )}
              <ReactPlayer
                key={key}
                wrapper={VideoContainer}
                url={mediaItem?.uri?.showUri}
                light={mediaItem?.uri?.ThumbnailUrl}
              >
                {" "}
              </ReactPlayer>
            </>
          );
        }
        if (mediaItem?.type && new RegExp("audio").test(mediaItem?.type)) {
          return <DisplayAudioPlayer key={key} audio={mediaItem} />;
        }
        if (
          mediaItem?.type &&
          new RegExp("application").test(mediaItem?.type)
        ) {
          return (
            <DocContainerLink
              key={key}
              href={`${mediaItem.uri.showUri}`}
              target="_blank"
            >
              <DocImage src={mediaItem.uri.ThumbnailUrl} alt="doc" />
              <Pdf src={require("assets/image/pdf.webp")} alt="pdf-thumbnail" />
            </DocContainerLink>
          );
        }
        return null;
      })}
    </Container>
  );
};

export default MediaPost;

const Image = styled.img`
  width: 100%;
  max-height: 480px;
  object-fit: cover;
  object-position: center;
`;

const Container = styled(Link)`
  margin: 8px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5px;
  position: relative;
`;

const VideoContainer = styled.div`
  width: 100% !important;
  min-height: 320px !important;
  max-height: 540px !important;
  height: auto !important;
  background-color: #000000;
  .react-player__preview {
    background-repeat: no-repeat !important;
    background-size: contain !important;
  }
  video {
    width: 100% !important;
    background-color: #000000 !important;
    min-height: 320px !important;
    max-height: 540px !important;
    height: auto !important;
  }
`;
const DocContainerLink = styled.a`
  display: block;
  margin: 10px auto;
  position: relative;
`;

const DocImage = styled.img`
  height: auto;
  width: 100%;
  background-color: #fff;
`;

const Pdf = styled.img`
  height: 56px;
  width: auto;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

const Modal = styled(Dialog)`
    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.75);
    }
    .MuiPaper-root {
        width: 100%;
        max-width: 500px;
        margin: 0;
        max-height: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .MuiDialogContent-root {
        padding: 8px;
    }
    button.MuiIconButton-root {
        position: absolute;
        right: 4px;
        top: 4px;
        padding: 8px;
    }
    .MuiTypography-h3 {
        font-size: 1rem;
    }
    .MuiButtonGroup-root {
        .MuiButton-root {
            width: 100%;
            height: 44px;
            &.active {
                border-bottom-color: #1da1f3;
                color: #1da1f3;
            }
        }
    }
    
    @media (min-width: 540px) {
        .MuiPaper-root {
            height: 500px;
        }
        .MuiDialogContent-root {
            padding: 8px 16px;
        }
    }
`;

export default Modal;
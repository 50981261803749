import React, { useTour } from "@reactour/tour";
import { FC, useEffect } from "react";


export const TourInit: FC = () => {
    const { setIsOpen } = useTour();


    useEffect(() => {

        setTimeout(() => {
            setIsOpen(true);
        }, 1000);

    }, []);

    return null

}
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Divider, IconButton, ListItemIcon, ListItemText, ListSubheader, Typography } from '@mui/material';
import { useGetListOfClubsWithPostPermissionQuery } from "api";
import SharedOn from "./SharedOn";
import ClubMenuItem from "./ClubMenuItem";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        width: 240,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
    '& .MuiListItemText-root': {
        marginRight: 8
    },
    '& .MuiAvatar-root': {
        height: 30,
        width: 30,
        marginRight: 8
    },
    '& .MuiListItemText-primary': {

    }
}));

interface SharePostDropdownProps {
    clubId: string | undefined;
    setClubId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const SharePostDropdown: React.FC<SharePostDropdownProps> = ({ clubId, setClubId }) => {
    const { data } = useGetListOfClubsWithPostPermissionQuery({
        fetchPolicy: "cache-and-network"
    });
    const [openClubs, setOpenClubs] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const buttonTitle = data?.getListOfClubsWithPostPermission?.find(x => x?.clubId === clubId)?.clubName || "Feed";
    return (
        <>
            <SharedOn
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {buttonTitle}
            </SharedOn>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {!openClubs ? (
                    <>
                        <ListSubheader component="div" sx={{ lineHeight: "32px" }}>
                            Choose Audience
                        </ListSubheader>
                        <MenuItem onClick={() => {
                            setClubId(undefined);
                            handleClose();
                        }} disableRipple>
                            <ListItemIcon>
                                <Avatar>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                    </svg>
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText>Feed</ListItemText>
                            <Typography variant="body2" color="text.secondary">
                                {!clubId &&
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#007aff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>}
                            </Typography>
                        </MenuItem>
                        {Number(data?.getListOfClubsWithPostPermission?.length) > 0 && <MenuItem onClick={() => setOpenClubs(true)} disableRipple>
                            <ListItemIcon>
                                <Avatar>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="9" cy="7" r="4"></circle>
                                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                    </svg>
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText>Club</ListItemText>
                            <Typography variant="body2" color="text.secondary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </Typography>
                        </MenuItem>}
                    </>
                ) : (
                    <>
                        <ListSubheader component="div" sx={{ lineHeight: "32px", padding: "4px", display: "flex" }}>
                            <IconButton onClick={() => setOpenClubs(false)} sx={{ marginRight: "8px" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="19" y1="12" x2="5" y2="12"></line>
                                    <polyline points="12 19 5 12 12 5"></polyline>
                                </svg>
                            </IconButton> Clubs
                        </ListSubheader>
                        <Divider />
                        {data?.getListOfClubsWithPostPermission?.map(club => (
                            <ClubMenuItem
                                key={club?.clubId}
                                club={club}
                                isActive={clubId === club?.clubId}
                                onClick={() => setClubId(club?.clubId!)}
                            />
                        ))}
                    </>
                )}
            </StyledMenu>
        </>
    );
}

export default SharePostDropdown;
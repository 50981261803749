import React from "react";
import { Typography, Button, Box, styled } from "@mui/material";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import colors from "constants/colors";
import { useSessionStorage } from "react-use";
import { isMobileOnly } from "react-device-detect";
import { usePermissionFeatureMapping } from "hooks";
import { PlatformPermissionFromFeatureTypes } from "api";
import { sendEvent } from "ga4";

export const reference = React.createRef<HTMLDivElement>();

const UpgradeMessage = () => {
  const [open] = useSessionStorage<boolean>("use_app_popup", true);
  const { handleModalOpen } = useUpgradePlanContext();
  const { platformFeatureMap } = usePermissionFeatureMapping();

  const handleUpgrade = () => {
    
    sendEvent({
      event: "upgrade_modal_initialised",
      component_name: "marketing_post",
      feature_name_list: platformFeatureMap[PlatformPermissionFromFeatureTypes.CanFollow],
    })
    handleModalOpen();
  }

  const isDownloadPromptVisiable = isMobileOnly && open;
  return (
    <StickyBottomContainer ref={reference} className={isDownloadPromptVisiable ? "download-prompt-visible" : ""}>
      <BlurrContainer>
        <Content>
          <Text className="desktop">Unlock the full potential of StockEdge Social. Upgrade your plan to benefit from the power of this exclusive community</Text>
          <Text className="mobile">Upgrade plan for latest insights</Text>
          <Button
            sx={{
              fontWeight: "normal",
              marginTop: "2rem",
              backgroundColor: `${colors.buttonAppSyncColor}`,
              ":hover": {
                backgroundColor: `${colors.buttonAppSyncColor}`,
              },
            }}
            variant="contained"
            onClick={handleUpgrade}
          >
            <Text className="desktop">Upgrade My Plan</Text>
            <Text className="mobile">Upgrade</Text>
          </Button>
        </Content>
      </BlurrContainer>
    </StickyBottomContainer>
  );
};

export default UpgradeMessage;



const StickyBottomContainer = styled('div')`
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 100%;
  &.download-prompt-visible {
    bottom: 82px;
  }
  @media (min-width: 960px) {
    position: absolute;
    bottom: -80px;
  }
`;
const BlurrContainer = styled(Box)`
  backdrop-filter: blur(3px);
  border-bottom: 0.5px solid rgb(92, 112, 130);
  @media (min-width: 960px) {
    height: 250px;
    display: table-cell;
    vertical-align: middle;
    padding: 0 3rem;
  }
  @media (max-width: 330px) {
    background-color: #fff;
  }
`;

const Content = styled(Box)`
  padding: 20px;
  text-align: center;
`;

const Text = styled(Typography)({
  color: 'inherit',
  '&.desktop': {
    display: 'none'
  },
  '@media (min-width: 640px)': {
    '&.mobile': {
      display: 'none'
    },
    '&.desktop': {
      display: 'block'
    },
  }
})
import { Dialog, styled } from "@mui/material";

export const HiddenInput = styled("input")`
  display: none;
`;

export const Modal = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
  }
  .MuiPaper-root {
    overflow-y: initial;
  }
  .MuiDialog-container {
    align-items: flex-start;
  }
  .MuiDialog-paper {
    width: 100%;
    max-width: 640px;
    min-height: 480px;
    margin: auto;
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
  @media (max-width: 540px) {
    .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
  }
  @media (min-width: 481px) {
    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;

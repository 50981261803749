import React from "react";
import styled from "@emotion/styled";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard, Props } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useTheme } from "@emotion/react";
import { useGetReferMsgQuery, useGetMultipleExternalResourceQuery } from "api";
import { isEmpty } from "lodash-es";
import { Link, Stack, Typography, Tooltip, Skeleton } from "@mui/material";
import imgCopyLink from "assets/copy-link.webp";
import imgCopyWhiteLink from "assets/copy-link-w.webp";

const CustomCopyToClipboard =
  CopyToClipboard as unknown as React.ComponentClass<Props>;

const ICON_SIZE = 32;

const ReferYourFriend = () => {
  const theme: any = useTheme();
  const { data, loading } = useGetReferMsgQuery();

  const { data: dataExternal } = useGetMultipleExternalResourceQuery({
    variables: { type: ["REFER_T_&_C_LINK"] },
  });
  const externalResource: any = (
    dataExternal?.getMultipleExternalResource || []
  ).reduce((a, c) => ({ ...a, [`${c?.type}`]: c?.value }), {});
  // if (loading || isEmpty(data?.getReferMsg)) return null;
  return (
    <Stack direction="column" sx={{ padding: 1 }}>
      <Typography variant="caption">Share via:</Typography>
      <ReferIconsContainer>
        {loading ? (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"30px"}
            sx={{ borderRadius: "5px" }}
          />
        ) : (
          <>
            {" "}
            <Tooltip title="Facebook" placement="top">
              <FacebookShareButton
                url={data?.getReferMsg?.url || ""}
                title={data?.getReferMsg?.message || ""}
              >
                <FacebookIcon size={ICON_SIZE} />
              </FacebookShareButton>
            </Tooltip>
            <Tooltip title="LinkedIn" placement="top">
              <LinkedinShareButton
                url={data?.getReferMsg?.url || ""}
                title={data?.getReferMsg?.message || ""}
              >
                <LinkedinIcon size={ICON_SIZE} />
              </LinkedinShareButton>
            </Tooltip>
            <Tooltip title="Twitter" placement="top">
              <TwitterShareButton
                url={data?.getReferMsg?.url || ""}
                title={
                  data?.getReferMsg?.message?.replace(
                    `${data?.getReferMsg?.url}`,
                    ""
                  ) || ""
                }
              >
                <TwitterIcon size={ICON_SIZE} />
              </TwitterShareButton>
            </Tooltip>
            <Tooltip title="Email" placement="top">
              <EmailShareButton
                url={data?.getReferMsg?.url || ""}
                subject={data?.getReferMsg?.message || ""}
              >
                <EmailIcon size={ICON_SIZE} />
              </EmailShareButton>
            </Tooltip>
            <Tooltip title="Whatsapp" placement="top">
              <WhatsappShareButton
                url={data?.getReferMsg?.url || ""}
                title={
                  data?.getReferMsg?.message?.replace(
                    `${data?.getReferMsg?.url}`,
                    ""
                  ) || ""
                }
              >
                <WhatsappIcon size={ICON_SIZE} />
              </WhatsappShareButton>
            </Tooltip>
            <CustomCopyToClipboard
              text={data?.getReferMsg?.message || ""}
              onCopy={() => toast.success("Link copied!")}
            >
              <Tooltip title="Copy" placement="top">
                {theme.palette.mode === "dark" ? (
                  <Img src={imgCopyWhiteLink} height={32} alt="" />
                ) : (
                  <Img src={imgCopyLink} width={44} height={44} alt="" />
                )}

                {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width={ICON_SIZE}
              height={ICON_SIZE}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{cursor:"pointer"}}
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
            </svg> */}
              </Tooltip>
            </CustomCopyToClipboard>
          </>
        )}
      </ReferIconsContainer>
      {externalResource["REFER_T_&_C_LINK"] && (
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Link
            sx={{ fontSize: "12px" }}
            target="_blank"
            href={externalResource["REFER_T_&_C_LINK"]}
          >
            Terms & Conditions
          </Link>
        </Stack>
      )}
    </Stack>
  );
};

export default ReferYourFriend;

const ReferIconsContainer = styled.div`
  align-items: center;
  justify-content: space-around;
  display: flex;
  padding: 10px 5px 5px;
`;

const Img = styled.img`
  cursor: pointer;
  margin-top: -10px;
`;


import { FC, Suspense, lazy, useMemo } from "react";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import HelmetWithNotificationCount from "components/HelmetWithNotificationCount";
import { PageLoading } from "components/PageLoading";
import FilterContainer from "./FilterContainer";


const Posts = lazy(() => import("./Posts"));



const FilterPosts: FC = () => {

  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  
  const hashTagId = searchParams.get('hashTagId');
  const userId = searchParams.get('userId');
  const dollarId = searchParams.get('dollarId');
  const clubId = searchParams.get('clubId');
  
  console.log("query",hashTagId,userId,dollarId,clubId);

  console.log("searchParams",searchParams);
  
  
  return (
    <Container>
      <HelmetWithNotificationCount title={`FilterPosts | StockEdge Social`} />
      <FilterContainer
         hashTagId={hashTagId ? hashTagId : ""}
         userId={userId ? userId : ""}
         dollarId={dollarId ? parseInt(dollarId) : undefined}
         clubId={clubId ? clubId : ""}
      />
      <Posts
        hashTagId={hashTagId ? hashTagId : ""}
        userId={userId ? userId : ""}
        dollarId={dollarId ? parseInt(dollarId) : undefined}
        clubId={clubId ? clubId : ""}
      />
      <Suspense fallback={<PageLoading />}>

      </Suspense>
    </Container>
  );
};

export default FilterPosts;

const Container = styled.div`
  max-width: 540px;
  margin: auto;
`;

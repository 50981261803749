import { Button, IconButton, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Poll } from "./CreatePoll";
import { ModalType } from "./PostCreator";

export const DisplayPoll: FC<{
    poll: Poll;
    setModal: React.Dispatch<React.SetStateAction<ModalType>>;
    setPoll: React.Dispatch<React.SetStateAction<Poll | undefined>>;
}> = ({ poll, setModal, setPoll }) => {
    return (
        <Container variant="outlined">
            <IconButton style={{ right: 44 }} onClick={() => setModal("CREATE_A_POLL")}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => setPoll(undefined)}>
                <DeleteIcon />
            </IconButton>
            <Typography variant="body1">{poll?.question}</Typography>
            {poll?.options?.map(option => <Option key={option} variant="outlined" disabled>{option}</Option>)}
            <Typography variant="caption">
                Duration: {durationMap[poll?.pollDuration || ""] || ""}
            </Typography>
        </Container>
    )
}

const durationMap = {
    oneDay: "1 day",
    threeDay: "3 days",
    oneWeek: "1 week",
    threeWeek: "3 weeks"
}

const Duration = styled.span``;

const Container = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 36px 10px 10px 10px;
    position: relative;
    margin-top: 16px;

    .MuiIconButton-root {
        position: absolute;
        padding: 8px;
        top: 4px;
        right: 4px;
        background-color: #2196f3;
        color: #fff;

        svg {
            width: 1.125rem;
            height: 1.125rem;
        }
    }
`;

// const Question = styled.p`
//     font-size: 1rem;
//     line-height: 1.24rem;
//     margin-bottom: 10px;
// `;

const Option = styled(Button)`
    margin: 4px 0;
`;





import React, { FC, Fragment } from "react";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    Button,
    TextField,
    TextFieldProps,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    styled
} from "@mui/material";
import {
    Close as CloseIcon,
    Add as AddIcon
} from "@mui/icons-material";
import TrashIcon from "../../assets/icons/svg/trash_20.svg";
import { Maybe, PollExpiryEnum } from "api";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { ModalType } from "./PostCreator";

export interface Option {
    id: number;
    text: string;
    required: boolean;
}

export interface Poll {
    question: string;
    options: Array<string>,
    pollDuration: Maybe<PollExpiryEnum> | undefined;
}

export const initOptions: Array<Option> = [{
    id: 1,
    text: "",
    required: true
}, {
    id: 2,
    text: "",
    required: true
}];

export const CreatePoll: FC<{
    poll: Poll
    handleClose: () => void;
    setModal: React.Dispatch<React.SetStateAction<ModalType>>;
    setPoll: React.Dispatch<React.SetStateAction<Poll | undefined>>;
}> = ({ poll, handleClose, setModal, setPoll }) => (
    <Fragment>
        <Header>
            <Title>Create a Poll</Title>
            <CloseButton onClick={() => handleClose()}>
                <CloseIcon />
            </CloseButton>
        </Header>
        <Formik
            initialValues={poll}
            enableReinitialize
            onSubmit={(values) => {
                values.question = values.question.trim();
                values.options = values.options.map(x => x.trim());
                setPoll(values);
                setModal("TEXT_POST")
            }}
            validationSchema={PollSchema}
        >
            {({
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                values,
                errors,
                touched
            }) => (
                <Form onSubmit={handleSubmit} noValidate>
                    <Body dividers>
                        <InputContainer>
                            <Input
                                label="Your Question*"
                                multiline
                                minRows={2}
                                value={values.question}
                                name="question"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.question && errors.question)}
                                helperText={touched.question ? errors.question : ''}
                            />
                        </InputContainer>
                        <FieldArray
                            name='options'
                            render={(arrayHelpers) => (
                                <Fragment>
                                    {values.options.map((option, key) => (
                                        <InputContainer key={key}>
                                            <Input
                                                label={`Option ${key + 1}${key < 2 ? "*" : ""}`}
                                                value={option}
                                                name={`options[${key}]`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={Boolean(touched?.options?.[key] && errors?.options?.[key])}
                                                helperText={touched?.options?.[key] ? errors?.options?.[key] : ''}
                                            />
                                            {key >= 2 && <RemoveButton onClick={() => arrayHelpers.remove(key)}><TrashIcon /></RemoveButton>}
                                        </InputContainer>
                                    ))}
                                    {values.options.length <= 3 && <AddButton
                                        variant="contained"
                                        onClick={() => arrayHelpers.push('')}
                                        startIcon={<AddIcon />}
                                    >Add option</AddButton>}
                                </Fragment>
                            )}
                        />
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="poll-duration">Poll duration</InputLabel>
                            <Select
                                labelId="poll-duration"
                                id="poll-duration"
                                label="Poll duration"
                                name="pollDuration"
                                value={values.pollDuration}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <MenuItem value={PollExpiryEnum.OneDay}>1 day</MenuItem>
                                <MenuItem value={PollExpiryEnum.ThreeDay}>3 days</MenuItem>
                                <MenuItem value={PollExpiryEnum.OneWeek}>1 week</MenuItem>
                                <MenuItem value={PollExpiryEnum.ThreeWeek}>3 weeks</MenuItem>
                            </Select>
                        </FormControl>
                    </Body>
                    <Footer>
                        <BackButton variant="contained" onClick={() => setModal("TEXT_POST")}>Back</BackButton>
                        <SaveButton variant="contained" disabled={isSubmitting} type="submit">Save</SaveButton>
                    </Footer>
                </Form>
            )}
        </Formik>
    </Fragment>
)

const Input: FC<TextFieldProps> = (props) => <TextField fullWidth variant="outlined" size="small" {...props} />

const PollSchema = Yup.object().shape({
    question: Yup.string()
        .trim("Question must be at least 1 character")
        .min(1, "Question must be at least 1 character")
        .max(240, "Question must be at most 240 characters")
        .required('Question is required'),
    options: Yup.array().of(Yup.string()
        .trim("Option must be at least 1 character")
        .min(1, "Option must be at least 1 character")
        .max(30, "Option must be at most 30 characters")
        .required('Option is required'))
});

const InputContainer = styled("div")`
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    &:first-child {
        margin-bottom: 36px;
    }
`;

const Header = styled(DialogTitle)`
    && {
        border: 0 !important;
    }
`;
const Body = styled(DialogContent)`
    && {
        padding: 16px !important;
        border-top: 1px solid rgba(0, 0, 0, 0.075);
        border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    }
`;
const Footer = styled(DialogActions)`
    && {
        justify-content: end !important;
    }
`;
const Title = styled(Typography)``;
const CloseButton = styled(IconButton)``;
const BackButton = styled(Button)`
    align-items: center;
    border-radius: 4px;
    line-height: 1;
    height: 32px;
    font-weight: 500;
    && {
        margin: 0 10px 0 0 !important;
    }
`;
const SaveButton = styled(Button)`
    background-color: #1da1f3;
    color:#fff;
    align-items: center;
    border-radius: 4px;
    line-height: 1;
    height: 32px;
    font-weight: 500;
    && {
        margin: 0 !important;
    }
    &:hover{
        background-color: #1da1f3;
    }
`;

const RemoveButton = styled(Button)`
    && {
        height: 40px !important;
        width: 40px !important;
        margin-left: 4px;
    }
`;
const AddButton = styled(Button)`
    background-color: #1da1f3;
    color:#fff;
    align-items: center;
    border-radius: 4px;
    line-height: 1;
    height: 32px;
    font-weight: 500;
    && {
        margin-bottom: 36px !important;
    }
    &:hover{
        background-color: #1da1f3;
    }
`;

import * as React from 'react';
import { MenuItem, MenuItemProps } from '@mui/material';
import { Avatar, ListItemIcon, ListItemText, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Club, Maybe } from 'api';


interface ClubMenuItemProps extends MenuItemProps {
    isActive: boolean | undefined;
    club: Maybe<Club> | undefined;
}

const ClubMenuItem: React.FC<ClubMenuItemProps> = ({ club, isActive, ...props }) => {
    return (
        <CustomMenuItem disableRipple {...props}>
            <ListItemIcon>
                <Avatar src={club?.avatar?.HighResUri} alt="logo" />
            </ListItemIcon>
            <ListItemText
                primary={club?.clubName}
                // secondary={`@${club?.clubHandle}`}
            />
            <Typography variant="body2" color="text.secondary">
                {isActive && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#007aff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="20 6 9 17 4 12"></polyline>
                </svg>}
            </Typography>
        </CustomMenuItem>
    );
}

export default ClubMenuItem;

const CustomMenuItem = styled(MenuItem)`
    && {
        padding: 2px;
        .MuiListItemText-primary {
            font-size: 12px;
        }
        .MuiListItemText-secondary {
            font-size: 12px;
        }
    }
`;
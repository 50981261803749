import { Button, Paper, Typography } from "@mui/material";
import React, { FC } from "react";
import styled from "@emotion/styled";
import PollExpired from "components/PollExpired";

import { Poll, Maybe } from "api";
import { timeAgo } from "utills";

export const DisplayPoll: FC<{
    poll: Maybe<Poll>;
}> = ({ poll }) => {
    const votes = Number(poll?.votes) > 1 ? `${poll?.votes} votes` : `${poll?.votes} vote`;
    return (
        <Container variant="outlined">
            <Typography variant="body1">{poll?.question}</Typography>
            {poll?.options?.map(option => <Option key={option?.optionId} variant="outlined" disabled>{option?.optionText}</Option>)}
            <CountContainer>
                <VotesButton disabled>{votes}</VotesButton>•
                {poll?.hasExpired ? <PollExpired /> : <span>{timeAgo(new Date(poll?.expiryDate))} left</span>}
            </CountContainer>
        </Container>
    )
}

const durationMap = {
    oneDay: "1 day",
    threeDay: "3 days",
    oneWeek: "1 week",
    threeWeek: "3 weeks"
}

const CountContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: 600;

    span {
        &:first-child {
            margin-right: 5px;
        }
        &:last-child {
            margin-left: 5px;
        }
    }
`;
const VotesButton = styled(Button)`
    && {
        height: 24px;
        margin-right: 2px;
        .MuiButton-label {
            font-size: 12px;
            font-weight: bold;
            margin: 0;
        }
    }
`;

const Duration = styled.span``;

const Container = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 10px;
    position: relative;
    margin-top: 16px;

    .MuiIconButton-root {
        position: absolute;
        padding: 8px;
        top: 4px;
        right: 4px;
        background-color: #2196f3;
        color: #fff;

        svg {
            width: 1.125rem;
            height: 1.125rem;
        }
    }
`;

// const Question = styled.p`
//     font-size: 1rem;
//     line-height: 1.24rem;
//     margin-bottom: 10px;
// `;

const Option = styled(Button)`
    margin: 4px 0;
`;





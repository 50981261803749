import { ArrowBack } from "@mui/icons-material";
import {
  Avatar,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemProps,
  ListSubheader,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import React, { FC, Fragment } from "react";
import { ModalType } from "./PostCreator";
import {
  Club,
  PlatformPermissionFromFeatureTypes,
  useGetFeatureToPlatformPermissionQuery,
  useGetListOfClubsWithPostPermissionQuery,
} from "api";
import { isEmpty } from "lodash-es";
import { useUpgradePlanContext } from "context/UpgradePlan/UpgradePlanContext";
import { usePermissionFeatureMapping } from "hooks";
import { sendEvent } from "ga4";
import { isDarkMode } from "utills";
interface ChooseAudienceProps {
  audience: Club | undefined;
  setModal: React.Dispatch<React.SetStateAction<ModalType>>;
  setAudience: React.Dispatch<React.SetStateAction<Club | undefined>>;
}

const ChooseAudience: FC<ChooseAudienceProps> = ({
  audience,
  setModal,
  setAudience,
}) => {
  const { platformFeatureMap, clubFeatureMap } = usePermissionFeatureMapping();
  const { handleModalOpen } = useUpgradePlanContext();
  const { data, loading } = useGetListOfClubsWithPostPermissionQuery({
    fetchPolicy: "cache-and-network",
  });
  const { data: permission } = useGetFeatureToPlatformPermissionQuery();
  const handleBackButton = () => {
    setModal("TEXT_POST");
  };

  const handleSetAudience = (audience: Club | undefined) => {
    setAudience(audience);
    setModal("TEXT_POST");
  };

  const canPostInFeed = permission?.getFeatureToPlatformPermission?.includes(
    PlatformPermissionFromFeatureTypes?.CanPost
  );

  return (
    <Fragment>
      {loading && (
        <Loader>
          <CircularProgress />
        </Loader>
      )}
      <DialogTitle sx={{ justifyContent: "center !important" }}>
        <IconButton
          onClick={handleBackButton}
          sx={{ position: "absolute", left: 8 }}
        >
          <ArrowBack />
        </IconButton>
        Select Audience
      </DialogTitle>
      <DialogContent dividers>
        <List disablePadding>
          <ListItem
            dense
            secondaryAction={
              !audience && canPostInFeed ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#007aff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              ) : null
            }
            sx={{ paddingLeft: 0 }}
          >
            <ListItemButton
              dense
              onClick={() => {
                if (!canPostInFeed) {
                  sendEvent({
                    event: "upgrade_modal_initialised",
                    component_name: "choose_audience",
                    feature_name_list:
                      platformFeatureMap[
                        PlatformPermissionFromFeatureTypes.CanPost
                      ],
                  });
                  handleModalOpen();
                } else {
                  handleSetAudience(undefined);
                }
              }}
            >
              <ListItemIcon>
                <Avatar alt="logo" sx={{ height: 32, width: 32 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </Avatar>
              </ListItemIcon>
              <ListItemText primary="Feed" />
            </ListItemButton>
          </ListItem>
          <StickyListItem label="Clubs" />
          {data?.getListOfClubsWithPostPermission?.map((club) => (
            <ListItem
              dense
              key={club?.clubId}
              secondaryAction={
                audience?.clubId === club?.clubId ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#007aff"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                ) : null
              }
              sx={{ paddingLeft: 0 }}
            >
              <ListItemButton
                dense
                onClick={() => handleSetAudience(club as Club)}
              >
                <ListItemIcon>
                  <Avatar
                    src={club?.avatar?.HighResUri}
                    alt="logo"
                    sx={{ height: 32, width: 32 }}
                  />
                </ListItemIcon>
                <ListItemText primary={club?.clubName} />
              </ListItemButton>
            </ListItem>
          ))}
          {!loading && isEmpty(data?.getListOfClubsWithPostPermission) ? (
            <Typography sx={{ fontSize: 12, textAlign: "center" }}>
              Join a club to post in it
            </Typography>
          ) : null}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleBackButton} disableElevation>
          Continue
        </Button>
      </DialogActions>
    </Fragment>
  );
};

export default ChooseAudience;

const Loader = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  backdrop-filter: blur(0.25px);
  border-radius: 4px;
  .MuiCircularProgress-colorPrimary {
    color: var(--button-color);
    opacity: 0.65;
  }
`;

const StickyListItem = styled(
  ({ label, ...props }: ListItemProps & { label?: React.ReactNode }) => (
    <ListItem {...props}>
      <ListItemText secondary={label} />
    </ListItem>
  )
)<ListItemProps>(({ theme }) => ({
  position: "sticky",
  top: -12,
  zIndex: 99,
  backgroundColor: isDarkMode(theme) ? "#000" : "#fff",
}));

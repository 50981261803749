import { Colors } from "./common.theme";

export const colors1 = {
  body: {
    background: "#000000",
    color: "#f1f2f3",
  },
  backgroundColor: {
    body: "#16181c",
    header: "#4c4c4c",
    post: "#16181c",
    comment: "#0d0f1380",
    reply: "#16181c",
  },
  fontColor: {
    title: "#F1F2F3",
    subtitle: "#bdbdbd",
    link: "#1976d2",
    buttonPrimary: "#1da1f3",
    buttonSecondary: "#fafafa",
    dollar: "#1f4386",
    hashtag: "#1b95e0",
    user: "#1b95e0",
  },
  // backgroundColor: "#000000",
  headerColor: "#16181c",
  cardColor: "#16181c",
  postCardColor: "#16181c",
  commentCardColor: "#3E4043",
  replyCardColor: "#16181c",
  // fontColor: "#F1F2F3",
  subFontColor: "#bdbdbd",
};

export const colors: Colors | any = {
  background: {
    default: "#16181c",
    card: "#000000",
    header: "#000000",
    disableButton: "#16181c",
    transparent: "rgba(255, 255, 255, 0.024)",
    mention: "#000",
    selectedpoll: "rgb(12 50 93 / 75%)",
  },
  color: {
    default: "#f1f2f3",
    title: "#F1F2F3",
    subtitle: "#bdbdbd",
  },
  mention: {
    background: "#000",
    border: "1px solid rgba(255, 255, 255, 0.12)",
    focusColor: "#2196f3",
  },
  poll: {
    background: "rgba(255, 255, 255, 0.072)",
  },
  // selectedPoll: {
  //   background: "red",
  // },
};

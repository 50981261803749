import React, { FC, useEffect, memo } from "react";
import { useAddNotificationTokenMutation, DeviceType } from "api";
import firebase from "@firebase/app";
import "@firebase/messaging";
import { FIREBASE_API_KEY, FIREBASE_APP_ID, FIREBASE_AUTH_DOMAIN, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET, FIREBASE_PUBLIC_VAPID_KEY } from "config";
//Live
// const firebaseConfig = {
//   apiKey: "AIzaSyDX8jdTU_j35Zx3sB6V86Uq7nM4Wx9J7zM",
//   authDomain: "sesocial-e6cb5.firebaseapp.com",
//   databaseURL: "https://sesocial-e6cb5.firebaseio.com",
//   projectId: "sesocial-e6cb5",
//   storageBucket: "sesocial-e6cb5.appspot.com",
//   messagingSenderId: "446186780652",
//   appId: "1:446186780652:web:b8329a3c78ab01646772c9",
//   // measurementId: "G-9DMTFKME23",
// };

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let messaging: any = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(FIREBASE_PUBLIC_VAPID_KEY);
}

const MainRoute: FC = () => {
  console.log("PushNotificationWeb");
  const [addNotificationToken] = useAddNotificationTokenMutation();

  const deleteToken = () => {
    // Delete Instance ID token.
    // [START delete_token]
    messaging
      ?.getToken()
      .then((currentToken: any) => {
        messaging
          .deleteToken(currentToken)
          .then(() => {
            console.log("Token deleted.");
          })
          .catch((err: Error) => {
            console.log("Unable to delete token. ", err);
          });
        // [END delete_token]
      })
      .catch((err: Error) => {
        console.log("Error retrieving Instance ID token. ", err);
      });
  };

  // const sendTokenToServer = (token: any) => {
  //     // send token to the server to subscribe device
  //     axios.post('http://localhost:8080/token', { token })
  //         .then(res => console.log(res))
  //         .catch(err => console.error(err));
  // }
  const sendTokenToServer = (notificationToken: any) => {
    // send token to the server to subscribe device
    console.log("sendTokenToServer");
    addNotificationToken({
      variables: {
        notificationToken,
        deviceType: isMobile.any() ? DeviceType.Mobileweb : DeviceType.Web,
      },
    });
  };

  useEffect(() => {
    // deleteToken();
    /**
     * Check Notification permission is allow or not
     */
    if (Notification.permission !== "granted") {
      // [START request for notification allow]
      messaging
        ?.requestPermission()
        .then(() => messaging.getToken())
        .then((token: any) => {
          console.log(token);
          sendTokenToServer(token);
        })
        .catch((err: any) => {
          console.error(err);
        });
      // [END request for notification allow]

      // [START refresh_token]
      // Callback fired if Instance ID token is updated.
      messaging?.onTokenRefresh(() => {
        messaging?.getToken()
          .then((refreshedToken: any) => {
            console.log("Token refreshed.");
            // Send Instance ID token to app server.
            sendTokenToServer(refreshedToken);
          })
          .catch((err: any) => {
            console.log("Unable to retrieve refreshed token ", err);
          });
      });
      // [END refresh_token]
    } else {
      console.log("allow notification");
    }

    messaging?.onMessage(function (payload: any) {
      console.log("onMessage", payload);
      // Boolean(payload?.notification?.title) &&
      //   toast.info(payload?.notification?.title, {
      //     position: 'bottom-left',
      //     autoClose: 3000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
    });
  }, []);

  // const notifyMe = () => {
  //     // Let's check if the browser supports notifications
  //     if (!("Notification" in window)) {
  //         console.log("This browser does not support desktop notification");
  //     }

  //     // Let's check whether notification permissions have alredy been granted
  //     else if (Notification.permission === "granted") {
  //         // If it's okay let's create a notification
  //         const options = {
  //             body: "enter_message_here",
  //             image: "https://earthsky.org/upl/2018/06/ocean-apr27-2020-Cidy-Chai-North-Pacific-scaled-e1591491800783.jpeg",
  //             icon: "https://image.shutterstock.com/image-vector/christmas-bells-church-bell-school-260nw-1050018878.jpg"
  //         }
  //         var notification = new Notification("Hi there!", options);
  //         notification.onclick = function (event) {
  //             event.preventDefault(); // prevent the browser from focusing the Notification's tab
  //             window.open('http://localhost:19006/editprofile/General', '_blank');
  //         }
  //     }

  //     // Otherwise, we need to ask the user for permission
  //     else if (Notification.permission === "default" || Notification.permission !== 'denied') {
  //         Notification.requestPermission(function (permission) {
  //             // If the user accepts, let's create a notification
  //             if (permission === "granted") {
  //                 var notification = new Notification("Hi there!");
  //             }
  //         });
  //     }

  //     // At last, if the user has denied notifications, and you
  //     // want to be respectful there is no need to bother them any more.
  // }
  // return (
  //     <button onClick={notifyMe}>Notifi Me</button>
  // )

  return null;
};

export default memo(MainRoute);

export const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  Safari() {
    return navigator.userAgent.match(/Safari/i);
  },
  any() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows() ||
      isMobile.Safari()
    );
  },
};

import React, { FC } from "react";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material";

export const FacebookButton: FC<ButtonProps> = (props) => (
    <Button
        startIcon={<Image src={require("assets/image/facebook.webp")} alt="Facebook Login Button" />}
        bgcolor="#4367b3"
        {...props}
    >Login with Facebook</Button>
)


export const StockedgeButton: FC<ButtonProps> = (props) => (
    <Button
        startIcon={<Image src="https://duzycfafl38re.cloudfront.net/Media/logo17092020142853.png" alt="StockEdge Login Button" />}
        bgcolor="#1d458a"
        {...props}
    >Login with StockEdge</Button>
)

const Image = styled("img")`
    --size: 32px;
    height: var(--size);
    width: var(--size);
    object-fit: contain;
    object-position: center;
`;

const Button = styled(MuiButton) <{ bgcolor?: string }>`
    && {
        display: flex;
        width: 100%;
        background-color: ${({ bgcolor }) => bgcolor ?? "#1d458a"};
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        min-height: 48px;
        justify-content: flex-start;
        padding: 0 2rem;
        margin: 0.5rem 0;
        &:last-child {
            margin-top: 0;
        } 
        &:first-child {
            margin-bottom: 0;
        }
        &:hover {
            background-color: ${({ bgcolor }) => bgcolor ?? "#1d458a"};
        }
    }
`;

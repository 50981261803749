import React, { FC } from "react";
import styled from "@emotion/styled";
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Maybe, Media } from "api";
import { DEFAULT_AVATAR } from "config";

interface AvatarImageProps {
    media?: Maybe<Maybe<Media>[]> | undefined;
}
const RedirectLazyLoadImage =
  LazyLoadImage as unknown as React.ComponentClass<LazyLoadImageProps>;
  
  
const AvatarImage: FC<AvatarImageProps> = ({ media }) => {
    if(!media || !media?.length) return null;
    return (
        <Container>
            <LazyAvatar
                src={media?.[0]?.uri?.HighResUri}
                height={240}
                width={240}
                alt="Avatar Post"
                placeholderSrc={DEFAULT_AVATAR}
                wrapperClassName="Post-avatar"
            />
        </Container>
    )
}

export default AvatarImage;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
`;

const LazyAvatar =  styled(RedirectLazyLoadImage)`
    border-radius: 100%;
    overflow: hidden;
`;
import { createContext } from "react";

export type ModalType =
    | "DISCUSSION"
    | "UPVOTING"
    | "DOWNVOTING"
    | "UPVOTING_COMMENT"
    | "DOWNVOTING_COMMENT"
    | "UPVOTING_REPLY"
    | "DOWNVOTING_REPLY"
    | "COMMENTS"
    | "REPLY"
    | "SHARE"
    | "BOOKMARK"
    | "FOLLOW"
    | "REPORT"
    | "BLOCK"
    | "JOIN"
    | "REPORT_COMMENT"
    | "REPORT_REPLY"
    | undefined;

type HandleOpenModal = (modal?: ModalType) => void;

export interface ISubscriptionModal {
    open: boolean;
    modal: ModalType;
    handleOpenModal: HandleOpenModal;
    handleCloseModal: VoidFunction;
}

export default createContext<ISubscriptionModal>({} as ISubscriptionModal);
import { FC, useEffect } from "react";
import {
  sendEvent,
  PostImpressionComponentName,
  PostImpressionTickStatus,
} from "ga4";
import { Maybe, User } from "api";

const POST_IMPRESSION_TIME = 5 * 1000;

export const getUserTickStatus = (
  user: Maybe<User> | undefined
): PostImpressionTickStatus => {
  if (user?.isVerified) return "blue_tick";
  if (user?.greenTick) return "green_tick";
  if (user?.tick3) return "golden_tick";
  return "no_tick";
};

const PostImpressionEventCall: FC<{
  postId: Maybe<string> | undefined;
  component_name: PostImpressionComponentName;
  tick_status: PostImpressionTickStatus;
}> = ({ postId, component_name, tick_status }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (postId) {
        sendEvent({
          event: "post_impression",
          postId,
          component_name,
          tick_status,
        });
      }
    }, POST_IMPRESSION_TIME);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return null;
};

export default PostImpressionEventCall;

import React, { FC } from "react";
import ReactGA from "react-ga4";
import { Maybe, Post, useFollowPostMutation, useUnfollowPostMutation } from "api";
import { IconButton } from "@mui/material";
import toast from 'react-hot-toast';
import { POST_NOTIFICATION_ENABLED, POST_NOTIFICATION_DISABLED, getEventScreen, getTypeOfExpert } from "utills/events";
import { useAuthContext } from "context/AuthContext";
interface FollowPostProps {
    post: Maybe<Post> | undefined
}

const FollowPost: FC<FollowPostProps> = ({ post }) => {
    const { user } = useAuthContext();
    const [followPost, { loading: followLoading }] = useFollowPostMutation();
    const [unfollowPost, { loading: unfollowLoading }] = useUnfollowPostMutation();

    const handleTurnOnNotifications = () => {
        ReactGA.event(POST_NOTIFICATION_ENABLED, {
            event_screen: getEventScreen()
        });
        followPost({
            variables: {
                data: { postId: post?.postId }
            },
            update: (cache, { data }) => {
                if (data?.followPost) {
                    cache.modify({
                        fields: {
                            isPostFollowed: (refrence, { storeFieldName }) => {
                                console.log(storeFieldName, refrence);
                                if (storeFieldName === `isPostFollowed({"postId":"${post?.postId}"})`) {
                                    return true;
                                }
                                return refrence;
                            }
                        }
                    })
                }

                if (data?.followPost?.message) {
                    toast.success(data?.followPost?.message)
                }
            }
        })
    }

    const handleTurnOffNotifications = () => {
        ReactGA.event(POST_NOTIFICATION_DISABLED, {
            event_screen: getEventScreen()
        });
        unfollowPost({
            variables: {
                data: { postId: post?.postId }
            },
            update: (cache, { data }) => {
                if (data?.unfollowPost) {
                    cache.modify({
                        fields: {
                            isPostFollowed: (refrence, { storeFieldName }) => {
                                console.log(storeFieldName, refrence);
                                if (storeFieldName === `isPostFollowed({"postId":"${post?.postId}"})`) {
                                    return false;
                                }
                                return refrence;
                            }
                        }
                    })
                }
                if (data?.unfollowPost?.message) {
                    toast.success(data?.unfollowPost?.message)
                }
            }
        })
    }

    if (post?.isFollowed)
        return (
            <IconButton title="Turn off notification" disabled={followLoading || unfollowLoading} onClick={handleTurnOffNotifications} data-tut="reactour__follow_post">
                <svg width="16" height="16" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.582 7.96916L11.4737 3.98149C11.1488 2.81327 10.4428 1.78716 9.46778 1.06626C8.4928 0.345371 7.30482 -0.0289346 6.09269 0.00284546C4.88055 0.0346255 3.71382 0.470667 2.77795 1.24166C1.84208 2.01265 1.19079 3.07435 0.927583 4.25799L0.0694995 8.11674C-0.0252832 8.54312 -0.0230918 8.98534 0.075912 9.41076C0.174916 9.83618 0.368204 10.2339 0.641506 10.5746C0.914808 10.9154 1.26114 11.1903 1.65495 11.3793C2.04875 11.5682 2.47996 11.6663 2.91675 11.6663H3.56658C3.70047 12.3257 4.05818 12.9184 4.5791 13.3442C5.10002 13.77 5.75213 14.0026 6.42492 14.0026C7.09771 14.0026 7.74981 13.77 8.27073 13.3442C8.79166 12.9184 9.14937 12.3257 9.28325 11.6663H9.77208C10.2217 11.6664 10.6653 11.5624 11.0681 11.3627C11.4709 11.1629 11.8221 10.8727 12.0942 10.5147C12.3663 10.1568 12.552 9.74077 12.6367 9.29918C12.7214 8.85759 12.7023 8.4024 12.582 7.96916ZM6.42492 12.833C6.06426 12.8315 5.71288 12.7186 5.41884 12.5098C5.1248 12.3009 4.90247 12.0064 4.78225 11.6663H8.06758C7.94737 12.0064 7.72503 12.3009 7.43099 12.5098C7.13695 12.7186 6.78557 12.8315 6.42492 12.833ZM11.1651 9.80841C11.0025 10.024 10.7919 10.1988 10.55 10.3188C10.3081 10.4388 10.0415 10.5007 9.7715 10.4997H2.91675C2.65471 10.4996 2.39602 10.4407 2.15978 10.3273C1.92354 10.2139 1.71579 10.0489 1.55184 9.84451C1.3879 9.64009 1.27196 9.40146 1.21258 9.14623C1.15319 8.89101 1.15189 8.6257 1.20875 8.36991L2.06625 4.51057C2.27296 3.58086 2.78451 2.74693 3.5196 2.14134C4.25469 1.53575 5.17111 1.19326 6.1232 1.16831C7.07529 1.14337 8.00839 1.43741 8.77418 2.00368C9.53996 2.56995 10.0945 3.37596 10.3496 4.29357L11.4579 8.28124C11.5312 8.54104 11.5428 8.81434 11.492 9.07943C11.4412 9.34453 11.3292 9.59412 11.1651 9.80841Z" fill="#249DEE" />
                </svg>
            </IconButton>
        )

    return (
        <IconButton title="Turn on notification" disabled={followLoading || unfollowLoading} onClick={handleTurnOnNotifications} data-tut="reactour__follow_post">
            <svg width="16" height="16" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.582 10.9692L11.4737 6.98149C11.1488 5.81327 10.4428 4.78716 9.46778 4.06626C8.4928 3.34537 7.30482 2.97107 6.09269 3.00285C4.88055 3.03463 3.71382 3.47067 2.77795 4.24166C1.84208 5.01265 1.19079 6.07435 0.927583 7.25799L0.0694995 11.1167C-0.0252832 11.5431 -0.0230918 11.9853 0.075912 12.4108C0.174916 12.8362 0.368204 13.2339 0.641506 13.5746C0.914808 13.9154 1.26114 14.1903 1.65495 14.3793C2.04875 14.5682 2.47996 14.6663 2.91675 14.6663H3.56658C3.70047 15.3257 4.05818 15.9184 4.5791 16.3442C5.10002 16.77 5.75213 17.0026 6.42492 17.0026C7.09771 17.0026 7.74981 16.77 8.27073 16.3442C8.79166 15.9184 9.14937 15.3257 9.28325 14.6663H9.77208C10.2217 14.6664 10.6653 14.5624 11.0681 14.3627C11.4709 14.1629 11.8221 13.8727 12.0942 13.5147C12.3663 13.1568 12.552 12.7408 12.6367 12.2992C12.7214 11.8576 12.7023 11.4024 12.582 10.9692ZM6.42492 15.833C6.06426 15.8315 5.71288 15.7186 5.41884 15.5098C5.1248 15.3009 4.90247 15.0064 4.78225 14.6663H8.06758C7.94737 15.0064 7.72503 15.3009 7.43099 15.5098C7.13695 15.7186 6.78557 15.8315 6.42492 15.833ZM11.1651 12.8084C11.0025 13.024 10.7919 13.1988 10.55 13.3188C10.3081 13.4388 10.0415 13.5007 9.7715 13.4997H2.91675C2.65471 13.4996 2.39602 13.4407 2.15978 13.3273C1.92354 13.2139 1.71579 13.0489 1.55184 12.8445C1.3879 12.6401 1.27196 12.4015 1.21258 12.1462C1.15319 11.891 1.15189 11.6257 1.20875 11.3699L2.06625 7.51057C2.27296 6.58086 2.78451 5.74693 3.5196 5.14134C4.25469 4.53575 5.17111 4.19326 6.1232 4.16831C7.07529 4.14337 8.00839 4.43741 8.77418 5.00368C9.53996 5.56995 10.0945 6.37596 10.3496 7.29357L11.4579 11.2812C11.5312 11.541 11.5428 11.8143 11.492 12.0794C11.4412 12.3445 11.3292 12.5941 11.1651 12.8084Z" fill="currentColor" fillOpacity="0.6" />
                <path d="M10 8C9.20888 8 8.43552 7.7654 7.77772 7.32588C7.11992 6.88635 6.60723 6.26164 6.30448 5.53073C6.00173 4.79983 5.92252 3.99556 6.07686 3.21964C6.2312 2.44372 6.61216 1.73098 7.17157 1.17157C7.73098 0.612165 8.44372 0.231202 9.21964 0.0768609C9.99556 -0.0774803 10.7998 0.0017333 11.5307 0.304484C12.2616 0.607234 12.8864 1.11992 13.3259 1.77772C13.7654 2.43552 14 3.20888 14 4C13.9989 5.06051 13.5771 6.07726 12.8272 6.82716C12.0773 7.57706 11.0605 7.99885 10 8Z" fill="currentColor" />
                <path d="M11.8828 3.71716C11.9579 3.79217 12 3.89391 12 4C12 4.10609 11.9579 4.20783 11.8828 4.28284C11.8078 4.35786 11.7061 4.4 11.6 4.4H10.4V5.6C10.4 5.70609 10.3579 5.80783 10.2828 5.88284C10.2078 5.95786 10.1061 6 10 6C9.89391 6 9.79217 5.95786 9.71716 5.88284C9.64214 5.80783 9.6 5.70609 9.6 5.6V4.4H8.4C8.29391 4.4 8.19217 4.35786 8.11716 4.28284C8.04214 4.20783 8 4.10609 8 4C8 3.89391 8.04214 3.79217 8.11716 3.71716C8.19217 3.64214 8.29391 3.6 8.4 3.6H9.6V2.4C9.6 2.29391 9.64214 2.19217 9.71716 2.11716C9.79217 2.04214 9.89391 2 10 2C10.1061 2 10.2078 2.04214 10.2828 2.11716C10.3579 2.19217 10.4 2.29391 10.4 2.4V3.6H11.6C11.7061 3.6 11.8078 3.64214 11.8828 3.71716Z" fill="currentColor" fillOpacity="0.6" />
            </svg>
        </IconButton>
    )
}

export default FollowPost;
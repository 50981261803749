import axios from "axios";
import { useEffect, useState } from "react";
import { GRAPHQL_API_URL } from "config";

type Data = {
    data: {
        isRunning: boolean;
        error: any;
    }
}

export const useIsServerUnderMaintainance = () => {
    const [serverDownUpto, setServerDownUpto] = useState<string | undefined>();
    const [isServerDown, setIsServerDown] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        axios.get(`${GRAPHQL_API_URL}/server_health_check`)
            .then(({ data }: Data) => {
                if (data?.isRunning) {
                    setIsServerDown(false);
                } else {
                    setIsServerDown(true);
                    setServerDownUpto(data?.error?.maintenanceWindowUntil)
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }, [setIsServerDown, setLoading])

    return { isServerDown, loading, serverDownUpto }
}
import React, { FC } from "react";
import styled from "@emotion/styled";
import { Exact, Maybe, Media } from "api";
import { Link, LinkProps } from "react-router-dom";

interface ImagePostProps {
  media?: Maybe<Maybe<Media>[]> | undefined;
  to: string;
}

const ImagePost: FC<ImagePostProps> = ({ media, to }) => {
  const images = media?.filter(x => x?.type.includes('image/'));
  const noOfImages = images?.length;

  const renderImages = images?.map((image, key) => <Image
    key={key}
    src={image?.uri?.HighResUri}
    alt=""
  />)

  if (!noOfImages) return null;
  if (noOfImages == 1)
    return (
      <CardMedia to={to} aria-label="Read post details">
        {renderImages}
      </CardMedia>
    );
  if (noOfImages == 2)
    return (
      <CardMedia2 to={to} aria-label="Read post details">
        {renderImages}
      </CardMedia2>
    );
  if (noOfImages == 3)
    return (
      <CardMedia3 to={to} aria-label="Read post details">
        {renderImages}
      </CardMedia3>
    );
  return (
    <CardMedia4 to={to} aria-label="Read post details">
      {images?.slice(0, 4)?.map((image, key) => <Image
        key={key}
        src={image?.uri?.HighResUri}
        alt=""
      />)}
    </CardMedia4>
  );
};

export default ImagePost;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const CardMedia = styled(Link)`
  text-decoration: none;
  display: block;
  margin: 10px 0;
`;

const CardMedia2 = styled(CardMedia)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
  grid-auto-rows: 360px;
  ${Image} {
    height: 100%;
  }
`;

const CardMedia3 = styled(CardMedia2)`
  grid-auto-rows: 180px;
  img {
    &:first-child {
      grid-row: 1 / 3;
    }
  }
  @media (min-width: 360px) {
    grid-auto-rows: 200px;
  }
  @media (min-width: 540px) {
    grid-auto-rows: 240px;
  }
`;

const CardMedia4 = styled(CardMedia2)`
  grid-auto-rows: 180px;
  @media (min-width: 360px) {
    grid-auto-rows: 200px;
  }
  @media (min-width: 540px) {
    grid-auto-rows: 240px;
  }
`;

export { getFullDateTimeFormat } from "./full-date-format";

export const formatDateForFeedback = (date: Date | string): string => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    };
    return new Date(date).toLocaleString('en-US', options);
}

import { Typography } from "@mui/material";
import React, { FC, memo } from "react";
import {styled} from "@mui/material";

export const EmptyBlockedList: FC = () => (
  <TitleContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#007aff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-alert-circle"
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
    <Title>Nothing to display!</Title>
    <Typography variant="caption">
      You have not blocked any profile till now.
    </Typography>
  </TitleContainer>
);

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
`;

const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 800;
`;

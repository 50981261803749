import axios from "axios";
import { GRAPHQL_API_URL } from "config";

export const accountSync = (token: string) => {
    const data = JSON.stringify({
        query: `mutation accountSync_V2 {
            accountSync_V2 {
              success
              userDetails {
                id
                username
                showNpsV2
                canBeOnboarded
              }
          }
      }`,
        variables: {}
    });

    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: GRAPHQL_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    return axios.request(config);
}

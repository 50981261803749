import React, { FC, Fragment, Suspense, lazy, useMemo } from "react";
import { ComponentType, Maybe, InterstitialsData } from "api";
import HighlightedAchievements from "components/HighlightedAchievements";

const ClubsInSpotLightSlider = lazy(
  () => import("./components/ClubsInSpotLight")
);
const SectorSlider = lazy(() => import("./components/SectorSlider"));
const StockSlider = lazy(() => import("./components/StockSlider"));
const TopicSlider = lazy(() => import("./components/TopicSlider"));
const Promotional = lazy(() => import("./components/Promotional"));
const SuggestedPeople = lazy(() => import("./components/SuggestedPeople"));
interface SmartFeedProps {
  data: Maybe<InterstitialsData> | undefined;
  loading?: boolean;
}

const SmartFeed: FC<SmartFeedProps> = ({ data, loading }) => {
  const renderComponent = useMemo(() => {
    switch (data?.componentType) {
      case ComponentType.ClubListForSmartFeed:
        return <ClubsInSpotLightSlider data={data} loading={loading} />;
      case ComponentType.PeopleListForSmartFeed:
        return <SuggestedPeople data={data} loading={loading} />;
      case ComponentType.PromotionsForSmartFeed:
        return <Promotional data={data} loading={loading} />;
      case ComponentType.SectorListForSmartFeed:
        return <SectorSlider data={data} loading={loading} />;
      case ComponentType.StockListForSmartFeed:
        return <StockSlider data={data} loading={loading} />;
      case ComponentType.TopicListForSmartFeed:
        return <TopicSlider data={data} loading={loading} />;
      case ComponentType.BadgePromotion:
        return (
          <HighlightedAchievements
            ga={{ event: "smart_feed_achievement_promotion" }}
          />
        );
      default:
        return null;
    }
  }, [data?.componentType, loading]);
  return <Suspense fallback={<Fragment />}>{renderComponent}</Suspense>;
};

export default SmartFeed;

import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import PublicHeader from "components/PublicHeader";
import imgUnderMaintenance from "./under-maintenance.png";
import { Button, Paper, Stack, Typography, styled } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import Theme from "Theme";
import { getFullDateTimeFormat } from "utills/formats";

const ServerMaintenance: FC<{ serverDownUpto: string | undefined }> = ({ serverDownUpto }) => {
    const handleReload = () => {
        location.reload();
    }
    return (
        <BrowserRouter>
            <Theme>
                <Helmet>
                    <title>Under Maintenance | StockEdge Social</title>
                </Helmet>
                <PublicHeader />
                <Stack alignItems='center'>
                    <Image src={imgUnderMaintenance} alt="Under Maintenance" />
                    {serverDownUpto ? <Card variant="elevation" elevation={0}>
                        <Typography variant="caption">Will be back on</Typography>
                        <Time>{getFullDateTimeFormat(serverDownUpto)}</Time>
                    </Card> : null}
                    <Button
                        variant="contained"
                        onClick={handleReload} sx={{
                            width: 200,
                            fontWeight: "bold"
                        }}
                    >Try Again</Button>
                </Stack>
            </Theme>
        </BrowserRouter>
    )
}

export default ServerMaintenance;

const Image = styled("img")`
    height: calc(80vh - 64px);
    width: 100%;
    object-fit: contain;
    object-position: center;
`;

const Time = styled("time")({

})

const Card = styled(Paper)({
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column"
})
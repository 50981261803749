import { Club, Maybe } from "api";
import React, {
  FC,
  useState,
  createContext,
  Fragment,
  useContext,
  useRef,
  PropsWithChildren,
} from "react";
import { styled } from "@mui/material";
import { Dialog } from "@mui/material";
import { PostCreator } from "./PostCreator";
import AskQuery from "./AskQuery";
export interface ICreatePostContext {
  handleOpenModal: (
    postCategory: PostCategory,
    club: Maybe<Club> | undefined
  ) => void;
  handleCloseModal: () => void;
  handleShareAchievement: (text: string, image?: File) => void;
}

export const CreatePostContext = createContext<ICreatePostContext>(
  {} as ICreatePostContext
);

interface IState {
  open: boolean;
  club: Maybe<Club> | undefined;
  text?: Maybe<string> | undefined;
  image?: File;
}

const initState: IState = {
  open: false,
  club: null,
  text: "",
  image: undefined,
};

type PostCategory = "Post" | "Query" | "Stock Idea";

export const CreatePostProvider: FC<PropsWithChildren> = (props) => {
  const childRef: any = useRef();
  const [postCategory, setPostCategory] = useState<PostCategory>("Post");
  const [{ open, club, text, image }, setState] = useState<IState>(initState);

  const handleOpenModal = (
    postCategory: PostCategory = "Post",
    club: Maybe<Club> | undefined = null
  ) => {
    setPostCategory(postCategory);
    setState({ open: true, club });
  };

  const handleCloseModal = (text?: any, flag?: boolean) => {
    setState({ ...initState });

    // console.log("AFTER MODAL CLOSE", { open, club, text, image });
  };

  const handleShareAchievement = (text: string, image?: File) => {
    // console.log(text, image);
    setState({ open: true, club: null, text, image });
  };

  // console.log(childRef.current);
  return (
    <Fragment>
      <CreatePostContext.Provider
        value={{
          handleOpenModal,
          handleCloseModal,
          handleShareAchievement,
        }}
        {...props}
      />
      <Modal open={open} onClose={() => childRef.current.handleClose()}>
        {postCategory === "Query" && <AskQuery />}
        {postCategory === "Post" && (
          <PostCreator
            club={club}
            text={text}
            image={image}
            handleCloseModal={handleCloseModal}
            handleOpenModal={handleOpenModal}
            ref={childRef}
          />
        )}
      </Modal>
    </Fragment>
  );
};

export const useCreatePost = () => {
  const context = useContext(CreatePostContext);
  if (!context) {
    throw new Error("useCreatePost must be used within a CreatePostProvider");
  }
  return context;
};

const Modal = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
  }
  .MuiPaper-root {
    overflow-y: initial;
  }
  .MuiDialog-container {
    align-items: flex-start;
  }
  .MuiDialog-paper {
    width: 100%;
    max-width: 640px;
    min-height: 480px;
    margin: auto;
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
  .MuiDialogContent-root {
    padding: 12px 12px 0 12px;
    --line-height: 18px;
    --font-size: 14px;

    textarea {
      border: 0;
      outline: 0;
    }

    textarea.createComment__input {
      padding: 0 !important;
      margin: 0 !important;
      font-size: var(--font-size) !important;
      line-height: var(--line-height) !important;
      font-weight: 400 !important;
    }

    div.createComment__highlighter {
      padding: 0 !important;
      margin: 0 !important;
      font-size: var(--font-size) !important;
      line-height: var(--line-height) !important;
      font-weight: 400 !important;
    }

    .createComment__suggestions {
      min-width: 120px !important;
    }

    .createComment__suggestions__list {
      max-height: 240px;
      overflow-y: auto;
      background: #fff;
      box-shadow: 0 4px 13px -3px rgb(0 0 0 / 10%);
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.12);

      &::-webkit-scrollbar {
        width: 12px; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: #ffffff; /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5); /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: 3px solid #ffffffff; /* creates padding around scroll thumb */
      }
    }

    .createComment__suggestions__item {
      padding: 2px 4px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      &:last-child {
        border: 0;
      }
    }
    .createComment__suggestions__item--focused {
      background-color: var(--hover-text-color);
      color: #fff;
    }
    .createComment_user_mention {
      background-color: var(--hover-text-color);
      font-size: var(--font-size) !important;
      line-height: var(--line-height) !important;
      font-weight: 400 !important;
      color: #fff;
      z-index: 1;
      position: relative;
      border-radius: 2px;
    }
    .createComment_hastag_mention {
      background-color: var(--hover-text-color);
      font-size: var(--font-size) !important;
      line-height: var(--line-height) !important;
      font-weight: 400 !important;
      color: #fff;
      z-index: 1;
      position: relative;
      border-radius: 2px;
    }
    .createComment_stock_mention {
      background-color: var(--hover-text-color);
      font-size: var(--font-size) !important;
      line-height: var(--line-height) !important;
      font-weight: 400 !important;
      color: #fff;
      z-index: 1;
      position: relative;
      border-radius: 2px;
    }
  }
  .MuiDialogTitle-root {
    .MuiTypography-root {
      font-size: 18px;
      font-weight: 700;
      color: var(--text-color-secondary);
    }
    .back-btn {
      display: none;
    }
  }
  .MuiDialogActions-root {
    justify-content: flex-start;
    align-items: center;
    padding: 8px 8px 8px 8px;
    .MuiIconButton-root {
      padding: 8px;
      margin: 0;
      svg {
        height: 20px;
        width: 20px;
      }
      /* &.Mui-disabled {
        svg {
          stroke: rgb(0 0 0 / 25%);
        }
      } */
    }
  }
  @media (max-width: 540px) {
    .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialogTitle-root {
      align-items: center;
    }
    .MuiDialogTitle-root {
      padding: 8px;
      margin: 0;
    }
    .MuiTypography-root {
      font-size: 16px;
    }
    .back-btn {
      display: block;
      margin-right: 8px;
    }
    .close-btn {
      display: none;
    }
  }
  @media (min-width: 481px) {
    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;

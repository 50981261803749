import React, { FC } from "react";
import MuiDialog, { DialogProps } from "@mui/material/Dialog";
import {styled} from "@mui/material";

export const ReportModal: FC<DialogProps> = ({ children, ...rest }) => {
  return (
    <Dialog {...rest}>
      {children}
    </Dialog>
  );
};

const Dialog = styled(MuiDialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
  }
  .MuiPaper-root {
    overflow-y: initial;
  }
  .MuiDialog-container {
    align-items: flex-start;
  }
  .MuiDialog-paper {
    width: 100%;
    max-width: 500px;
    min-height: 360px;
  }
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 16px;
    height: 50px;
    .MuiIconButton-root {
      padding: 8px;
    }
  }
  .MuiDialogContent-root {
    padding: 12px 12px 0 12px;
    --line-height: 18px;
    --font-size: 14px;
  }
  .MuiDialogTitle-root {
    .MuiTypography-root {
      font-size: 18px;
      font-weight: 700;
      color: var(--text-color-secondary);
    }
    .back-btn {
      display: none;
    }
  }
  .MuiDialogActions-root {
    .btnCancel {
      background-color: #3C4858;
    }
    .Mui-disabled {
      background-color: #c3c3c3;
    }
  }
  @media (max-width: 540px) {
    .MuiPaper-root {
      margin: 0;
      max-height: 100%;
      border-radius: 0;
      height: 100%;
    }
    .MuiDialogTitle-root {
      align-items: center;
    }
    .MuiDialogTitle-root {
      padding: 8px;
      margin: 0;
    }
    .MuiTypography-root {
      font-size: 16px;
    }
    .back-btn {
      display: block;
      margin-right: 8px;
    }
    .close-btn {
      display: none;
    }
  }
  @media (min-width: 481px) {
    .MuiDialogContent-root {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;

import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import React, { FC } from "react";
import { useGetCountries } from "./useGetCountries";

const CountryCodeDropdown: FC<SelectProps> = (props) => {
    const countries = useGetCountries();
    return (
        <FormControl sx={{ minWidth: 172, marginRight: 0.25 }} size="small">
            <InputLabel id="country-select">Country</InputLabel>
            <Select
                labelId="country-select"
                id="country-select"
                {...props}
            >
                {countries?.map((item:any) => (
                    <MenuItem key={item.ID} value={item.DialInCode}>
                        {item.Name} ({item.DialInCode})
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

    )
}

export default CountryCodeDropdown;
// OIDC
export const OIDC_AUTHORITY = process.env.OIDC_AUTHORITY as string;
export const OIDC_CLIENT_ID = process.env.OIDC_CLIENT_ID as string;

export const GRAPHQL_API_URL = process.env.GRAPHQL_API_URL as string;
export const GRAPHQL_SUBSCRIPTION_URL = process.env
  .GRAPHQL_SUBSCRIPTION_URL as string;
export const RECAPTHA_SITE_KEY = process.env.RECAPTHA_SITE_KEY as string;
export const STOCKEDGE_URL = process.env.STOCKEDGE_URL as string;
export const STOCKEDGE_WEBAPP_URL = process.env.STOCKEDGE_WEBAPP_URL as string;
export const MY_ACCOUNT_URL = `${OIDC_AUTHORITY}/myaccount#myprofile`;
export const CHANGE_PASSWORD_URL = `${OIDC_AUTHORITY}/MyAccount/ChangePassword`;
export const DELETE_ACCOUNT_URL = `${OIDC_AUTHORITY}/MyAccount/UserAccountDeleteRequest`;

// Plans
export const CLUB_PLAN_URL = process.env.CLUB_PLAN_URL as string;
export const PLAN_MONTHLY = process.env.PLAN_MONTHLY as string;
export const PLAN_QUARTERLY = process.env.PLAN_QUARTERLY as string;
export const PLAN_YEARLY = process.env.PLAN_YEARLY as string;
export const PLAN_CLUBS = process.env.PLAN_CLUBS as string;

// GA
export const GA_MEASUREMENT_ID = process.env.GA_MEASUREMENT_ID as string;

// FIREBASE
export const FIREBASE_API_KEY = process.env.FIREBASE_API_KEY as string;
export const FIREBASE_AUTH_DOMAIN = process.env.FIREBASE_AUTH_DOMAIN as string;
export const FIREBASE_PROJECT_ID = process.env.FIREBASE_PROJECT_ID as string;
export const FIREBASE_STORAGE_BUCKET = process.env
  .FIREBASE_STORAGE_BUCKET as string;
export const FIREBASE_MESSAGING_SENDER_ID = process.env
  .FIREBASE_MESSAGING_SENDER_ID as string;
export const FIREBASE_APP_ID = process.env.FIREBASE_APP_ID as string;
export const FIREBASE_PUBLIC_VAPID_KEY = process.env
  .FIREBASE_PUBLIC_VAPID_KEY as string;

// Rest API
export const REST_API_URL = `${GRAPHQL_API_URL}/stock`;
export const MEDIA_UPLOAD_URL = `${GRAPHQL_API_URL}/upload`;
export const SESSION_URL = `${GRAPHQL_API_URL}/session`;
export const PROMOTION_URL = `${GRAPHQL_API_URL}/promotion/getPromotion`;
export const EXTERNAL_RESOURSE = `${GRAPHQL_API_URL}/externalResource/getExternalResource`;
export const POST_DISCLAIMER = `${GRAPHQL_API_URL}/getDisclaimer`;
export const ANALYST_URL = `${window.location.origin}/analyst`;
export const DEFAULT_AVATAR = `${GRAPHQL_API_URL}/assets?name=default_avatar`;
export const WEBINARS_URL = `${STOCKEDGE_URL}/Account/Profile#webinars`;
export const COURSES_URL = `${STOCKEDGE_URL}/Account/Profile#courses`;
export const REGULATORY_URL = `${STOCKEDGE_URL}/regulatorydetails`;
export const UPLOAD_LARGE_FILES = `https://staging-apisocial.stockedge.com/uploadLargeFiles`;
export const UPLOAD_LARGE_FILE_THUMBNAIL = `https://staging-apisocial.stockedge.com/uploadVideoThumbnail`;
export const EDIT_LARGE_FILE_THUMBNAIL = `https://staging-apisocial.stockedge.com/editVideoThumbnail`;

console.log("UPLOAD_LARGE", UPLOAD_LARGE_FILES);

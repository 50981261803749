import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

const PostUnavailable = () => (
    <Card sx={{ margin: 2 }}>
        <CardContent sx={{ padding: 2, paddingBottom: 2 }}>
            <Typography variant="h6">Post Unavailable</Typography>
            <Typography variant="body2" paragraph>This post is hidden due to privacy settings of its owner</Typography>
        </CardContent>
    </Card>
)

export default PostUnavailable;
function addOrdinalIndicator(n: number): string {
    const suffixes: string[] = ["st", "nd", "rd"];
    const suffix: string = n % 10 < 4 && (n % 100 < 10 || n % 100 >= 20) ? suffixes[n % 10 - 1] : "th";
    return `${n}${suffix}`;
}

const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
};

export const getFullDateTimeFormat = (date: Date | string | undefined): string | undefined => {
    if (!date) return;
    try {
        const tempDate: Date = new Date(date);
        const formattedDate: string = tempDate.toLocaleString('en-US', options);
        console.log({formattedDate})
        const dayWithOrdinal: string = addOrdinalIndicator(tempDate.getDate());
        console.log({dayWithOrdinal})
        return formattedDate.replace(tempDate.getDate().toString()+',', dayWithOrdinal).replace(" at",",");
    } catch (e) {
        return;
    }
}
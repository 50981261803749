import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ActivityFeed, Maybe } from "api";

interface ActivityMessageProps {
  activity: Maybe<ActivityFeed> | undefined;
}

const ActivityMessage: FC<ActivityMessageProps> = ({ activity }) => {
  const { user } = useAuthContext();
  const isME = user?.id === activity?.user?.id;
  const link = (
    <Link to={`/profile/${activity?.user?.username}`}>
      {`${activity?.user?.firstName} ${activity?.user?.lastName}`}{" "}
      {activity?.user?.isVerified ? (
        <Image src={require("assets/image/blue-check.webp")} alt="Blue Tick" />
      ) : activity?.user?.greenTick ? (
        <Image
          src={require("assets/image/green-check.webp")}
          alt="Green Tick"
        />
      ) : activity?.user?.tick3 ? (
        <Image
          src={require("assets/image/yellow-tick.webp")}
          alt="Yellow Tick"
        />
      ) : null}
    </Link>
  );

  switch (activity?.activityType) {
    case "upvotePost":
      return isME ? (
        <CustomTypography variant="body2">
          You upvoted this post
        </CustomTypography>
      ) : (
        <CustomTypography variant="body2">
          {link} has upvoted this post
        </CustomTypography>
      );
    case "downvotePost":
      return isME ? (
        <CustomTypography variant="body2">
          You downvoted this post
        </CustomTypography>
      ) : (
        <CustomTypography variant="body2">
          {link} has downvoted this post
        </CustomTypography>
      );
    case "comment":
      return isME ? (
        <CustomTypography variant="body2">
          You commented on post
        </CustomTypography>
      ) : (
        <CustomTypography variant="body2">
          {link} has commented on post
        </CustomTypography>
      );
    case "upvoteComment":
      return isME ? (
        <CustomTypography variant="body2">You upvoted comment</CustomTypography>
      ) : (
        <CustomTypography variant="body2">
          {link} has upvoted comment
        </CustomTypography>
      );
    case "downvoteComment":
      return isME ? (
        <CustomTypography variant="body2">
          You downvoted comment
        </CustomTypography>
      ) : (
        <CustomTypography variant="body2">
          {link} has downvoted comment
        </CustomTypography>
      );
    case "reply":
      return isME ? (
        <CustomTypography variant="body2">
          You replied on comment
        </CustomTypography>
      ) : (
        <CustomTypography variant="body2">
          {link} has replied on comment
        </CustomTypography>
      );
    case "downvoteReply":
      return isME ? (
        <CustomTypography variant="body2">You downvoted reply</CustomTypography>
      ) : (
        <CustomTypography variant="body2">
          {link} has downvoted reply
        </CustomTypography>
      );
    case "upvoteReply":
      return isME ? (
        <CustomTypography variant="body2">You upvoted reply</CustomTypography>
      ) : (
        <CustomTypography variant="body2">
          {link} has upvoted reply
        </CustomTypography>
      );
    default:
      return null;
  }
};

export default ActivityMessage;

const Image = styled("img")({
  height: 10,
  width: 10,
  marginRight: "2px"
});

const CustomTypography = styled(Typography)`
  padding: 8px 8px 0 8px;
  font-size: 0.725rem;
  a {
    font-weight: bold;
    text-decoration: none;
    color: inherit;
  }
  a:hover {
    text-decoration: underline;
    color: inherit;
  }
  @media (min-width: 375px) {
    // font-size: 0.813rem;
    padding: 12px 12px 0 12px;
  }
  @media (min-width: 540px) {
    // font-size: 0.875rem;
    padding: 16px 16px 0 16px;
  }
`;

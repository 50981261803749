import React, { FC } from "react";
import styled from "@emotion/styled";
import { CardContent } from "@mui/material";
import AudioPlayer from "react-player/lazy";
import { Maybe, Media } from "api";
import imgSound from "assets/audio.webp";


const DisplayAudioPlayer: FC<{ audio: Maybe<Media> }> = ({ audio }) => {
    return (
        <CardContent>
            <Container>
                <img src={imgSound} alt="" />
                <AudioPlayer
                    className="audio-player"
                    url={audio?.uri?.showUri}
                    width='100%'
                    height='56px'
                    controls
                />
            </Container>
        </CardContent>
    )
}

export default DisplayAudioPlayer;

const Container = styled.div`
    height: 150px;
    position: relative;
    border-radius: 4px;
    background: #2196f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 20px;
    img {
        height: 56px;
        width: 56px;
        margin-bottom: 20px;
        object-fit: cover;
        object-position: center;
    }
`;
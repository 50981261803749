import React, { forwardRef } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import {
  Typography,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { Maybe, useMeQuery, User } from "api";
import { useAuthContext } from "context/AuthContext";
import BlueTick from "./BlueTick";
import GreenTick from "./GreenTick";
import YellowTick from "./YellowTick";
import { UserFollowButton } from "components/Buttons";
import Avatar from "components/Avatar";
import { getIsRingVisible } from "utills";
import {
  UpgradeModalInitialised,
  UserFollowButtonClickedComponentName,
  UserButtonClicked,
  sendEvent,
} from "ga4";

interface UserListItemProps {
  user: Maybe<User> | undefined;
  component_name: UserFollowButtonClickedComponentName;
  upgrade_component_name: UpgradeModalInitialised;
  button_component_name: UserButtonClicked;
}

const UserListItem = forwardRef<HTMLLIElement, UserListItemProps>(
  (
    { user, component_name, upgrade_component_name, button_component_name },
    ref
  ) => {
    const { data } = useMeQuery();
    const isNotMe = !(user?.id === data?.me.id);

    const onClick = () => {
      sendEvent({
        event: "user_button_clicked",
        component_name: button_component_name,
      });
    };

    return (
      <CustomListItem dense disableGutters ref={ref} onClick={onClick}>
        <ListItemButton
          disabled={Boolean(user?.isUserDeleted)}
          component={Link}
          to={`/profile/${user?.username}`}
        >
          <ListItemAvatar>
            <Avatar
              alt={user?.username as string}
              src={user?.avatar?.LowResUri}
              hasRing={getIsRingVisible(user?.membershipLevel)}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="subtitle2">
                {`${user?.firstName} ${user?.lastName}`}
                {Boolean(user?.isVerified) && <BlueTick />}
                {Boolean(user?.greenTick) && <GreenTick />}
                {Boolean(user?.tick3) && <YellowTick />}
              </Typography>
            }
            secondary={
              Boolean(user?.isUserDeleted) ? null : (
                <Typography variant="caption">@{user?.username}</Typography>
              )
            }
            disableTypography
          />
          {isNotMe ? (
            <CustomUserFollowButton
              user={user}
              component_name={component_name}
              upgrade_component_name={upgrade_component_name}
            />
          ) : null}
        </ListItemButton>
      </CustomListItem>
    );
  }
);

export default UserListItem;

const CustomUserFollowButton = styled(UserFollowButton)({
  minWidth: "100px",
});

const CustomListItem = styled(ListItem)`
  .MuiListItemButton-root {
    padding-left: 4px;
    padding-right: 4px;
  }
  .MuiListItemAvatar-root {
    min-width: 48px;
  }
  .MuiListItemText-root {
    margin-top: 0;
    margin-bottom: 0;
  }
  .MuiTypography-subtitle2 {
    font-size: 0.875rem;
    line-height: 1rem;
  }
  .MuiTypography-caption {
    font-size: 0.725rem;
  }
  .MuiButton-root {
    max-width: 80px;
  }
  @media (min-width: 360px) {
    .MuiListItemButton-root {
      padding-left: 8px;
      padding-right: 8px;
    }
    .MuiButton-root {
      max-width: 100px;
    }
  }
`;
